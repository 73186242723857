
//import { saveAs } from 'file-saver';
//import EXIF from 'exif-js';
import { onload2promise, onload2promiseE } from './promiseTools'
import { saveAs } from 'file-saver';
import { drawStar, drawStars, drawCrowns } from '../helpers/drawStar'
import { toDateTimeObj } from '../helpers/basics'
import shortid from 'shortid'


//import Hashids from 'hashids/lib/hashids'
import Hashids from 'hashids'


import ArtQR from 'art-qr';


// ! might be an issue with some LINUX systems, that use ! for history... in that case use single quotes around the file name or backslash with !
shortid.characters('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!-')


//import as piexif from 'piexifjs';
//const piexif = require('piexifjs')
//var piexif = import('piexifjs') 
//import {load as piexifload, piexif} from 'piexifjs';
var piexif = require("piexifjs");


// create something you can access to store the instance if you want
let MyQRInstance;

//import bardcode from 'bardcode'
var bardcode = require("bardcode");


const TOP_MENU_MARGIN = 55 //55 + 55

var myhashids = new Hashids('', 0, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'); // all lowercase

//var myhashids = new Hashids('', 0, 'abcdefghijklmnopqrstuvwxyz'); // all lowercase
console.log('top 234,hashids:', myhashids.encode('234'))






const SAVE_IMAGE_FORMAT = 'image/jpeg'
const SAVE_IMAGE_OPTIONS = '0.9'
const MAX_IMAGE_SIZE = 1400  // max scale size in pixel



// reads the default image from URL and call imageProcessor with it
export const piexifUrlImageProcessor = async({ photoUrl=null, ...props }) => {
    /*maxImageSize=MAX_IMAGE_SIZE, 
    saveImageOptions=SAVE_IMAGE_OPTIONS, 
    saveImageFormat=SAVE_IMAGE_FORMAT,
    canRef=null }) => { */

  //var blob = null;
  var xhr = new XMLHttpRequest();
  xhr.open("GET", photoUrl);
  xhr.responseType = "blob";//force the HTTP response, response-type header to be blob
  xhr.send()

  await onload2promise(xhr)

  const res = await piexifImageProcessor({ ...props, target:xhr.response })

  console.log('piexifUrlImageProcessor res:', res)
  return res

}


// 2012:08:19 23:36:42   into  2012-08-19 23:36:42   into timestamp
const parseExifDate = function(exifDate) {

  if (!!exifDate) {

    let newExifDate = exifDate

    if ((exifDate.match(/:/g) || []).length > 3) {
      console.log('more than 3 occurances of :   replace the first 2 with -   so we can convert to date')
      // 2012:08:19 23:36:42   into  2012-08-19 23:36:42   into timestamp

      newExifDate = exifDate.replace(":", "-").replace(":", "-")
    }

    const newExifTimeStamp = Date.parse(newExifDate)
    
    if (newExifTimeStamp && newExifTimeStamp > 0) {
      return { originalExifDate: exifDate, exifDate: newExifDate, exifTimeStamp: newExifTimeStamp }
    } else {
      return null
    }

  }
}


export function generateQRid({ prefix=null, filler='/' }) {
  return !!prefix ? prefix + filler + shortid.generate() : + shortid.generate()
}

// helper that handles different ways DMS may be stored, called by gpsDegreesToDec..
function parseDms(partArr) {
  return partArr.length > 1 ? partArr[0] / partArr[1] : partArr[0]
}



// normalizes GPS DMS array of Lat or Lng to a decimal value
// this could also be done with this NPM module: https://github.com/Turistforeningen/dms2dec.js/
const gpsDegreesToDec = function(dmsArr) {

  if (dmsArr === undefined) {
    console.log('GPS degrees value is undefined.. returning NULL')
    return null
  }
  // $deg + ($min * 60 + $sec) / 3600;
  return parseDms(dmsArr[0]) + ( parseDms(dmsArr[1])*60 + parseDms(dmsArr[2]) ) / 3600 

}



// compares two dates, returns the newer one, or false, if both dates are 0
export function getNewerDate({ exifDate=0, thisDate=0, newerBest=true }) {
  if(exifDate === 0 && thisDate === 0) {
    return false
  }
  if (newerBest) {
    return exifDate > thisDate ? exifDate : thisDate
  } 

  return exifDate < thisDate ? exifDate : thisDate
  
}

// scale and rotate image according to EXIF information on client side
//
// in: file[0] and ref to canvas
// out: image and canVals (canvasSpecs)
export const piexifImageProcessor = async({   
    target, 
    maxImageSize=MAX_IMAGE_SIZE, 
    saveImageOptions=SAVE_IMAGE_OPTIONS, 
    saveImageFormat=SAVE_IMAGE_FORMAT,
    canRef=null,
    doStoreExif, 
    doUpdateFileTimeStamp,
    doUpdateGPS
  }) => {

  // imageToBase64

  const reader = new FileReader();
  // Read the target as DataURL.
  reader.readAsDataURL(target);    //piexifjs uses (to read/write exif values):   reader.readAsDataURL(target)
  const base64Str = await onload2promiseE(reader);

  // getArrayBufferOrientation

  const exifObj = piexif.load(base64Str.target.result);

  // Read EXIF Orientation data.
  const orientation = exifObj["0th"][piexif.ImageIFD.Orientation];




  let zeroth = exifObj["0th"] 
  let exif = exifObj["Exif"] 
  let gps = exifObj["GPS"] 

  
  // use these to override the file timestamp we get from the browser file selection (if exif exists and is earlier)
  const exifDate = exif[36867] //exifObj["0th"][piexif.ImageIFD.DateTimeOriginal];        // 0x9003 = 36867
  const exifTimeZone = exif[36881];    // 0x9011 = 36881   // == timezone

  

  console.log('### exifObj:', exifObj)

  // Exif.Image.ImageWidth / ImageLength
  // 0x010d 	269 	Image 	Exif.Image.DocumentName 	Ascii 	The name of the document from which this image was scanned
  // 0x013b 	315 	Image 	Exif.Image.Artist 	Ascii
  // 0x013c 	316 	Image 	Exif.Image.HostComputer 	Ascii
  // 0x02bc 	700 	Image 	Exif.Image.XMLPacket 	Byte 	XMP Metadata (Adobe technote 9-14-02)
  // 0x800d 	32781 	Image 	Exif.Image.ImageID 	Ascii 	ImageID is the full pathname of the original, high-resolution image, or any other identifying string that uniquely identifies the original image (Adobe OPI).
  /* 0x8298 	33432 	Image 	Exif.Image.Copyright 	Ascii 	Copyright information. In this standard the tag is used to indicate both the photographer and editor copyrights. It is the copyright notice of the person or organization claiming rights to the image. The Interoperability copyright statement including date and rights should be written in this field; e.g., "Copyright, John Smith, 19xx. All rights reserved.". In this standard the field records both the photographer and editor copyrights, with each recorded in a separate part of the statement. When there is a clear distinction between the photographer and editor copyrights, these are to be written in the order of photographer followed by editor copyright, separated by NULL (in this case since the statement also ends with a NULL, there are two NULL codes). When only the photographer copyright is given, it is terminated by one NULL code. When only the editor copyright is given, the photographer copyright part consists of one space followed by a terminating NULL code, then the editor copyright is given. When the field is left blank, it is treated as unknown.
  */
  // 0x8769 	34665 	Image 	Exif.Image.ExifTag 	Long 	A pointer to the Exif IFD. Interoperability, Exif IFD has the same structure as that of the IFD specified in TIFF. ordinarily, however, it does not contain image data as in the case of TIFF.

  /*
  0x9c9b 	40091 	Image 	Exif.Image.XPTitle 	Byte 	Title tag used by Windows, encoded in UCS2
  0x9c9c 	40092 	Image 	Exif.Image.XPComment 	Byte 	Comment tag used by Windows, encoded in UCS2
  0x9c9d 	40093 	Image 	Exif.Image.XPAuthor 	Byte 	Author tag used by Windows, encoded in UCS2
  0x9c9e 	40094 	Image 	Exif.Image.XPKeywords 	Byte 	Keywords tag used by Windows, encoded in UCS2
  0x9c9f 	40095 	Image 	Exif.Image.XPSubject 	Byte 	Subject tag used by Windows, encoded in UCS2

  // JS encode / decode UCS2:
  https://maketips.net/tip/239/convert-to-ucs2-and-from-ucs2-in-javascript
  which uses punycode npm module to encode utf8 into limited ascii character set:
  https://www.npmjs.com/package/punycode
  */



  zeroth[piexif.ImageIFD.Orientation] = 1

  zeroth[piexif.ImageIFD.Copyright] = "Copyright, HuaJin Consulting Co Ltd, 2019. All rights reserved"

/*  
  zeroth[piexif.ImageIFD.ImageDescription] = "some ImageDescription here.."

  // not showing up in exif editor
  zeroth[piexif.ImageIFD.DocumentName] = "original photo document name here"

  // not showing up in exif editor
  zeroth[piexif.ImageIFD.ImageID] = "original photo ImageID name here, might overwrite some File path..."

  zeroth[piexif.ImageIFD.Artist] = "the logged in Artist.."
  zeroth[piexif.ImageIFD.Copyright] = "Copyright, HuaJin Consulting Co Ltd, 2019. All rights reserved"


  // adding a exif.photo.UserComment field at address 37510... careful, the whole (exif) block can t be over 64kb
  exif[37510] = '12345678'.concat(`&#x80fd;&#x7f8e;&#x5831;&#x524d;&#x55b6;&#x7c73;&#x59d4;&#x6b69;&#x6bb5; {focuses} on a self-contained incident or series of linked incidents, with the intent of evoking a "single effect" or mood, however there are many exceptions to this.

  A dictionary definition is "an invented prose narrative shorter than a novel usually dealing with a few characters and aiming at unity of effect and often concentrating on the creation of mood rather than plot."[1]

  The short story is a crafted form in its own right. Short stories make use of plot, resonance, and other dynamic components as in a novel, but typically to a lesser degree. While the short story is largely distinct from the novel or novella (a shorter novel), authors generally draw from a common pool of literary techniques.

  Short story writers may define their works as part of the artistic and personal expression of the form. They may also attempt to resist categorization by genre and fixed formation.`) // the address for UserComment...
  // strangely the leading 8 characters are cut off...

*/

  const newExifObj = {"0th":zeroth, "Exif":exif, "GPS":gps};

  // store new exif object with parent callback (old and new data merged into new obj), so that it can be used later to add
  // additional data coming in from the UI
  doStoreExif(newExifObj)






  //  const orientation = exifObj["0th"][piexif.ImageIFD.Orientation];
  //gpsIfd[piexif.GPSIFD.GPSLatitude] =

  const exifLat = exifObj["GPS"][piexif.GPSIFD.GPSLatitude]
  const exifLng = exifObj["GPS"][piexif.GPSIFD.GPSLongitude]

  // not really necessary
  //const exifLatRef = exifObj["GPS"][piexif.GPSIFD.GPSLatitudeRef]
  //const exifLngRef = exifObj["GPS"][piexif.GPSIFD.GPSLongitudeRef]  


  // $deg + ($min * 60 + $sec) / 3600;
  const exifLatDec = gpsDegreesToDec(exifLat)
  const exifLngDec = gpsDegreesToDec(exifLng)

  if (exifLatDec !== null && exifLngDec !== null) {
    // got useable GPS info

    // LINK TO GOOGLE MAPS
    console.log(`https://maps.google.com/?q=${exifLatDec},${exifLngDec}`)
    doUpdateGPS(exifLatDec, exifLngDec)

    // use icon PinDrop

      
  }


  // might want to set state for a GPS variable (to indicate that image has GPS..)

  const exifDateObj = !!exifDate ? parseExifDate(exifDate) : {}

  if (!!exifDate) {

    if (!!exifDateObj) {
      doUpdateFileTimeStamp({ ...exifDateObj, exifTimeZone })
    } else {
      console.warn('EXIF DATE ERROR, COULD NOT PARSE EXIF DATE/TIME..')
    }
  } else {
    console.log('no exif time/date in file..')
    doUpdateFileTimeStamp({ exifTimeStamp:null })

    // update with file date?
  }



  const exifbytes = piexif.dump(newExifObj);



  // orientImage

  // Create a new Image called thisImage.
  const thisImage = new Image();
  // Read thisImage into a new Object URL.
  thisImage.src = URL.createObjectURL(target);

  // insert new EXIF data into image
  //const inserted = piexif.insert(exifbytes, target);    // 'target' should probably better be from the new oriented and scaled canvas...


  await onload2promise(thisImage);


  // Create a consitient ratio to original image.
  const ratio = thisImage.width / thisImage.height;
  const canvasSpecs = createCanvasSpecs({ scaleSize: maxImageSize, ratio, orientation })


  // imageToCanvas

  // use existing canvas or create new one to show the rotated canvas
  const can = !!canRef ? canRef : document.createElement("canvas");


  // Create '2D' context.
  const ctx = can.getContext('2d');

  // Perform Canvas Operations.
  can.width = canvasSpecs.canvasW;
  can.height = canvasSpecs.canvasH;

  ctx.rotate(canvasSpecs.rotate); 
  ctx.translate(canvasSpecs.translateX, canvasSpecs.translateY);

  ctx.save();
  ctx.drawImage(thisImage, 0, 0, canvasSpecs.drawImgX, canvasSpecs.drawImgY);
  ctx.restore();

  console.log('canvasSpecs created:', canvasSpecs)
  const newJpgStr = can.toDataURL(saveImageFormat, saveImageOptions)

  const newExifJpg = piexif.insert(exifbytes, newJpgStr)


  // use mimeType and imageFormat for this..
  const mimeType = ["image/jpeg", 0.9]  // png is standard, no need for writing it out
  

  //const blob = await b64toBlob(newExifJpg, mimeType);

  //const b = window.atob(newExifJpg)

  //
  //
  //
  //  SAVING THE IMAGE AT THE USER S DEVICE
  //
  //saveAs(newExifJpg, 'exifTest.jpg');


  // the save is done in the saveImgDialog as follows:
  // await saveCanvas({ canRef: this.dialogMergeCanRef, fileName: this.props.fileName })




    /*  // left here just for documentation purpose:
  CanRef.toBlob(function(blob) {
      saveAs(blob, 'exifTest.jpg');
  }, ...mimeType);   
  */


  console.log('new canVals:', canvasSpecs)

  // insert new EXIF data into image and return object

  //return { canVals: canvasSpecs, image: can.toDataURL(saveImageFormat, saveImageOptions) }
  return { canVals: canvasSpecs, image: piexif.insert(exifbytes, newJpgStr), exifDateObj }

}









// helper for piexif ... not really used...
export const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}





function createCanvasSpecs({ orientation, scaleSize, ratio }) {

    // Switch statement based on orientation value.
    switch (orientation) {
    case 1:
        // Counter Clockwise -> Landscape (Top Facing Right).
        return {
        canvasW: scaleSize * ratio,
        canvasH: scaleSize,
        drawImgX: scaleSize * ratio,
        drawImgY: scaleSize,
        rotate: 0,
        translateX: 0,
        translateY: 0
        }

    case 3:
        // Clockwise -> Landscape (Top Facing Left).
        return {
        canvasW: scaleSize * ratio,
        canvasH: scaleSize,
        drawImgX: scaleSize * ratio,
        drawImgY: scaleSize,
        rotate: 180 * Math.PI / 180,
        translateX: -scaleSize * ratio,
        translateY: -scaleSize
        }

    case 6:
        // Vertical -> Portrait (Upside up).
        return {
        canvasW: scaleSize,
        canvasH: scaleSize * ratio,
        drawImgX: scaleSize * ratio,
        drawImgY: scaleSize,
        rotate: 90 * Math.PI / 180,
        translateX: 0,
        translateY: -scaleSize
        }

    case 8:
        // Vertical -> Portrait (Upside down).
        return {
        canvasW: scaleSize,
        canvasH: scaleSize * ratio,
        drawImgX: scaleSize * ratio,
        drawImgY: scaleSize,
        rotate: -90 * Math.PI / 180,
        translateX: -scaleSize * ratio,
        translateY: 0
        }

    default:
        // No Available Data.
        return {
        canvasW: scaleSize * ratio,
        canvasH: scaleSize,
        drawImgX: scaleSize * ratio,
        drawImgY: scaleSize,
        rotate: 0,
        translateX: 0,
        translateY: 0
        }
    }

}


// save canvas as local file in browser
export const saveCanvas = async({ canRef=null, fileName="noName.jpg" }) => {

  //console.log('saveCanvas canRef, canvasW/canvasH:', canRef, canvasW, canvasH)
  // using a thrid canvas to merge text canvas with scaled / rotated picture canvas and save

  //let can3 = document.createElement('canvas');
  //canRef.width = canvasW;
  //canRef.height = canvasH;   


  let mimeType = []  // png is standard, no need for writing it out


  if (fileName.toLowerCase().endsWith('.jpg') || fileName.toLowerCase().endsWith('.jpeg')) {
      mimeType = ["image/jpeg", 0.9]
  }  


  canRef.toBlob(function(blob) {
      saveAs(blob, fileName);
  }, ...mimeType);      
  /*
  canvas.toBlob(function(blob) {
      saveAs(blob, fileName);
  }, ...mimeType);  

  //}, 'image/jpeg', 0.9);  
  */


}





// save canvas as local file in browser
export const saveCanvasWithExif = async({ 

      
    canRef=null, 
    fileName="noName.jpg", 
    oldExifData=null, // exif data from opening the initial file, with corrected rotation
    uiData=null, // data that the user entered in the UI
    timeStamp=null,

    saveImageOptions=SAVE_IMAGE_OPTIONS, 
    saveImageFormat=SAVE_IMAGE_FORMAT }) => {


    let mimeType = []  // png is standard, no need for writing it out


  ///////////////////////////////////////////////////
  // stuff coming in from saveExif functionality in processImage:


    let zeroth = oldExifData["0th"] 
    let exif = oldExifData["Exif"] 
    let gps = oldExifData["GPS"] 

    try {

      console.log('### exifData:', oldExifData)

      if (!!timeStamp && timeStamp > 0) {
        const dateTimeObj = toDateTimeObj(timeStamp,':',':')
        exif[37510] = dateTimeObj.dateOnly + ' ' + dateTimeObj.timeOnly

      }

      //might also want to set the timezone...
      //const exifTimeZone = exif[36881];    // 0x9011 = 36881   // == timezone

  






      // Exif.Image.ImageWidth / ImageLength
      // 0x010d 	269 	Image 	Exif.Image.DocumentName 	Ascii 	The name of the document from which this image was scanned
      // 0x013b 	315 	Image 	Exif.Image.Artist 	Ascii
      // 0x013c 	316 	Image 	Exif.Image.HostComputer 	Ascii
      // 0x02bc 	700 	Image 	Exif.Image.XMLPacket 	Byte 	XMP Metadata (Adobe technote 9-14-02)
      // 0x800d 	32781 	Image 	Exif.Image.ImageID 	Ascii 	ImageID is the full pathname of the original, high-resolution image, or any other identifying string that uniquely identifies the original image (Adobe OPI).
      /* 0x8298 	33432 	Image 	Exif.Image.Copyright 	Ascii 	Copyright information. In this standard the tag is used to indicate both the photographer and editor copyrights. It is the copyright notice of the person or organization claiming rights to the image. The Interoperability copyright statement including date and rights should be written in this field; e.g., "Copyright, John Smith, 19xx. All rights reserved.". In this standard the field records both the photographer and editor copyrights, with each recorded in a separate part of the statement. When there is a clear distinction between the photographer and editor copyrights, these are to be written in the order of photographer followed by editor copyright, separated by NULL (in this case since the statement also ends with a NULL, there are two NULL codes). When only the photographer copyright is given, it is terminated by one NULL code. When only the editor copyright is given, the photographer copyright part consists of one space followed by a terminating NULL code, then the editor copyright is given. When the field is left blank, it is treated as unknown.
      */
      // 0x8769 	34665 	Image 	Exif.Image.ExifTag 	Long 	A pointer to the Exif IFD. Interoperability, Exif IFD has the same structure as that of the IFD specified in TIFF. ordinarily, however, it does not contain image data as in the case of TIFF.

      /*
      0x9c9b 	40091 	Image 	Exif.Image.XPTitle 	Byte 	Title tag used by Windows, encoded in UCS2
      0x9c9c 	40092 	Image 	Exif.Image.XPComment 	Byte 	Comment tag used by Windows, encoded in UCS2
      0x9c9d 	40093 	Image 	Exif.Image.XPAuthor 	Byte 	Author tag used by Windows, encoded in UCS2
      0x9c9e 	40094 	Image 	Exif.Image.XPKeywords 	Byte 	Keywords tag used by Windows, encoded in UCS2
      0x9c9f 	40095 	Image 	Exif.Image.XPSubject 	Byte 	Subject tag used by Windows, encoded in UCS2

      // JS encode / decode UCS2:
      https://maketips.net/tip/239/convert-to-ucs2-and-from-ucs2-in-javascript
      which uses punycode npm module to encode utf8 into limited ascii character set:
      https://www.npmjs.com/package/punycode
      */



      zeroth[piexif.ImageIFD.Orientation] = 1
      zeroth[piexif.ImageIFD.ImageDescription] = "new uiData coming here.."

      // not showing up in exif editor
      zeroth[piexif.ImageIFD.DocumentName] = "original photo document name here"

      // not showing up in exif editor
      zeroth[piexif.ImageIFD.ImageID] = "original photo ImageID name here, might overwrite some File path..."

      zeroth[piexif.ImageIFD.Artist] = "the logged in Artist.."
      zeroth[piexif.ImageIFD.Copyright] = "Copyright, HuaJin Consulting Co Ltd, 2019. All rights reserved"


      // this might leave out newly created data... check!!
      const cleanData = Object.keys(uiData).map( item => {
        if (!!uiData[item].saveExif && uiData[item].selected && uiData[item].values && uiData[item].dockedText && uiData[item].dbKey) {
          //return { key: uiData[item].dockedText, values: uiData[item].values.join(',') }

          const valStr = uiData[item].values.length > 0 ? uiData[item].values.join(',') : uiData[item].values[0]
          const renderStr = uiData[item].dbKey + ': ' + valStr 
          return renderStr

        } 
        //return null
        return ''
      })


      // adding a exif.photo.UserComment field at address 37510... careful, the whole (exif) block can t be over 64kb
      //exif[37510] = JSON.stringify(cleanData)
      exif[37510] = cleanData.join('\n')
      zeroth[piexif.ImageIFD.ImageDescription] = cleanData.join('\n')  // trying out desription/caption as new place for tags


      // also putting it into the windows XP Keywords section of exif (also got XP Title, Subject, Comments, Author)
      //exif[40094] = cleanData.join('\n')   this did not work

      //exif[40092] = cleanData.join('\n')  






      /*
      exif[37510] = '12345678'.concat(`&#x80fd;&#x7f8e;&#x5831;&#x524d;&#x55b6;&#x7c73;&#x59d4;&#x6b69;&#x6bb5; {focuses} on a self-contained incident or series of linked incidents, with the intent of evoking a "single effect" or mood, however there are many exceptions to this.

      A dictionary definition is "an invented prose narrative shorter than a novel usually dealing with a few characters and aiming at unity of effect and often concentrating on the creation of mood rather than plot."[1]

      The short story is a crafted form in its own right. Short stories make use of plot, resonance, and other dynamic components as in a novel, but typically to a lesser degree. While the short story is largely distinct from the novel or novella (a shorter novel), authors generally draw from a common pool of literary techniques.

      Short story writers may define their works as part of the artistic and personal expression of the form. They may also attempt to resist categorization by genre and fixed formation.`) // the address for UserComment...
      // strangely the leading 8 characters are cut off...  was a problem with the firefox exif viewer...

      */



      const newExifObj = {"0th":zeroth, "Exif":exif, "GPS":gps};

      // store new exif object with parent callback (old and new data merged into new obj), so that it can be used later to add
      // additional data coming in from the UI
      // doStoreExif(newExifObj)


      const exifbytes = piexif.dump(newExifObj);



      // use existing canvas or create new one to show the rotated canvas
      const can = !!canRef ? canRef : document.createElement("canvas");


      const newJpgStr = can.toDataURL(saveImageFormat, saveImageOptions)

      const newExifJpg = piexif.insert(exifbytes, newJpgStr)


      // use mimeType and imageFormat for this..
      //const mimeType = ["image/jpeg", 0.9]  // png is standard, no need for writing it out
      

      //const blob = await b64toBlob(newExifJpg, mimeType);

      //const b = window.atob(newExifJpg)

      //
      //
      //
      //  SAVING THE IMAGE AT THE USER S DEVICE
      //
      saveAs(newExifJpg, fileName);








        ///////////////////////////////////////////////
      /*
        if (fileName.toLowerCase().endsWith('.jpg') || fileName.toLowerCase().endsWith('.jpeg')) {
            mimeType = ["image/jpeg", 0.9]
        }  


        canRef.toBlob(function(blob) {
            saveAs(blob, fileName);
        }, ...mimeType);      
      */
    }
    catch(err) {
      alert('Error in saveCanvasWithExif:', err)
    }
}






// save canvas as local file in browser
export const createUploadImagWithExif = async({ 
  canRef=null, 
  fileName="noName.jpg", 
  oldExifData=null, // exif data from opening the initial file, with corrected rotation
  uiData=null, // data that the user entered in the UI
  timeStamp=null,

  saveImageOptions=SAVE_IMAGE_OPTIONS, 
  saveImageFormat=SAVE_IMAGE_FORMAT }) => {


  let mimeType = []  // png is standard, no need for writing it out

  ///////////////////////////////////////////////////
  // stuff coming in from saveExif functionality in processImage:


  let zeroth = oldExifData["0th"] 
  let exif = oldExifData["Exif"] 
  let gps = oldExifData["GPS"] 

  try {
      
    console.log('### exifData:', oldExifData)

    if (!!timeStamp && timeStamp > 0) {
      const dateTimeObj = toDateTimeObj(timeStamp,':',':')
      exif[37510] = dateTimeObj.dateOnly + ' ' + dateTimeObj.timeOnly

    }

    //might also want to set the timezone...
    //const exifTimeZone = exif[36881];    // 0x9011 = 36881   // == timezone



    // Exif.Image.ImageWidth / ImageLength
    // 0x010d 	269 	Image 	Exif.Image.DocumentName 	Ascii 	The name of the document from which this image was scanned
    // 0x013b 	315 	Image 	Exif.Image.Artist 	Ascii
    // 0x013c 	316 	Image 	Exif.Image.HostComputer 	Ascii
    // 0x02bc 	700 	Image 	Exif.Image.XMLPacket 	Byte 	XMP Metadata (Adobe technote 9-14-02)
    // 0x800d 	32781 	Image 	Exif.Image.ImageID 	Ascii 	ImageID is the full pathname of the original, high-resolution image, or any other identifying string that uniquely identifies the original image (Adobe OPI).
    /* 0x8298 	33432 	Image 	Exif.Image.Copyright 	Ascii 	Copyright information. In this standard the tag is used to indicate both the photographer and editor copyrights. It is the copyright notice of the person or organization claiming rights to the image. The Interoperability copyright statement including date and rights should be written in this field; e.g., "Copyright, John Smith, 19xx. All rights reserved.". In this standard the field records both the photographer and editor copyrights, with each recorded in a separate part of the statement. When there is a clear distinction between the photographer and editor copyrights, these are to be written in the order of photographer followed by editor copyright, separated by NULL (in this case since the statement also ends with a NULL, there are two NULL codes). When only the photographer copyright is given, it is terminated by one NULL code. When only the editor copyright is given, the photographer copyright part consists of one space followed by a terminating NULL code, then the editor copyright is given. When the field is left blank, it is treated as unknown.
    */
    // 0x8769 	34665 	Image 	Exif.Image.ExifTag 	Long 	A pointer to the Exif IFD. Interoperability, Exif IFD has the same structure as that of the IFD specified in TIFF. ordinarily, however, it does not contain image data as in the case of TIFF.

    /*
    0x9c9b 	40091 	Image 	Exif.Image.XPTitle 	Byte 	Title tag used by Windows, encoded in UCS2
    0x9c9c 	40092 	Image 	Exif.Image.XPComment 	Byte 	Comment tag used by Windows, encoded in UCS2
    0x9c9d 	40093 	Image 	Exif.Image.XPAuthor 	Byte 	Author tag used by Windows, encoded in UCS2
    0x9c9e 	40094 	Image 	Exif.Image.XPKeywords 	Byte 	Keywords tag used by Windows, encoded in UCS2
    0x9c9f 	40095 	Image 	Exif.Image.XPSubject 	Byte 	Subject tag used by Windows, encoded in UCS2

    // JS encode / decode UCS2:
    https://maketips.net/tip/239/convert-to-ucs2-and-from-ucs2-in-javascript
    which uses punycode npm module to encode utf8 into limited ascii character set:
    https://www.npmjs.com/package/punycode
    */



    zeroth[piexif.ImageIFD.Orientation] = 1
    zeroth[piexif.ImageIFD.ImageDescription] = "new uiData coming here.."

    // not showing up in exif editor
    zeroth[piexif.ImageIFD.DocumentName] = "original photo document name here"

    // not showing up in exif editor
    zeroth[piexif.ImageIFD.ImageID] = "original photo ImageID name here, might overwrite some File path..."

    zeroth[piexif.ImageIFD.Artist] = "the logged in Artist.."
    zeroth[piexif.ImageIFD.Copyright] = "Copyright, HuaJin Consulting Co Ltd, 2019. All rights reserved"


    // write tags into a text array
    // this might leave out newly created (added) data... check!!
    const cleanData = Object.keys(uiData).map( item => {
      if (!!uiData[item].saveExif && uiData[item].selected && uiData[item].values && uiData[item].dockedText && uiData[item].dbKey) {
        //return { key: uiData[item].dockedText, values: uiData[item].values.join(',') }

        const valStr = uiData[item].values.length > 0 ? uiData[item].values.join(',') : uiData[item].values[0]
        const renderStr = uiData[item].dbKey + ': ' + valStr 
        return renderStr

      } 
      //return null
      return ''
    })


    // adding a exif.photo.UserComment field at address 37510... careful, the whole (exif) block can t be over 64kb
    //exif[37510] = JSON.stringify(cleanData)
    exif[37510] = cleanData.join('\n')
    zeroth[piexif.ImageIFD.ImageDescription] = cleanData.join('\n')  // trying out desription/caption as new place for tags


    // also putting it into the windows XP Keywords section of exif (also got XP Title, Subject, Comments, Author)
    //exif[40094] = cleanData.join('\n')


      /*
    exif[37510] = '12345678'.concat(`&#x80fd;&#x7f8e;&#x5831;&#x524d;&#x55b6;&#x7c73;&#x59d4;&#x6b69;&#x6bb5; {focuses} on a self-contained incident or series of linked incidents, with the intent of evoking a "single effect" or mood, however there are many exceptions to this.

    A dictionary definition is "an invented prose narrative shorter than a novel usually dealing with a few characters and aiming at unity of effect and often concentrating on the creation of mood rather than plot."[1]

    The short story is a crafted form in its own right. Short stories make use of plot, resonance, and other dynamic components as in a novel, but typically to a lesser degree. While the short story is largely distinct from the novel or novella (a shorter novel), authors generally draw from a common pool of literary techniques.

    Short story writers may define their works as part of the artistic and personal expression of the form. They may also attempt to resist categorization by genre and fixed formation.`) // the address for UserComment...
    // strangely the leading 8 characters are cut off...  was a problem with the firefox exif viewer...

    */



    const newExifObj = {"0th":zeroth, "Exif":exif, "GPS":gps};

    // store new exif object with parent callback (old and new data merged into new obj), so that it can be used later to add
    // additional data coming in from the UI
    // doStoreExif(newExifObj)


    const exifbytes = piexif.dump(newExifObj);



    // use existing canvas or create new one to show the rotated canvas
    const can = !!canRef ? canRef : document.createElement("canvas");


    const newJpgStr = can.toDataURL(saveImageFormat, saveImageOptions)

    const newExifJpg = piexif.insert(exifbytes, newJpgStr)

    /* 

    // not sure if i need to send in binary or base64 encoded data to AWS
    
        if (fileName.toLowerCase().endsWith('.jpg') || fileName.toLowerCase().endsWith('.jpeg')) {
          mimeType = ["image/jpeg", 0.9]  // png is standard, no need for writing it out
        }    

        if (fileName.toLowerCase().endsWith('.mp4') || fileName.toLowerCase().endsWith('.mpeg4')) {
          mimeType = ["video/mp4"]
        }      


        const blob = await b64toBlob(newExifJpg, mimeType);

        const b = window.atob(newExifJpg)
  */


    //
    //
    //
    //  SAVING THE IMAGE AT THE USER S DEVICE
    //
    //saveAs(newExifJpg, fileName);

    return newExifJpg

  }
  catch(err) {
    alert('Error in createUploadImagWithExif:', err)
  }

  return null

}





    // merge all canvases so it can be saved later (also need it for image preview for the save dialog)
    export const mergeCanvas = async({ mergeCanRef=null, textCanRef=null, photoCanRef=null, textBlock, canVals }) => {


        if (!mergeCanRef || !textCanRef || !photoCanRef) {
            console.log('err, mergeCanvas missing mergeCanRef, textCanRef or photoCanRef, returning early..')
            return
        }

        console.log('photoCanRef:', photoCanRef, photoCanRef.width, photoCanRef.height)

        //this.stateToText()
        const t = textBlock() //this.stateToText2() //.join('\n')

        // parameters not used..
        await textToCanvas2({ x:30, y:60, myText:t, font:"30px Arial", textCanRef:textCanRef, photoCanRef:photoCanRef, canVals })


        const canvas = photoCanRef
        const textCanvas = textCanRef

        const can3 = mergeCanRef //this.mergeCanvas
        
        //const {canVals} = this.state

        // using a thrid canvas to merge text canvas with scaled / rotated picture canvas and save

        //let can3 = document.createElement('canvas');
        can3.width = canVals.canvasW;
        can3.height = textCanRef.height //canVals.canvasH;    


        const ctx = can3.getContext('2d');

        ctx.drawImage(canvas, 0, 0)
        ctx.drawImage(textCanvas, 0, 0)

        console.log('finished mergeCanvas..')

    }
      

    
  
  

//////////////////////////////////////


      function canvasShadowOn(ctx) {
        // for shadow:
        ctx.shadowOffsetX = 3
        ctx.shadowOffsetY = 3
        ctx.shadowColor = "rgba(0,0,0,0.3)";   
        ctx.shadowBlur = 4;        
      }

      function canvasShadowOff(ctx) {
        // for shadow:
        ctx.shadowOffsetX = 0
        ctx.shadowOffsetY = 0
        ctx.shadowColor = "rgba(0,0,0,0)";   
        ctx.shadowBlur = 0;        
      }      

  



      export const textToCanvas2 = async({ myText=null, textCanRef=null, photoCanRef=null, canVals=null  }) => {

    
        if (!textCanRef || !photoCanRef || !myText || !canVals) {
          console.log('err, dont have textCanRef, photoCanRef, canVals or myText, returning early, no text/QR/barcode rendered..')
          console.log('textCanRef, photoCanRef, canVals, myText:', textCanRef, photoCanRef, canVals, myText)
          return
        }
    
    
        const barcodeLineSize = 26
        const barcodeMargin = 4
    
    
    
    
        //const textSize = 20
        //const textSize = canVals.canvasW / 25
        const textSizeBig = Math.floor(canVals.canvasH / 30)
        const textSize = Math.floor(canVals.canvasH / 35)


        const marginH = textSize / 2
        const marginV = textSize / 5



        //const qrWidth = Math.floor(textSize * 4) //180
        //const qrWidth = Math.floor(textSize * 5) //180
        const qrWidth = Math.floor(canVals.canvasH / 10) //180

    
    
    
        console.log('textToCanvas:', myText)
    
        const can = textCanRef //this.textCanRef //this.canvasRef
        const ctx = can.getContext('2d');

        ctx.clearRect(0, 0, can.width, can.height)
    
    
        can.width = canVals.canvasW;
        can.height = canVals.canvasH + qrWidth + 30;    
    



      // left bar code white background
      ctx.fillStyle = "#FFFFFF";
      //ctx.fillRect(0, 0, 95, canVals.canvasH)
      ctx.fillRect(0, 0, barcodeLineSize+barcodeMargin*2, canVals.canvasH)
    
      // right bar code white background
      ctx.fillRect(canVals.canvasW-barcodeLineSize-barcodeMargin*2, 0, canVals.canvasW, canVals.canvasH)

      
      // top white bar
      ctx.fillRect(0, 0, canVals.canvasW, barcodeLineSize+barcodeMargin*2)

    
      // bottom white bar
      ctx.fillRect(0, canVals.canvasH, canVals.canvasW, can.height)



        ctx.font = "bold " + textSizeBig + "px Arial";
        ctx.strokeStyle = "rgb(255, 255, 255)";
        ctx.fillStyle = "rgba(126, 200, 240, .9)"; 

        // for shadow:
/*        
        ctx.shadowOffsetX = 3
        ctx.shadowOffsetY = 3
        ctx.shadowColor = "rgba(0,0,0,0.3)";   
        ctx.shadowBlur = 4;
*/
        canvasShadowOn(ctx)



        const newX = (barcodeLineSize + barcodeMargin*2 + 25)//- marginH*2
        //const newY = marginH + marginH + marginH + marginH + textSize
        const newY = Math.floor(canVals.canvasH / 20) + textSizeBig


    
        let i = 0
        let barcodeStr = ''//'www.koi-institute.com?t='
    
        ctx.fillStyle = "rgba(255, 255, 255, .9)"; 


        myText.block1.forEach( item => {
    
          if (true) {
          //  if (item.length > 0) {
           // if (i < 4) {
              barcodeStr = barcodeStr.length > 0 ? barcodeStr + ' ' + item : item
           // }
    
            // create extra spacing after first line, and before last line 
            if (item.length > 0) {
              console.log('block1 ctx.measureText(myText):', item, ctx.measureText(item))
              ctx.fillText(item, newX, (newY + ((textSize + (textSize/4)) * i))  ); 
            }
           
            ctx.font = textSize + "px Arial";
     
            i++ 
          }
        })
       

        canvasShadowOff(ctx)       
        

        i = 0   
        ctx.font = Math.floor(textSize/5*4) + "px Arial";
         
        myText.block2.forEach( item => {
    
          if (true) {
          //  if (item.length > 0) {
           // if (i < 4) {
              barcodeStr = barcodeStr.length > 0 ? barcodeStr + ' ' + item : item
           // }
    
            console.log('block2 ctx.measureText(myText):', ctx.measureText(item))
            console.log('block2 item:', item)


            ctx.fillStyle = "rgba(0, 0, 0, .9)"; 
            // create extra spacing after first line, and before last line 
            ctx.fillText(item, barcodeLineSize+barcodeMargin*2, (canVals.canvasH + textSize*1.3 + ((textSize + (textSize/4)) * i))  ); 
           
            //ctx.font = textSize + "px Arial";
     
            i++ 
          }
        })





/*
        drawStars(ctx, { stars: 5,
          cx: canVals.canvasW-newX-(textSize*4), 
          cy: (canVals.canvasH + textSize*1.3 + ((textSize + (textSize/4)) * 0))-textSize/2, 
          spikes:5, 
          outerRadius:Math.floor(textSize/3), 
          innerRadius:Math.floor(textSize/6), 
          strokeColor:'orange', fillColor:'yellow'}) 
*/
        // cx, cy, crownWidth, crownHeight, spaceBetween

        const crownSize = Math.floor(textSize*0.8)

        console.log('drawing crowns for body/skin/pattern/finish:', myText.ratings.ratingBody, myText.ratings.ratingSkin, myText.ratings.ratingPattern, myText.ratings.ratingFinishing)

        await drawCrowns(ctx, { crowns:myText.ratings.ratingBody, 
          label: 'Body',
          labelFixLen: textSize*2.2,
          cx: canVals.canvasW-newX-(textSize*5.5)-(textSize/2), // because stars start from center.. pictures in corner
          cy: (canVals.canvasH + textSize*1.3 + ((textSize - (textSize/4)) * 0))-textSize,
          textSize,
          crownWidth: crownSize, //35, 
          crownHeight: crownSize, //35,
          spaceBetween: 0 })

        await drawCrowns(ctx, { crowns:myText.ratings.ratingSkin, 
          label: 'Skin',
          labelFixLen: textSize*2.2,
          cx: canVals.canvasW-newX-(textSize*5.5)-(textSize/2), // because stars start from center.. pictures in corner
          cy: (canVals.canvasH + textSize*1.3 + ((textSize - (textSize/4)) * 1))-textSize,
          textSize,
          crownWidth: crownSize, 
          crownHeight: crownSize,
          spaceBetween: 0})

        await drawCrowns(ctx, { crowns:myText.ratings.ratingPattern, 
          label: 'Pattern',
          labelFixLen: textSize*2.2,
          cx: canVals.canvasW-newX-(textSize*5.5)-(textSize/2), // because stars start from center.. pictures in corner
          cy: (canVals.canvasH + textSize*1.3 + ((textSize - (textSize/4)) * 2))-textSize,
          textSize,
          crownWidth: crownSize, 
          crownHeight: crownSize,
          spaceBetween: 0})     
        
        await drawCrowns(ctx, { crowns:myText.ratings.ratingFinishing, 
          label: 'Finishing',
          labelFixLen: textSize*2.2,
          cx: canVals.canvasW-newX-(textSize*5.5)-(textSize/2), // because stars start from center.. pictures in corner
          cy: (canVals.canvasH + textSize*1.3 + ((textSize - (textSize/4)) * 3))-textSize,
          textSize,
          crownWidth: crownSize, 
          crownHeight: crownSize,
          spaceBetween: 0})   
          
        // body:40, skin:30, patt:20, fin:10
        // 5..40
        // r..x   x = r*40/5

        // 100%..5
        // r%..x  x = r%*5/100

        
        const { ratingBody=0, ratingSkin=0, ratingPattern=0, ratingFinishing=0 } = myText.ratings
        const sumRating = (ratingBody*40/5 + ratingSkin*30/5 + ratingPattern*20/5 + ratingFinishing*10/5) * 5 / 100  
        

/*        
          // BREEDER
        drawCrowns(ctx, { crowns:sumRating-1, 
          label: 'Breeder:',
          labelFixLen: 90,
          cx: canVals.canvasW-newX-20-(textSize*5.5)-(textSize/2), // because stars start from center.. pictures in corner
          cy: canVals.canvasH - textSize*1.3 - textSize, 
          //cy: (canVals.canvasH + textSize*1.3 + ((textSize - (textSize/4)) * 4))-textSize,
          textSize,
          textColor:'white',
          crownWidth: 35, 
          crownHeight: 35,
          spaceBetween: 0})  
*/



/*
        drawStars(ctx, { stars: 2,
          cx: canVals.canvasW-newX-(textSize*4), 
          cy: (canVals.canvasH + textSize*1.3 + ((textSize + (textSize/4)) * 2))-textSize/2, 
          spikes:5, 
          outerRadius:Math.floor(textSize/3), 
          innerRadius:Math.floor(textSize/6), 
          strokeColor:'orange', fillColor:'yellow'}) 
*/


/*

          // QUEEN
        drawCrowns(ctx, { crowns:sumRating, 
          label: 'Queen:',
          labelFixLen: 90,
          cx: canVals.canvasW-newX-20-(textSize*5.5)-(textSize/2), // because stars start from center.. pictures in corner
          cy: canVals.canvasH - textSize*1.3, 
          //cy: (canVals.canvasH + textSize*1.3 + ((textSize - (textSize/4)) * 4))-textSize,
          textSize,
          textColor:'white',
          crownWidth: 35, 
          crownHeight: 35,
          spaceBetween: 0})   
*/


/*
        ctx.fillStyle = "rgba(0, 0, 0, .9)"; 
        ctx.font = Math.floor(textSize/2) + "px Arial";
        ctx.fillText('', uidX, newY  ); 
*/

        
/*

        drawStars(ctx, { stars: 2,
          cx: canVals.canvasW-newX-(textSize*4), 
          cy: (canVals.canvasH + textSize*1.3 + ((textSize + (textSize/4)) * 2))-textSize/2, 
          spikes:5, 
          outerRadius:Math.floor(textSize/3), 
          innerRadius:Math.floor(textSize/6), 
          strokeColor:'orange', fillColor:'yellow'}) 
                        

        drawStars(ctx, { stars: 4,
          cx: canVals.canvasW-newX-(textSize*4), 
          cy: (canVals.canvasH + textSize*1.3 + ((textSize + (textSize/4)) * 3))-textSize/2, 
          spikes:5, 
          outerRadius:Math.floor(textSize/3), 
          innerRadius:Math.floor(textSize/6), 
          strokeColor:'orange', fillColor:'yellow'}) 
          */




      // add footer - should be turned vertical later, to not distrub the fish
      ctx.fillStyle = "rgba(255, 255, 255, .9)"; 
      ctx.font = Math.floor(textSize/3*2) + "px Arial";
      
      const FOOTER_TEXT = 'Copyright © HuaJin Consulting Co Ltd'    // copyright symbol {'\u00A9'}
       
    
    
    
    
    /*  
        bardcode.drawBarcode(ctx, barcodeStr, { 
          type: "Code 128", 
          angle: 90, 
          height: 50, 
          maxWidth: canVals.canvasH-100,
          quietZoneSize: 20,
          x: 20
      });
    */
      const halfStr = Math.floor(barcodeStr.length/2)
      const str1 = barcodeStr.slice(0, halfStr)
      const str2 = barcodeStr.slice(halfStr)
      console.log('str1:', str1)
      console.log('str2:', str2)

      /*
      bardcode.drawBarcode(ctx, str1, { 
        type: "Code 128", 
        angle: 90, 
        height: barcodeLineSize, 
        maxWidth: canVals.canvasH-60,
        quietZoneSize: 20,
        x: barcodeMargin
      });
    
      bardcode.drawBarcode(ctx, str2, { 
        type: "Code 128", 
        angle: 90, 
        height: barcodeLineSize, 
        maxWidth: canVals.canvasH-60,
        quietZoneSize: 20,
        x: canVals.canvasW-barcodeLineSize-barcodeMargin
      });  
*/


    
      // size and position of qr code
      //const qrWidth = Math.floor(textSize * 4) //180
    
      //const qrX = canVals.canvasW-barcodeLineSize-barcodeMargin*2- Math.floor(qrWidth / 1.5)-35
      
      const smallQrSize = qrWidth // Math.floor(qrWidth / 1.5)
      //const qrY = newY-qrWidth + marginH + textSize

      const qrY = newY-(textSize-textSize/4) + textSize + 30//25
      const qrX = canVals.canvasW-newX-smallQrSize   
      //const qrX = canVals.canvasW-qrY-smallQrSize
    
    
      // copying the background of the QR code out of the photo canvas
      // so that it blends in well
    
      const photoCan = photoCanRef //this.canvasRef //this.canvasRef
      const photoCtx = photoCan.getContext('2d'); 
      
      photoCtx.width = canVals.qrWidth;
      photoCtx.height = canVals.qrWidth;    
    
      //const cutImgData = photoCtx.getImageData(qrX, qrY, qrWidth, qrWidth);
    
      const qrCan = document.createElement("CANVAS");
    /*
      const qrCtx = qrCan.getContext("2d")  
    
      //qrCtx.putImageData(cutImgData, 0, 0);
    
      qrCtx.drawImage(photoCan,0,0,qrWidth,qrWidth, 0, 0, qrWidth, qrWidth);
      const qrBackDataUrl = qrCan.toDataURL('image/png');
    */
    
    
    /*
      const img = new Image();
      img.crossOrigin = "Anonymous";
      //img.src = 'static/images/lilly4.png';
      //img.src = 'static/images/blueback.png';
      img.src = qrBackDataUrl //`url(${qrBackDataUrl})` 
       
      await onload2promise(img);
      */
/*    
      const logoImg = new Image();
      logoImg.crossOrigin = "Anonymous";
      logoImg.src = 'static/images/ikilogo1.png' //'static/images/ikilogo2-1.png' //ikilogo1black.png' //iki1.png';
      await onload2promise(logoImg);
*/
      console.log('about to create QR code..')
    
        MyQRInstance = new ArtQR().create({
          //text: "https://koi-institute.com/koitag/asdfEEASD",
          text: "https://m.koitag.com/"+myText.uid,

          size: smallQrSize,
          margin: 0, //3, //5,
          dotScale: 0.4, //0.35,
          //maskedDots: true,
          colorDark: "#000000", //"#00008B",
          colorLight: "#FFFFFF", //"#8CBED6",
          //whiteMargin: true,        
          //backgroundDimming: 'rgba(0,0,0,0)',
          backgroundImage: qrCan, //qrBackDataUrl, //img,
    
          /*
          logoImage: logoImg,
          logoScale: 0.2,
          logoMargin: 3,
          logoCornerRadius: 8,
          */
          binarize: false,    
          //binarize: true,
          //binarizeThreshold: 128,    
          callback: function (qrDataUri) {
            //console.log('got qr:',qrDataUri)
    
    
            var qrimg = new Image();
            qrimg.onload = function(){
              ctx.drawImage(qrimg,qrX,qrY); // Or at whatever offset you like
    
              ctx.save() 
            };
            qrimg.src = qrDataUri;            
          },
          bindElement: 'qr' // id of <img /> in real dom
        });


        canvasShadowOn(ctx)


        ctx.fillStyle = "rgba(255, 255, 255, .9)"; 
        //ctx.font = Math.floor(textSize/5*4) + "px Arial";
        ctx.font = "bold " + textSizeBig + "px Arial";


        //ctx.font = textSize + "px Arial";

        const uidX = canVals.canvasW-newX-(ctx.measureText(myText.uid).width)

        //const uidX = canVals.canvasW-newX-smallQrSize
        ctx.fillText(myText.uid, uidX, newY  ); 
        console.log('writing uid:', myText.uid)



        ctx.font = Math.floor(textSize/5*4) + "px Arial";
        const footerY = ctx.measureText(FOOTER_TEXT).width + qrY + qrWidth + (textSize) //newY-(textSize-textSize/4) + textSize + 30 +   //25

        //const footerY = ctx.measureText(FOOTER_TEXT).width + qrY + (textSize + 30)*2 //newY-(textSize-textSize/4) + textSize + 30 +   //25
        //const footerX = canVals.canvasW-newX
        const footerX = canVals.canvasW-newX

        console.log('footerX, footerY:', footerX, footerY)
        console.log('measureText:', ctx.measureText(FOOTER_TEXT))
        console.log('can.width:', can.width)
        console.log('can.height:', can.height)

        ctx.save();
        //ctx.translate(100, 100);
        ctx.rotate(-Math.PI / 2);
        ctx.fillText(FOOTER_TEXT, -footerY, footerX ); 
        ctx.restore();         
    
    
    /*
        MyQRInstance = await new ArtQR().create({
          text: barcodeStr,
          size: qrWidth,
          margin: 0, //3, //5,
          dotScale: 0.4, //0.35,
          //maskedDots: true,
          colorDark: "#000000", //"#00008B",
          colorLight: "#FFFFFF", //"#8CBED6",
          //whiteMargin: true,        
          //backgroundDimming: 'rgba(0,0,0,0)',
          backgroundImage: qrCan, //qrBackDataUrl, //img,
    
          
          //logoImage: logoImg,
          //logoScale: 0.2,
          //logoMargin: 3,
          //logoCornerRadius: 8,
          
          binarize: false,    
          //binarize: true,
          //binarizeThreshold: 128,    
          callback: function (qrDataUri) {
            //console.log('got qr:',qrDataUri)
    
    
            var qrimg = new Image();
            qrimg.onload = function(){
    
                ctx.drawImage(qrimg, (canVals.canvasW-barcodeLineSize-barcodeMargin*3)-qrWidth, can.height-(qrWidth+marginH)); // testing the look of two QR codes on the pictures
              //ctx.drawImage(qrimg,qrX, can.height-(qrY+qrWidth+70); // testing the look of two QR codes on the pictures
    
              ctx.save() 
            };
            qrimg.src = qrDataUri;            
          },
          bindElement: 'qr' // id of <img /> in real dom
        });
    */
        // some drawing speeds are too slow... hack
        await new Promise(resolve => setTimeout(resolve, 500));
        
    
        // download the QR code is easy
        //MyQRInstance.download()
    
        
    
        ctx.save()      
      }
          






















      

/*

////////////////////////////////

    // save canvas to file
    mergeAndSaveCanvas = async (fileName=null) => {

      //this.stateToText()
      const t = this.stateToText2() //.join('\n')

      await this.textToCanvas({ x:30, y:60, myText:t, font:"30px Arial", textCanRef:this.textCanRef, photoCanRef:this.canvasRef })

      const canvas = this.canvasRef
      const textCanvas = this.textCanRef
      const can3 = this.mergeCanvas
      const { canVals } = this.state

      // using a thrid canvas to merge text canvas with scaled / rotated picture canvas and save

      //let can3 = document.createElement('canvas');
      can3.width = canVals.canvasW;
      can3.height = canVals.canvasH;    


      const ctx = can3.getContext('2d');

      ctx.drawImage(canvas, 0, 0)
      ctx.drawImage(textCanvas, 0, 0)

      await saveCanvas({ canRef: this.mergeCanvas, fileName: !fileName ? this.state.uid+'.jpg' : fileName })
      // also works sending along the canvas directly:
      // await saveCanvas({ canRef: can3, fileName })

    }
  

    
///////////////////////////////////////////////////////////////////////////



*/






/*

using exif-js, rather than piexifjs  (no write back to EXIF data)
// reads the default image from URL and call imageProcessor with it
export const urlImageProcessor = async({ photoUrl=null, ...props }) => {
                                        //maxImageSize=MAX_IMAGE_SIZE, 
                                        //saveImageOptions=SAVE_IMAGE_OPTIONS, 
                                        //saveImageFormat=SAVE_IMAGE_FORMAT,
                                        //canRef=null }) => { 

//var blob = null;
var xhr = new XMLHttpRequest();
xhr.open("GET", photoUrl);
xhr.responseType = "blob";//force the HTTP response, response-type header to be blob
xhr.send()

await onload2promise(xhr)

const res = await imageProcessor({ ...props, target:xhr.response })

console.log('urlImageProcessor res:', res)
return res

}


// scale and rotate image according to EXIF information on client side
//
// in: file[0] and ref to canvas
// out: image and canVals (canvasSpecs)
export const imageProcessor = async({   target, 
                                        maxImageSize=MAX_IMAGE_SIZE, 
                                        saveImageOptions=SAVE_IMAGE_OPTIONS, 
                                        saveImageFormat=SAVE_IMAGE_FORMAT,
                                        canRef=null }) => {

// imageToBase64

    const reader = new FileReader();
    // Read the target as DataURL.
    reader.readAsArrayBuffer(target);    //piexifjs uses (to read/write exif values):   reader.readAsDataURL(target)
    const arrayBufferRes = await onload2promiseE(reader);


// getArrayBufferOrientation

    // Read EXIF Orientation data.
    const arrayBufferExif = EXIF.readFromBinaryFile(arrayBufferRes.target.result);
    // Assign orientation value.
    const orientation = arrayBufferExif.Orientation;


// orientImage

    // Create a new Image called thisImage.
    const thisImage = new Image();
    // Read thisImage into a new Object URL.
    thisImage.src = URL.createObjectURL(target);
    await onload2promise(thisImage);

    // Create a consitient ratio to original image.
    const ratio = thisImage.width / thisImage.height;
    const canvasSpecs = createCanvasSpecs({ scaleSize: maxImageSize, ratio, orientation })


// imageToCanvas

    // use existing canvas or create new one to show the rotated canvas
    const can = !!canRef ? canRef : document.createElement("canvas");
    

    // Create '2D' context.
    const ctx = can.getContext('2d');

    // Perform Canvas Operations.
    can.width = canvasSpecs.canvasW;
    can.height = canvasSpecs.canvasH;
    
    ctx.rotate(canvasSpecs.rotate); 
    ctx.translate(canvasSpecs.translateX, canvasSpecs.translateY);
    
    ctx.save();
    ctx.drawImage(thisImage, 0, 0, canvasSpecs.drawImgX, canvasSpecs.drawImgY);
    ctx.restore();

    console.log('canvasSpecs created:', canvasSpecs)

    return { canVals: canvasSpecs, image: can.toDataURL(saveImageFormat, saveImageOptions) }

}


*/
