import React, { Component } from 'react';
import { render } from 'react-dom';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Grid from '@material-ui/core/Grid';


// not sure yet from where i ll call this dialog

import YesIcon from '@material-ui/icons/Check'
import NoIcon from '@material-ui/icons/Close'



function Transition(props) {
    return <Slide direction="up" {...props} />;
}


let resolve;

const defaultProps = {
  title: 'Confirmation',
  message: 'Are you sure?'
};


class Confirm extends Component {
  static create(props = {}) {
    const containerElement = document.createElement('div');
    document.body.appendChild(containerElement);
    return render(<Confirm createConfirmProps={props} />, containerElement);
  }

  constructor() {
    super();

    this.state = {
      isOpen: false,
      showConfirmProps: {},
      icon: null
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.show = this.show.bind(this);
  }

  handleCancel(e) {
    //this.setState({ isOpen: false });
    this.handleClose(e);

    resolve(false);
  }

  handleConfirm(e) {
    //this.setState({ isOpen: false });
    this.handleClose(e);

    resolve(true);
  }


  handleClose(e) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()     
    }

    this.setState({ isOpen: false });

  }


  show(props = {}) {
    const showConfirmProps = { ...this.props.createConfirmProps, ...props };
    this.setState({ isOpen: true, showConfirmProps });
    return new Promise((res) => {
      resolve = res;
    });
  }

  render() {
    const { isOpen, showConfirmProps } = this.state;
    const { message, title, icon, ...rest } = showConfirmProps;

    return (

        <Dialog
            style={{ zIndex:"10000" }}
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
              <Grid container>
                <Grid >
                  {icon || defaultProps.icon} 
                </Grid>
                <Grid>
                  {title || defaultProps.title}
                </Grid>
              </Grid>

            </DialogTitle>


            <DialogContent>


                <DialogContentText id="alert-dialog-slide-description">
                
                  {message || defaultProps.message}

                </DialogContentText>
            </DialogContent>


            <DialogActions>
        
                <Button variant="outlined" size="small" onClick={this.handleCancel} ><NoIcon /></Button>
                <Button variant="outlined" size="small" onClick={this.handleConfirm} ><YesIcon /></Button>

            </DialogActions>
        </Dialog>



    );
  }
}

export default Confirm;
