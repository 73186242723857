/**
 * Talisman phonetics/statcan
 * ===========================
 *
 * The statistics Canada name coding technique.
 *
 * [Reference]:
 * http://naldc.nal.usda.gov/download/27833/PDF
 */

// STATCAN 

import deburr from 'lodash/deburr';
//import {squeeze} from '../helpers';

/**
 * Constants.
 */
const DROPPED = /[AEIOUY]/g;

/**
 * Function taking a single name and computing its statcan code.
 *
 * @param  {string}  name - The name to process.
 * @return {string}       - The statcan code.
 *
 * @throws {Error} The function expects the name to be a string.
 */
export default function statcan(name) {

  if (typeof name !== 'string')
    throw Error('talisman/phonetics/statcan: the given name is not a string.');

  let code = deburr(name)
    .toUpperCase()
    .replace(/[^A-Z\s]/g, '');

  // 1-- Keeping the first letter
  const first = code[0];
  code = code.slice(1);

  // 2-- Dropping vowels and Y
  code = code.replace(DROPPED, '');

  // 3-- Dropping consecutive duplicates
  code = squeeze(code);

  // 4-- Dropping blanks
  code = code.replace(/\s/g, '');

  // 5-- Limiting code size to 4
  return (first + code).slice(0, 4);
}





///////////////



/**
 * Function squeezing the given sequence by dropping consecutive duplicates.
 *
 * Note: the name was actually chosen to mimic Ruby's naming since I did not
 * find any equivalent in other standard libraries.
 *
 * @param  {mixed} target - The sequence to squeeze.
 * @return {array}        - The resulting sequence.
 */
export function squeeze(target) {
  const isString = typeof target === 'string',
        sequence = seq(target),
        squeezed = [sequence[0]];

  for (let i = 1, l = sequence.length; i < l; i++) {
    if (sequence[i] !== sequence[i - 1])
      squeezed.push(sequence[i]);
  }

  return isString ? squeezed.join('') : squeezed;
}


/**
 * Function normalizing the given variable into a proper array sequence.
 *
 * @param  {mixed} target - The variable to normalize as a sequence.
 * @return {array}        - The resulting sequence.
 */
export function seq(target) {
    return typeof target === 'string' ? target.split('') : target;
  }