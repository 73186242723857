/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
//import Select from 'react-select';
//import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import CreatableSelect from 'react-select/lib/Creatable';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';





const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
    backgroundColor: 'rgba(200, 200, 200, 0.5)',

    //zIndex: 20000,
  },
  input: {
    display: 'flex',
    padding: 0,
    width: '10em',       // added by chris
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: 'rgba(200, 200, 200, 0.5)',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),

  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(200, 200, 200, 0.5)',

  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});




function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}




function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} type="number" />;
}


// input type="number"   not working
// aparrently there s a type property for list type.. of different usage..


function Control(props) {
  console.log('## Control props:', props)
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
          //type: "number",  // props.selectProps.inputType,       
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}




function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}



function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}



function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}



function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}



function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}



function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}



const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};





class IntegrationReactSelect extends React.Component {

  /*
    constructor(props) {
        super(props)
        this.creatableRef = React.createRef()  // for vertical scrolling of autoSuggest (the menu gets covered by mobile keyboard otherwise)
    }
*/

  state = {
    single: null,
    multi: null,
    suggestions: [],


  };


  


  componentDidMount() {

    // convert tagGroup choices list into suggestions list
    this.setState({ suggestions: this.props.suggestions.map(suggestion => ({
        value: suggestion.key,
        label: suggestion.text,
      }))                    
    })

    
/*
    console.log('(autoSuggest2) this.creatableRef:', this.creatableRef)

    if (!!this.creatableRef && !!this.creatableRef.current) {

        console.log('(autoSuggest2) this.createableRef.current is working..')
        //this.creatableRef.current.scrollTo(0, 0);

        
        this.creatableRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            //block: 'center',
            inline: 'end',
          })
               
    }

    */

  }




  handleChange = name => value => {
    this.setState({
      [name]: value,
    });
    //console.log('handleChange: ', value)

    if (!!value && !!value.__isNew__) {
        //console.log('got a new value:', value.value)
        this.props.onNew(value.value)
        return
    }

    if (!!value) {
        this.props.onSelect(value.value)
        return
    }
  };




  render() {
    const { classes, theme } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    return (
      <div className={classes.root}>
        <NoSsr>


          <CreatableSelect
            ref={this.creatableRef}
            classes={classes}
            styles={selectStyles}
            options={this.state.suggestions}
            components={components}
            value={this.state.single}
            onChange={this.handleChange('single')}
            placeholder="Type n Search..."
            isClearable
            autoFocus
            menuIsOpen
            inputType={!!this.props.inputType? this.props.inputType : null}
          />


        </NoSsr>
      </div>
    );
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);


/*




            ref={ ref => { this.creatableRef = ref; }}

            ref={this.creatableRef}




import React, { Component } from 'react';

import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import { colourOptions } from '../data';

const filterColors = (inputValue: string) => {
  return colourOptions.filter(i =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};

const promiseOptions = inputValue =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(filterColors(inputValue));
    }, 1000);
  });

export default class WithPromises extends Component<*, State> {
  render() {
    return (
      <AsyncCreatableSelect
        cacheOptions
        defaultOptions
        loadOptions={promiseOptions}
      />
    );
  }
}







////////////////////////////



          <div className={classes.divider} />


          <Select
            classes={classes}
            styles={selectStyles}
            textFieldProps={{
              label: 'Label',
              InputLabelProps: {
                shrink: true,
              },
            }}
            options={this.state.suggestions}
            components={components}
            value={this.state.multi}
            onChange={this.handleChange('multi')}
            onKeyPress={this.onKeyPress}            
            placeholder="Select multiple countries"
            isMulti
          />
          */