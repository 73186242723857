// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:00919971-2436-46cd-9dc2-06cb0d3a87f6",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_0mhi07IEE",
    "aws_user_pools_web_client_id": "47oclb9qs38t1umdtknvkil9sb",
    "oauth": {},
    "aws_user_files_s3_bucket": "koitag-tokyoone",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_content_delivery_bucket": "koi-tag-one-hosting-tokyoone",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d2zhy0574uta1u.cloudfront.net"
};


export default awsmobile;
