import React from 'react';
import {
    withAuthenticator,
    S3Album,
    S3Image
} from 'aws-amplify-react'
import { withRouter } from 'react-router-dom'

import { getUrlPayload, getFullHostUrl, pureSplice } from '../helpers/basics'
import { deleteImage } from '../helpers/storageIO'
import confirmService from './confirmService';


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Fab from '@material-ui/core/Fab';


import SaveAltIcon from '@material-ui/icons/SaveAlt'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import PhotoFilterIcon from '@material-ui/icons/PhotoFilter'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CancelIcon from '@material-ui/icons/Cancel'

import { Storage, Cache } from 'aws-amplify';


import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
}));




// height of image in grid
const IMG_HEIGHT = 100

const dialogTitle = 'Browse S3'
const dialogText = ''//'Save or upload image'




// props: dialogTitle, dialogText
//
// dialog for upload status (accept, skip, reject, new)
// can maybe also used for start/stop game..


function Transition(props) {
  return <Slide direction="up" {...props} />;
}


/*
props:

isOpen
handleClose
classes
*/

class BrowseS3dialog extends React.Component {


    state = {
      keys: [], // S3 file keys

      isGettingS3list: true,
      //isGettingS3files: false,  // not used currently: not explicitely getting files, due to use of S3Image

      deleteDialogOpen: false   // delete dialog, to confirm image needs deleting
    }


    async componentDidUpdate(prevProps, prevState) {


      // only get s3 file list when "browse" button is pressed (not on first render) -> faster startup time, up-to-date file-list
      if (!prevProps.isOpen && this.props.isOpen) {

        const generatedPrefix = 'generated/'  // folder for generated files (with writing on them)


          // initially in componentDidMount() .. with more comments..
          
          // does not use an image folder yet... change that!
        Storage.list(generatedPrefix, { level: 'protected' })
                .then(result => {
                    console.log('## storage.list result:', result)
                    //const sorted = result.sort(o => o.lastModified)
                    this.setState({ isGettingS3list: false, keys: result.sort( (a,b) => b.lastModified - a.lastModified) })  
                    //this.setState({ keys: result })  
                })
                .catch(err => console.log(err))
        
        
      }

    }

  
/*
    async componentDidMount() {

        const generatedPrefix = 'generated/'  // folder for generated files (with writing on them)



        // better cache that id list to reduce API hits
        // what if we have many 1000s of images in folder, need to look thru all before sorting?
        // normal s3 api usually api give max 10,000 (or 1000?) before giving a continuationToken, not sure about amplify.storage 
        //
        // there is NO limit on how many files can be in a bucket, just on getObjects / Storage.list
        // however, 20,000 files * $0.01 per file api call = usd 200


//        Storage.list('photos/', { 
//            level: 'protected', 
//            identityId: 'xxxxxxx' // the identityId of that user        



          // this api call is not blocking, or lengthening initial load time...
          
          // does not use an image folder yet... change that!
        Storage.list(generatedPrefix, { level: 'protected' })
            .then(result => {
                console.log('## storage.list result:', result)
                //const sorted = result.sort(o => o.lastModified)
                this.setState({ isGettingS3list: false, keys: result.sort( (a,b) => b.lastModified - a.lastModified) })  
                //this.setState({ keys: result })  
            })
            .catch(err => console.log(err))
    
    }

    // Storage.get('test.txt', {expires: 60})
    // expires: new Date().now() + 60 * 60 * 24 * 7, // (Date) The date and time at which the object is no longer cacheable. ISO-8601 string, or a UNIX timestamp in seconds
*/


    logItem(item) {
        if (!!item) {
            console.log('logItem image clicked:', item.key, item.lastModified)

        } else {
            console.log('logItem image clicked, no item..')
        }
    }


    getUidFromKey(key) {

      const { uidPrefixFiller } = this.props     

      if (uidPrefixFiller === "/" && key.indexOf("_") === -1) {

        // edge-case keeps the previous /ik/ as part of the base URL
        const beforeLastSlash = key.lastIndexOf("/")-1
        
        // extract keyUid from generated/keyUid path (keyUid includes a / as part of its prefix)
        const keyUid =  key.substring(key.lastIndexOf("/",beforeLastSlash) +1)
        return keyUid

      } else {

        // extract keyUid from generated/keyUid path
        const keyUid =  key.substring(key.lastIndexOf('/') + 1) 
        return keyUid

      }       
    }


    async handleClick(item) {  
       
      
      if (!!!item || !!!item.key) return

      console.log('image clicked:', item)
      console.log('image clicked (key):', item.key)

      this.logItem(item)

//      await deleteImage({ uid:keyUid })
//      await deleteImage({ uid:keyUid })


      // extract keyUid from generated/keyUid path (keyUid includes a / as part of its prefix)
      const keyUid =  this.getUidFromKey(item.key)

      const baseUrl = !!this.props.baseUrl ? this.props.baseUrl : '/tagger'
      const url = baseUrl + '/' + keyUid
      console.log('push url:', url)
      
      this.props.history.push(url)

      this.props.handleClose()

      
    }




    doImageDelete = async (item) => {

     if (!!!item || !!!item.key) return

     // keyUid and item.key are different (item.key is the key to the generated image file, whereas KeyUid is the unique id of koi)
      const keyUid =  this.getUidFromKey(item.key)
  

      console.log('delete image clicked:', item)

      this.logItem(item)


      if (keyUid === this.props.uid) {
        console.log('deleting active (currently selected) item: ', keyUid, ' removing item.key:', item)

        await deleteImage({ uid: keyUid, generatedFileKey: item.key })


        this.props.history.push("/")
        this.props.handleClose()

        return
      }

      if (keyUid !== this.props.uid) {

        console.log('deleting item (not currently selected): ', keyUid, ' removing item.key:', item)

        await deleteImage({ uid: keyUid, generatedFileKey: item.key })

        const delIdx = this.state.keys.indexOf(item)

        this.setState({ keys: pureSplice(this.state.keys, delIdx, 1 )})

        // delete data file, original image file, generated image file..
        
        return
      }

    }



    // used currently only for testing ..
    onImageDelete = async (item) => {

      this.setState({ deleteDialogOpen: true })


      // show confirm dialog..
      const result = await confirmService.show({
        title:  ' Delete?',
        message: <S3Image  imgKey={item.key} 
                          level="protected" 
                          theme={{ photoImg: {  maxHeight: `"${IMG_HEIGHT}px"`, display: "flex", width: "100%" }}} 
                          alt={item.key} 
                  />,
        icon: <CancelIcon fontSize="large" />
      });
      if (result) {
        this.doImageDelete(item)
      }


      this.setState({ deleteDialogOpen: false })
    }




    // CURRENTLY NOT CACHING S3 image URLs...
    // CURRENTLY NOT RECURSIVELY READING IMAGES FROM s3 ==> max 1000 images !!!!
    // CURRENTLY HAVE TO GET IMAGE LIST ON EVERY RENDER, before user input starts (very inefficient)

  render() {

    const { isOpen=false, handleClose=null } = this.props
    const { classes, uidPrefixFiller } = this.props     
    
    // uidPrefixFiller uidPrefix+uidPrefixFiller+uid 
    // e.g. ik_ASDFAS
    // e.g. ik/ASDFAS (special edge case that needs to be taken care of when extracting UIDs from S3 image keys..)

    //const classes = useStyles();


    //const ImgRender = !!this.props.pic && this.props.pic.length > 0 ? <img src={this.props.pic} width={IMG_WIDTH} heigh={IMG_HEIGHT} alt='uploadImage' /> : ''
    //const RenderedImages = this.state.keys.map( item => <S3Image imgKey={item.key} theme={{ photoImg: {  maxHeight: '200px', display: 'flex', width: '100%' }}}  onClick={ () => this.logItem(item) } onMouseEnter={() => this.logItem(item)} alt={item.key} />)

    

    return (
      <div>
        <Dialog
          open={isOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">
            {dialogTitle}
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {dialogText}
            </DialogContentText>


            <div className={classes.root}>
            <GridList cellHeight={'auto'} className={classes.gridList} cols={3}>
                {this.state.keys.map(item => (
                    <GridListTile key={item.eTag} cols={item.cols || 1}>

                        <div style={{ position: 'relative', maxHeight: `"${IMG_HEIGHT}px"`, display: "flex", width: "100%" }}>
                          <S3Image  imgKey={item.key} 
                                    level="protected" 
                                    theme={{ photoImg: {  maxHeight: `"${IMG_HEIGHT}px"`, display: "flex", width: "100%" }}} 
                                    alt={item.key} 
                                    onClick={ () => this.handleClick(item) }

                          />
                          <CancelIcon style={{ position: 'absolute', top:3, right: 3, zIndex: 100 }} 
                                        onClick={ (e)=> { 
                                          //alert('delete photo?') 
                                          //e.stopPropagation()
                                          //e.preventDefault()
                                          this.onImageDelete(item)
                                        }
                                      }/>
                        </div>
                    </GridListTile>
                ))}
            </GridList>
            </div>



          </DialogContent>


          <DialogActions>

            { !!this.state.isGettingS3list || !!this.state.isGettingS3files ? 'getting S3 data..' : '' }        
            
          </DialogActions>

        </Dialog>
      </div>
    );
  }
}

export default withRouter(BrowseS3dialog);

/*
            <div className="App" style={{   display: "flex", flexWrap: "wrap", justifyContent: 'center' }}>
                {RenderedImages}
            </div>              

*/