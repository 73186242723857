import React, { Component } from 'react';
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";

import { MuiThemeProvider } from '@material-ui/core/styles'
import theme from './theme'

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";


//import logo from './logo.svg';
import './App.css';


import ProtectedPages from './pages/protectedPages'
//import BrowseS3 from './pages/browseS3'

import * as tagModel from './tagModel.json';
import { getUrlPayload } from './helpers/basics'
import { getTagDataCached, getDataCached, getImageCached, saveTagModel } from './helpers/storageIO'


import { SnackbarProvider } from 'notistack';

// polyfill needed for safari scrolling..
import smoothscroll from 'smoothscroll-polyfill';
import vhCheck from 'vh-check'

// AWS Log In...
import Amplify, { Auth } from 'aws-amplify';
//import aws_manual_setup from './aws-manual-setup';
import aws_exports from './aws-exports';

import { withAuthenticator } from 'aws-amplify-react';
Amplify.configure(aws_exports);
//Amplify.configure(aws_manual_setup);

// kick off the smoth scrolling polyfill for safari!
smoothscroll.polyfill();
//const test = vhCheck()
// initialize vertical height fix for measuring big in android mobile and safari
// import vhCheck from 'vh-check'
const topMargin = vhCheck().offset





class App extends Component {



  render() {

    //                   <Route path="/admin/:prefix/:uid" render={ProtectedPages} />
    //                   <Route path="/admin/:prefix/:uid" component={ProtectedPages} />


    return (
      <BrowserRouter>
        <div>
          <CssBaseline />
          <MuiThemeProvider theme={theme}>
            <div className="App" style={{ marginTop: 3 }}>
              
              <Switch>

                <Route path="/" exact component={ProtectedPages} />
            
                <Route path="/tagger" exact component={ProtectedPages} />


                <Route
                  path="/tagger/:prefix/:uid"
                  render={({ match }) => <ProtectedPages match={match} />}
                />      
                
                <Route
                  path="/tagger/:uid"
                  render={({ match }) => <ProtectedPages match={match} />}
                />                        


              </Switch>
                      
                      
  
            </div>
          </MuiThemeProvider> 
          
        </div>
      </BrowserRouter>
    );
  }
}


export default App // {includeGreetings: true});

//export default withAuthenticator(App, false ) // {includeGreetings: true});


/*</div>

render() {



  return (
    <BrowserRouter> 
      <div >
        <CssBaseline />

        <MuiThemeProvider theme={theme}>

          <Switch>
       


            <Route path="/" exact component={SplashScreen} />

            <Route path="/login" component={SignIn} />

            <Route path="/logout" component={SignOut} />


            <Route path="/app/:tabId" component={ requireAuth(MainUser) } />
            
            <Route path="/app" component={ requireAuth(MainUser) } />           


            <Route path="/admin" component={ requireAuth(MainAdmin) } />

            <Route path="/media" component={ requireAuth(MainAdmin) } />
            
            <Route path="/finished/:finishedRank" component={ requireAuth(FinishedUser) } />

            <Route path="/finished" exact component={ requireAuth(FinishedUser) } />


            <Route component={SplashScreen} />

          </Switch>

        </MuiThemeProvider> 

      </div>
    </BrowserRouter>
  );
}
}

export default connect(null, {})(App);
// export default connect(null, { fetchUser })(App);








    // could set a document.fullscreenEnabled variable for all others to read..
    // use this, to trigger fullscreen in app: https://github.com/sindresorhus/screenfull.js
/*    
    const fullscreenEnabled =
    document.fullscreenEnabled ||
    document.mozFullScreenEnabled ||
    document.documentElement.webkitRequestFullScreen;    
*/
    
    //console.log('fullScreenElement:', fullscreenElement)

/*
    // for special case where the QR code id (uid) comes in via URL, but gets lost during authentication
    // (when user has not logged in yet) - the callback URL can t transport the QR code id)
    // so we store the QR code id before auth in localStorage, and retrieve it after auth..
    //
    // if user is logged in already, the QR code id can be read from the URL (as no callback is called)
    //
    // URL callback works fine on localhost, not requiring this workaround... lets hope this also works on server


    const uid = localStorage.getItem("uid")

    if (!!uid) {
      console.log('## got uid from localStorage.. came in via URL before auth..:', uid)

      localStorage.removeItem("uid")
      console.log('removed uid from localStorage (url)')
    }

    const pathName = window.location.pathname
    if (pathName.length > 1) {
      
      //localStorage.setItem({ uid: pathName.slice(1) })
      console.log('## also got uid from URL:', pathName.slice(1))
    }
    
*/


    // USING this.state.imageUrl IS PROBABLY NOT A GREAT IDEA.
    // ITS ONLY USED BY ITS CHILD
    // ALSO, NEED TO KEEP TRACK OF THAT IMAGE'S DATA ==> UID
    // AND MAKE SURE I DON T GENERATE A NEW UID FOR IT ON SAVE / UPLOAD
    // OR SHOULD I ? TO TRACK CHANGES...?


    // REMOVE notistack package.. or start using it.. !!!!!!
    //          <div className="App" style={{ marginTop: fullscreenEnabled ? 8 : 70 }}>

//           <div className="App" style={{ marginTop: topMargin }}>








//export default App;



/*

import { Auth } from 'aws-amplify';


// get current user

Auth.currentAuthenticatedUser({
    bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
}).then(user => console.log(user))
.catch(err => console.log(err));



*/