import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import CloseIcon from '@material-ui/icons/Close';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';



import DoneIcon from '@material-ui/icons/Done';

import LockIcon from '@material-ui/icons/Lock';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';


import KeyboardIcon from '@material-ui/icons/Keyboard';


//import IntegrationDownshift from './autoSuggest'




const styles = theme => ({
  root: {
    textAlign: 'left',
    alignItems: 'left',
    //marginLeft: '1em'

  },
  nested: {
    marginLeft: '1em'
  },  
  chip: {
    marginTop: theme.spacing(1), //theme.spacing.unit,
    opacity: 0.8,
    //height: '1.9em', //'1.8em',
    fontSize: '1.1em', //'1.2em',
    backgroundColor:'#3333',
    color: 'white'
  },


});


const ChipSpan = {
  //position: 'static',
  display: 'flex',
  //justifyContent: 'center',
  //alignSelf: 'center',
  alignContent: 'center',
  //alignItems: 'center',
  //width: '100%',
  overflowX: 'auto',
  scrollbarWidth: 'none',  // Firefox    
  //flexWrap: 'wrap',
  //'&::-webkit-scrollbar': { // Webkit
  '&::WebkitScrollbar': {
    appearance: 'none',
    height: 0,
  }, 
  //marginLeft: 100,   
}

const ContainerSpan = {
  //display: 'absolute',


  alignItems: 'center',

  display: 'flex',
  flexWrap: 'nowrap'

}

// just used for texting
const LabelSpan = {
  //position: 'static',
  alignContent: 'left',
  textAlign: 'middle', 
  marginLeft: '0.5em',
  marginTop: '0.4em',  
}



const KeyboardIconSpan = {
  //position: 'static',
  alignContent: 'left',
  textAlign: 'middle', 
  //marginLeft: '0.5em',
  //marginTop: '0.4em',  
}




// TagBlock renderedTags=   initialOpen=false


class TagBlock extends React.Component {

    constructor(props) {
        super(props)
        this.verticalRef = React.createRef()  // for vertical scrolling of autoSuggest (the menu gets covered by mobile keyboard otherwise)
      }


    componentDidMount() {

       this.toggleOpen()
    }


    state = {
        boxIsOpen: null,

    }

    toggleOpen = async () => {   // added async in switerland..
      
        //this.setState({ boxIsOpen: (state) => !state.boxIsOpen })

        if (this.state.boxIsOpen === null) {
            if (this.props.initialOpen !== undefined) {
                this.setState({ boxIsOpen: this.props.initialOpen })
            } else {
                this.setState({ boxIsOpen: true })
            }
        } else {
            this.setState({ boxIsOpen: !this.state.boxIsOpen })
        }
        
        // scroll the newly opened/closed box into view
        setTimeout(() => {
            this.props.scrollHandler(this.verticalRef, {block: 'nearest', behavior: 'smooth'})
        },300)
        
        
    }

    handleDelete() {

    }


    render() {


        //const renderStr = {boxChip}

        //const renderStr = this.state.boxIsOpen? <div>{this.props.children}<br/>{boxChip}</div> : boxChip

        const { activeChildTags, classes, avatar='', avatarSrc='', dockedText='', dockedSymbol='' } = this.props


        return(
            <div ref={this.verticalRef} className={classes.root}>
                <Chip 
                    onClick={this.toggleOpen} 
                    label={this.props.label }
                    clickable
                    className={classes.chip}        
                    
                    key={ 'tagBlock_'+ this.props.parent + '-' + this.props.dbKey }
                    avatar={ avatarSrc.length > 0 
                      ? <Avatar src={avatarSrc} alt="avatar" /> 
                      : avatar.length > 0 
                        ? <Avatar>{avatar}</Avatar>
                        : '' }

                    deleteIcon={ !!this.state.boxIsOpen? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                    onDelete={ this.handleDelete }
                    variant={"outlined"}
                    style={{ backgroundColor: activeChildTags? "#913773" : "" }}
                  
                />
                <div style={{ display: !!this.state.boxIsOpen? "" : "none", marginLeft: '1em' }}>
                    {this.props.children}
                </div>
            </div>
        ) 

    }

}

// AVATAR CAN ALSO BE A JPEG
// avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
// "./koi6.jpg"

//       label={this.props.label + (!!this.state.boxIsOpen? " -" : " +")}


//export default TagBlock

export default withStyles(styles)(TagBlock);




/*

                <Chip 
                    onClick={this.toggleOpen} 
                    label={this.props.label + (!!this.state.boxIsOpen? " open" : " closed")}
                    clickable
                    className={classes.chip}        
                    
                    key={ 'tagBlock_'+ this.props.label }
                    avatar={ !!avatar && avatar.length > 0 ? <Avatar>{avatar}</Avatar> : null }
                    style={{backroundColor:'green'}}
                  
                />






              <TagGroup
                key='tagGroupSize'
                choices={[
                    { key:'t', text:'...'},
                    { key:'10to12', text:'10-12'},
                    { key:'12to15', text:'12-15'},
                    { key:'15to20', text:'15-20'},
                    { key:'20to25', text:'20-25'},
                    { key:'25to30', text:'25-30'},
                    { key:'30to35', text:'30-35'},
                    { key:'35to40', text:'35-40'},
                    { key:'40to45', text:'40-45'},
                    { key:'45to50', text:'45-50'},
                    { key:'50to55', text:'50-55'},
                    { key:'55to60', text:'55-60'},
                    { key:'60to65', text:'60-65'},
                    { key:'65to70', text:'65-70'},
                    { key:'70to75', text:'70-75'},
                    { key:'75to80', text:'75-80'},
                    { key:'80to85', text:'80-85'},
                    { key:'85to90', text:'85-90'},
                    { key:'90to100', text:'90-100'},
                    { key:'100p', text:'100+'}
                  ]} 
                  avatar={null}
                  selected={[]} 
                  multiSelect={false} 
                  multiSelectMaxN={null} 
                  docked={true}
                  dockedText={'Size'}
                  dockedSymbol={'cm'}
                  required

                  scrollHandler={this.scrollHandler}
                  onTagSelect={this.onTagSelect}
              />

*/




/*


function inBlock(WrappedComponent) {

    return class extends React.Component {

      
        state = {
            boxIsOpen: true,
        }

        componentWillReceiveProps(nextProps) {
            console.log('Current props: ', this.props);
            console.log('Next props: ', nextProps);
        }
        render() {
            // Wraps the input component in a container, without mutating it. Good!
            return <WrappedComponent {} {...this.props} />;

            
            <WrappedComponent
                injectedProp={injectedProp}
                {...passThroughProps}
            />  
                  
        }
    }
}

*/