

// returns a promise once image is loaded
export function onload2promise(obj){
    return new Promise(resolve => {
        obj.onload = () => resolve(obj);
    });
  }  

export function onload2promiseE(obj){
    return new Promise(resolve => {
        obj.onload = (e) => resolve(e);
    });
}  

