import { getFullHostUrl } from '../helpers/basics'

/*
material-ui icons:
star
star_border
star_half
*/

export function drawStars(ctx, { stars=1, cx, outerRadius, ...props }) {

    if (stars === 0){
        return
    }    
    for (let i=0; i < stars; i++) {
        drawStar(ctx, { ...props, cx: cx+i*(outerRadius*3), outerRadius } )
    }
    
}

export function drawStar(ctx, { cx, cy, spikes, outerRadius, innerRadius, strokeColor='blue', fillColor='skyblue'}) {
    var rot = Math.PI / 2 * 3;
    var x = cx;
    var y = cy;
    var step = Math.PI / spikes;

    ctx.strokeSyle = "#000";
    ctx.beginPath();
    ctx.moveTo(cx, cy - outerRadius)
    for (let i = 0; i < spikes; i++) {
        x = cx + Math.cos(rot) * outerRadius;
        y = cy + Math.sin(rot) * outerRadius;
        ctx.lineTo(x, y)
        rot += step

        x = cx + Math.cos(rot) * innerRadius;
        y = cy + Math.sin(rot) * innerRadius;
        ctx.lineTo(x, y)
        rot += step
    }
    ctx.lineTo(cx, cy - outerRadius)
    ctx.closePath();
    ctx.lineWidth=5;
    ctx.strokeStyle=strokeColor;
    ctx.stroke();
    ctx.fillStyle=fillColor;
    ctx.fill();

}


/*
material-ui icons:
star
star_border
star_half


fontawesome.com
crown

*/

export function loadImage(url) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.addEventListener('load', e => resolve(img));
      img.addEventListener('error', () => {
        reject(new Error(`Failed to load image's URL: ${url}`));
      });
      img.src = url;
    });
}


export async function drawCrown(ctx, { cx, cy, crownWidth, crownHeight }) { //strokeColor='blue', fillColor='skyblue'}) {

    console.log('drawing a crown..')
    
    var img = new Image()
    img.onload = function() {
        //ctx.drawImage(img, cx, cy, crownWidth, crownHeight) //, cx, cy, crownWidth, crownHeight)

        //ctx.drawImage(img, cx, cy)//, crownWidth, crownHeight, cx, cy, crownWidth, crownHeight)

        ctx.drawImage(img, 0, 0, img.width, img.height, cx, cy, crownWidth, crownHeight)
    }
    //img.src = "static/images/freeCrown20.svg" //freeCrown20.svg"
    //img.src = "static/images/crownB7_35.png" //freeCrown20.svg"
    img.src = getFullHostUrl() + "/static/images/crownB7_35.png"
  
/*    
    const img = loadImage("http://localhost:3000/static/images/crownB7_35.png")
    ctx.drawImage(img, 0, 0, img.width, img.height, cx, cy, crownWidth, crownHeight)
*/

}

// cx, cy, crownWidth, crownHeight, spaceBetween
export async function drawCrowns(ctx, { label='', labelFixLen=0, crowns=1, cx, cy, crownWidth, crownHeight, spaceBetween=0, textSize, textColor="rgba(100, 100, 100, 1)", ...props }) {

    if (crowns === 0){
        return
    }

    ctx.save()
    ctx.fillStyle = textColor; 
    ctx.font = Math.floor(textSize/2) + "px Arial";
    const labelLen = ctx.measureText(label).width
    const newCx = labelFixLen > 0? cx+labelFixLen : cx+labelLen+5


    ctx.fillText(label, cx, cy+(textSize/2)*1.2); 

    let promiseArr = []
    for (let i=0; i < crowns; i++) {
        promiseArr.push( drawCrown( ctx, 
                                    {   ...props, 
                                        cx: newCx+i*(crownWidth+spaceBetween), 
                                        cy, 
                                        crownWidth, 
                                        crownHeight 
                                    } )
        )
    }

    await Promise.all(promiseArr)

    ctx.restore()

    return true
    
}