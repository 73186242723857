import React, { Component } from 'react';
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";

import { MuiThemeProvider } from '@material-ui/core/styles'
//import theme from './theme'

/*
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
*/

//import logo from './logo.svg';
//import '../App.css';


import Tag from '../pages/tag2'
//import BrowseS3 from './pages/browseS3'

import * as tagModel from  '../tagModel.json';
import { getUrlPayload, getRouterPayload, getRouterPrefixAndId } from '../helpers/basics'
import { getTagDataCached, getDataCached, getImageCached, getTagModelCached, saveTagModel } from '../helpers/storageIO'


import { SnackbarProvider } from 'notistack';

// polyfill needed for safari scrolling..
import smoothscroll from 'smoothscroll-polyfill';
import vhCheck from 'vh-check'

// AWS Log In...
import Amplify, { Auth } from 'aws-amplify';
//import aws_manual_setup from './aws-manual-setup';
//import aws_exports from '../aws-exports';

import { withAuthenticator } from 'aws-amplify-react';
//Amplify.configure(aws_exports);
//Amplify.configure(aws_manual_setup);

// kick off the smoth scrolling polyfill for safari!
smoothscroll.polyfill();
//const test = vhCheck()
// initialize vertical height fix for measuring big in android mobile and safari
// import vhCheck from 'vh-check'
const topMargin = vhCheck().offset





class Tagging extends Component {

  state = { 
    tagModel: null, 
    argUid: null, 
    argImageUrl: null, 
    argData: [],
    fetchingImageAndData: false
  } //, argUid: null, argData: null }



  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    });
  } 


  // load data model for this folder
  async componentDidMount() {
    const { routerParams } = this.props

    console.log('componentDidMount, loadFilesAndSetState:', routerParams)
    await this.loadFilesAndSetState({ routerParams, forceFetch: true }) // can also set modelFileName (fileName.json) and forceFetch
     
  }


  // load data model for this folder
  async componentDidUpdate(prevProps, prevState) {

    const { routerParams } = this.props           
    if (!!routerParams && !!prevProps.routerParams && routerParams.url !== prevProps.routerParams.url) {
      console.log('componentDidUpdate, loadFilesAndSetState:', routerParams)
      await this.loadFilesAndSetState({ routerParams, forceFetch: true }) // can also set modelFileName (fileName.json) and forceFetch
    }
  }

 
/*
  async shouldComponentUpdate(nextProps, nextState) {

    const { routerParams } = this.props           

    
    if (!!nextProps.routerParams && !!routerParams && nextProps.url !== routerParams.url) {
      console.log('tagging shouldUpdate:', nextProps.routerParams)
      //await this.loadFilesAndSetState(nextProps.routerParams)

      return true
    }

    if (routerParams===null && !!nextProps.routerParams) {
      console.log('tagging shouldUpdate (prev url null):', nextProps.routerParams)
      //await this.loadFilesAndSetState(nextProps.routerParams)

      return true
    }
   

    if (this.state.argImageUrl !== null && this.state.argImageUrl !== nextState.argImageUrl) {
      console.log('shouldComponentUpdate true:', this.state.argImageUrl)
      return true
    }
     

  }
  */



  // load data model for this folder
  // also loads data for the image supplied an (if any)
  loadFilesAndSetState = async ({ routerParams, modelFileName='tagModel.json', cacheKey='tagModel', forceFetch=true }) => {

      console.log('loadFilesAndSetState fetchingImageAndData:', this.state.fetchingImageAndData)

      if (!this.state.fetchingImageAndData) {

        this.setState({ fetchingImageAndData: true })

        // get config file for tagGroups (only used to override existing one on S3, e.g. to reset)
        console.log('## new location coming in, TagModel from ES6:', tagModel)

        // image and data derived from QRid (argument passed in via URL)
        let argImageUrl = null
        let argData = null
        let argUid = null

        // initiate promise
        const argTagModelPromise = getTagModelCached({ modelFileName, cacheKey, forceFetch:forceFetch })   // forces to read from S3 rather than cache
        
        console.log('getTagModelCached result (to be used for setState, currently still a promise):', argTagModelPromise)

        // see if there s an incoming uid (QRid), if so, get (signed) image URL from S3
        // probably also should get data file from S3

        //argUid = getUrlPayload()
        //argUid = getRouterPayload(this.props.location, 'uid')
        


        const prefixUidObj = getRouterPrefixAndId(routerParams, 'prefix','uid')
        if (!!!prefixUidObj) {
            console.log('loadFilesAndSetState, prefixObj is null for location.. no incoming Uid.. ')
        }

        argUid = !!prefixUidObj ? prefixUidObj.combi : null

        
        // running promisses in prallel, according to this:
        // https://stackoverflow.com/questions/35612428/call-async-await-functions-in-parallel
        try {


          // load image and data if we have an Uid... otherwise, just load the tagModel
          if (!!argUid && argUid.length > 1) {
            
            //localStorage.setItem({ uid: pathName.slice(1) })
            console.log('## (loadFilesAndSetState) with uid from URL:', argUid)

            // initiate promise
            argImageUrl = getImageCached('orig/', argUid)
            //console.log('received argImageUrl:', argImageUrl)


            // initiate promise
            argData = getDataCached('data/', argUid)
            //console.log('received data:', argData)


            // from here on start awaiting promisses, so they execute in parallel, with no blocking code in between
            argImageUrl = await argImageUrl
            argData = await argData
   
          }

          // await promise
          const argTagModel = await argTagModelPromise



          // save loaded (from local tagModel.json) JSON to S3  (if anything happens to the AwS S3 tag model.. this is the local backup)

          // got a PATH issue with FIREFOX and CHROME. looks like they store the model in different places...
          // or caching creates this problem...
          //
          // !!!! uncomment the followin line to restore the tagModel on s3:
          // await saveTagModel({ tagModel: tagModel.default }) 
          
          

          // model, argData, argImageUrl ... all getting set in state at the same time
          console.log('setting tagModel, argImage, argData, argUid to STATE.. argData', argData, ' tagModel:', argTagModel)
          await this.setStateAsync({ tagModel: argTagModel, argImageUrl, argData, argUid, fetchingImageAndData: false })

          //this.forceUpdate()
            
        }
        catch(err) {
         console.log('tagModel storage.get err:', err)
        }
      }

  }








  render() {

    return (
                <Tag  tagModel={this.state.tagModel} 
                      argUid={this.state.argUid} 
                      argImageUrl={this.state.argImageUrl} 
                      argData={this.state.argData}
                       />   
                    
              
    );
  }
}


export default withAuthenticator(Tagging, false ) // {includeGreetings: true});


/*</div>

render() {



  return (
    <BrowserRouter> 
      <div >
        <CssBaseline />

        <MuiThemeProvider theme={theme}>

          <Switch>
       


            <Route path="/" exact component={SplashScreen} />

            <Route path="/login" component={SignIn} />

            <Route path="/logout" component={SignOut} />


            <Route path="/app/:tabId" component={ requireAuth(MainUser) } />
            
            <Route path="/app" component={ requireAuth(MainUser) } />           


            <Route path="/admin" component={ requireAuth(MainAdmin) } />

            <Route path="/media" component={ requireAuth(MainAdmin) } />
            
            <Route path="/finished/:finishedRank" component={ requireAuth(FinishedUser) } />

            <Route path="/finished" exact component={ requireAuth(FinishedUser) } />


            <Route component={SplashScreen} />

          </Switch>

        </MuiThemeProvider> 

      </div>
    </BrowserRouter>
  );
}
}

export default connect(null, {})(App);
// export default connect(null, { fetchUser })(App);



*/




    // could set a document.fullscreenEnabled variable for all others to read..
    // use this, to trigger fullscreen in app: https://github.com/sindresorhus/screenfull.js
/*    
    const fullscreenEnabled =
    document.fullscreenEnabled ||
    document.mozFullScreenEnabled ||
    document.documentElement.webkitRequestFullScreen;    
*/
    
    //console.log('fullScreenElement:', fullscreenElement)

/*
    // for special case where the QR code id (uid) comes in via URL, but gets lost during authentication
    // (when user has not logged in yet) - the callback URL can t transport the QR code id)
    // so we store the QR code id before auth in localStorage, and retrieve it after auth..
    //
    // if user is logged in already, the QR code id can be read from the URL (as no callback is called)
    //
    // URL callback works fine on localhost, not requiring this workaround... lets hope this also works on server


    const uid = localStorage.getItem("uid")

    if (!!uid) {
      console.log('## got uid from localStorage.. came in via URL before auth..:', uid)

      localStorage.removeItem("uid")
      console.log('removed uid from localStorage (url)')
    }

    const pathName = window.location.pathname
    if (pathName.length > 1) {
      
      //localStorage.setItem({ uid: pathName.slice(1) })
      console.log('## also got uid from URL:', pathName.slice(1))
    }
    
*/


    // USING this.state.imageUrl IS PROBABLY NOT A GREAT IDEA.
    // ITS ONLY USED BY ITS CHILD
    // ALSO, NEED TO KEEP TRACK OF THAT IMAGE'S DATA ==> UID
    // AND MAKE SURE I DON T GENERATE A NEW UID FOR IT ON SAVE / UPLOAD
    // OR SHOULD I ? TO TRACK CHANGES...?


    // REMOVE notistack package.. or start using it.. !!!!!!
    //          <div className="App" style={{ marginTop: fullscreenEnabled ? 8 : 70 }}>

//           <div className="App" style={{ marginTop: topMargin }}>








//export default App;



/*

import { Auth } from 'aws-amplify';


// get current user

Auth.currentAuthenticatedUser({
    bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
}).then(user => console.log(user))
.catch(err => console.log(err));



*/