import { Storage, Cache, Auth } from 'aws-amplify'
import {removeItemFromArray, pureSplice, mergeObjectArraysSpecial} from '../helpers/basics' 
//import statusService from '../components/statusService';

// storage related functions to save/load data from S3



// options: e.g. {level: 'protected'}
export async function deleteFile( fileName=null, options={} ) {
  console.log('deleteFile with file, options:', fileName, options)
/*
  Storage.remove('test.txt', {level: 'protected'})
  .then(result => console.log(result))
  .catch(err => console.log(err));
*/
  try {

    if (fileName !== null) {

      const result = await Storage.remove(fileName, options)
      console.log('deleteFile delete result:', result)
      return result

      
    } else {
      console.log('deleteFile is null.. not deleting..')
      return null
    }

  }
  catch(err) {
    console.log('deleteFile err:', err)
    alert('Error deleting file:', err)

    return err
  } 
}


// uid includes folder prefix already

// deletes the original image
// deletes the generated image
// deletes the data file for this image
export async function deleteImage({ uid=null, generatedFileKey=null }) {

  console.log('deleteImage with uid:'+ uid +' generatedFileKey:'+ generatedFileKey)

  const dataFileName = `data/${uid}.json` // e.g. data/ik_F782QRenx.json
  const originalFileName = `orig/${uid}` //`${folderPrefix}${uid}` 
  const generatedFileName = `generated/${uid}` //data/ik_F782QRenx.json


  if (uid !== null) {// && generatedFileKey !== null) {

    await deleteFile(generatedFileName, { level: 'protected' })
    await deleteFile(originalFileName, { level: 'protected' })
    await deleteFile(dataFileName, { level: 'protected' })

  } else {
    console.log('deleteImage uid is null.. not deleting..')
  }

 
}


  // these api functions are used for the newer version based on SAGAs

  export const DEFAULT_MODEL_FILE_NAME = 'tagModel.json'

  export async function apiPutModel({ tagModel=null, modelFileName=DEFAULT_MODEL_FILE_NAME }) {
    return await Storage.put(modelFileName, JSON.stringify(tagModel), {
      contentType: 'application/json',    // works
      //contentType: 'text/plain',        // works
      level: 'protected',
      cacheControl: '', // (String) Specifies caching behavior along the request/reply chain
      //contentDisposition: '', // (String) Specifies presentational information for the object
      //expires: new Date().now() + 60 * 60 * 24 * 7, // (Date) The date and time at which the object is no longer cacheable. ISO-8601 string, or a UNIX timestamp in seconds
      //metadata: { key: 'value' }, // (map<String>) A map of metadata to store with the object in S3.
    })    
  }

  export async function apiFetchModel({ modelFileName=DEFAULT_MODEL_FILE_NAME }) {
    console.log('apiFetchModel: ', modelFileName)
    return Storage.get(modelFileName, { level: 'protected', download: true, expires: 604800 })      
    //console.log('storage.get result:', result)
  }



  // duplicate exists in saveImgDialog.. rework this later
  export async function saveTagModel({tagModel=null, modelFileName='tagModel.json', cacheKey=null}) {

    console.log('saveTagModel called..', tagModel)
    try {

      if (tagModel !== null) {

        const result = await Storage.put(modelFileName, JSON.stringify(tagModel), {
            contentType: 'application/json',    // works
            //contentType: 'text/plain',        // works
            level: 'protected',
            cacheControl: '', // (String) Specifies caching behavior along the request/reply chain
            //contentDisposition: '', // (String) Specifies presentational information for the object
            //expires: new Date().now() + 60 * 60 * 24 * 7, // (Date) The date and time at which the object is no longer cacheable. ISO-8601 string, or a UNIX timestamp in seconds
            //metadata: { key: 'value' }, // (map<String>) A map of metadata to store with the object in S3.
        })

        console.log('tagModel saveTagModel write result:', result)
        
        if (!!cacheKey) {
          console.log('also rewriting tagModel cache..')
          //const resultJson = await JSON.parse(result.Body.toString())
          await Cache.setItem(cacheKey, tagModel)      
        }

        return result      

      } else {
        console.log('tagModel is null.. not saving..')

        return null
      }

    }
    catch(err) {
      console.log('tagModel saveTagModel err:', err)
      alert('Error in saveTagModel', err)

      return null
    }     

  }


  // check if choices have empty arrays (artifacts from a previous bug saved to file)
  // if such [] are found, they get removed
  export function checkTagModel(tagModel=[]) {
    //console.log('checkTagModel... tagModel:', tagModel)

    let neededFix = false
    const okModel = tagModel.map(item => {
      //console.log('checking item:', item)
      if (item.type === 'group') {
        const okChoices = item.choices.filter( choice => !!choice.key  )    // filter out the empty array items (anything that does not have a .key field)

        if (item.choices.length > okChoices.length) {
          console.log('checkTagModel found nr of [] choices for item:', item.id, ':', item.choices.length - okChoices.length)
          neededFix = true
        }
        return { ...item, choices: okChoices }
      } 
      return item

    })
    return { checkedModel: okModel, neededFix }
  }


  /*
  // get the model from S3 (and cache it afterwards) or from cache,
  // caching the tagModel, so i don t have to hit S3 for it so often
  // probably should do this with localhost..
  export async function getTagModelCached({ modelName='tagModel.json', cacheKey=null, forceFetch=false }) {

    if (cacheKey===null) {
      console.log('getTagModelCached has no key, returning early..')
      return null
    }
      

    try {

      if (!forceFetch) {
        const cachedTagModel = Cache.getItem(cacheKey)
        console.log(cachedTagModel)

        if (cachedTagModel) {
          console.log('getTagModelCached Cache hit: ', cachedTagModel)
          return cachedTagModel
        } 
      }
        
      console.log('getTagModelCached Cache miss: ', cacheKey)
      const result = await Storage.get(modelName, { level: 'protected', download: true, expires: 604800 })      
      //console.log('storage.get result:', result)

      const resultJson = await JSON.parse(result.Body.toString())

      const { checkedModel, neededFix } = checkTagModel(resultJson)
      if (neededFix) {
        alert('load: tagModel needed fixing for []')
      }
      console.log('getTagModelCached storage.get json result:', checkedModel)

      // writing read result into cache
      Cache.setItem(cacheKey, checkedModel)

      //return checkedModel
      return Promise.resolve(checkedModel);
      
    }
    catch(err) {
      console.log('tagModel getTagModelCached err:', err)
      alert('Error in getTagModelCached', err)

      //return null 
      return Promise.reject(null);

    }    
  
  }

*/  


export async function getTagModelCached({ modelName='tagModel.json', cacheKey=null, forceFetch=true }) {

    return new Promise(async (resolve, reject) => {

      if (cacheKey===null) {
        console.log('getTagModelCached has no key, returning early..')
        return null
      }
        

      try {

        if (!forceFetch) {
          const cachedTagModel = Cache.getItem(cacheKey)
          //console.log(cachedTagModel)

          if (cachedTagModel) {
            console.log('getTagModelCached Cache hit: ', cachedTagModel)
            return cachedTagModel
          } 
        }
          
        console.log('getTagModelCached Cache miss: ', cacheKey)
        const result = await Storage.get(modelName, { level: 'protected', download: true, expires: 604800 })      
        console.log('getTagModelCached storage.get result:', result)

        const resultJson = await JSON.parse(result.Body.toString())

        const { checkedModel, neededFix } = checkTagModel(resultJson)
        if (neededFix) {
          alert('load: tagModel needed fixing for []')
        }
        console.log('getTagModelCached storage.get json result:', checkedModel)

        // writing read result into cache
        Cache.setItem(cacheKey, checkedModel)

        //return checkedModel
        resolve(checkedModel)
        
      }
      catch(err) {
        console.log('tagModel getTagModelCached err:', err)
        alert('Error in getTagModelCached', err)

        reject(null)
      }   
      
    })
  
  }


  

// folderPrefix has to end in / or be an empty string
// key is the key of the file to get

// !!!! not sure if u can have a cacheKey like "image_ik/asdf"..


// returns an URL, no lastModifiedDate is returned...
export async function getImageCached(folderPrefix='', key) {

  return new Promise(async (resolve, reject) => {


    const cacheKey = 'image_' + key
    const fileName = `${folderPrefix}${key}` 


    /*
    const result = await statusService.show({
      message: "loading image.."
    });
    if (result) {
      console.log('getImageCached statusService promise resolved..')
    }
    */
    


    try {

      const cachedImage = false // = Cache.getItem(cacheKey)
      console.log(cachedImage)
        
      if (cachedImage) {
        console.log('getImageCached Cache hit: ', key)
        return cachedImage
      }
      
      console.log('getImageCached Cache miss key: ', key, ' fileName:', fileName)

      // was Date().getTime() + before
      //const expires = new Date().getTime() + 6048000
      //const expires = (new Date()).getTime() + 60480000
      //const expires = await new Date().now() + 60 * 60 * 24 * 6 
      //const expires = new Date().now() + 60 * 60 * 24 * 7 

      //const expires = (new Date()).getTime() + 5000 //+ 60 * 60 * 24 * 6 //+ 604800000
      // or like this:     // expires: new Date().now() + 60 * 60 * 24 * 7, // (Date) The date and time at which the object is no longer cacheable. ISO-8601 string, or a UNIX timestamp in seconds

  // level: 'protected'
      //const url = await Storage.get(fileName, { level: 'protected', expires, download: false }) // if download:true, it returns the contents

      const url = await Storage.get(fileName, { level: 'protected', download: false }) // if download:true, it returns the contents
      //console.log('getImageCached Storage.get url:', url)

      Cache.setItem(cacheKey, url)
      //Cache.setItem(key, url, { expires })    

      resolve(url)
      //return url

    }
    catch(err) {
      console.log('getImageCached err:', err)
      alert('Error in getImageCached', err)
      
      reject(null)
      //return null
    } 
  })
}


export async function getDataCached(folderPrefix='', key) {

  return new Promise(async (resolve, reject) => {


    const cacheKey = 'data_' + key
    const fileName = `${folderPrefix}${key}.json` 


    /*
    const result = await statusService.show({
      message: "loading data.."
    });
    if (result) {
      console.log('getDataCached statusService promise resolved..')
    }
    */
    


    try {

      //const cachedData = Cache.getItem(cacheKey)
      const cachedData = false

        console.log('getDataCached cacheData',cachedData)
        
      if (cachedData) {
        console.log('getDataCached Data Cache hit: ', key)
        return cachedData
      }
      
      console.log('getDataCached Data Cache miss: ', key)

      const expires = new Date().getTime() + 604800000
      // or like this:     // expires: new Date().now() + 60 * 60 * 24 * 7, // (Date) The date and time at which the object is no longer cacheable. ISO-8601 string, or a UNIX timestamp in seconds


      const result = await Storage.get(fileName, { level: 'protected', download: true, expires })      
      console.log('getDataCached storage.get result:', result)

      const resultJson = await JSON.parse(result.Body.toString())  
      console.log('getDataCached storage.get JSON result:', resultJson)
      
      Cache.setItem(cacheKey, resultJson)
      //Cache.setItem(key, url, { expires })    

      //return resultJson
      resolve(resultJson)
    }
    catch(err) {
      console.log('getDataCached err:', err)
      alert('Error in getDataCached', err)

      //return null
      reject(null)
    }   
  })
  
}

  


  // get the data from S3 (and cache it afterwards) or from cache,
  // caching the tagData, so i don t have to hit S3 for it so often
  // probably should do this with localhost..

  // key = uid
  export async function getTagDataCached(key='', forceFetch=false) {


    /*
    const result = await statusService.show({
        message: "loading tag data.."
      });
      if (result) {
        console.log('getTagDataCached statusService promise resolved..')
      }
*/



    if (key===null) {
      console.log('getTagDataCached has no key, returning early..')
      return null
    }

    
    const fileName = 'data/'+key+'.json' //data/ik_F782QRenx.json
    console.log('getTagDataCached fileName:', fileName)

    try {

      if (!forceFetch) {
        const cachedTagData = Cache.getItem(key)
        console.log(cachedTagData)

        if (cachedTagData) {
          console.log('getTagDataCached Cache hit: ', cachedTagData)
          return cachedTagData
        } 
      }
        
      console.log('getTagDataCached Cache miss: ', key)
      // key should come from uid..
      // EDGE CASE: will not (yet) work with nested images, or time series of images 
      // until i figure out how to do versioned UIDs

      const expires = Date.now() + 60 * 60 * 24 * 6 //+ 604800000


      const result = await Storage.get(fileName, { level: 'protected', download: true, expires })      
      console.log('getTagDataCached result:', result)

      const resultJson = await JSON.parse(result.Body.toString())
      console.log('getTagDataCached json result:', resultJson)

      // writing read result into cache
      Cache.setItem(key, resultJson)

      return resultJson
      
    }
    catch(err) {
      console.log('tagData getTagDataCached err:', err)
      alert('Error in getTagDataCached', err)
      return null
    }    
  
  }





    // generate new tagModel from CREATED/DELETED tags in this.props.uiData (state of parent)
    // the UI keeps track of newly created entries, that are not part of the model in an CREATED array
    // the same holds true for deleted entries, which are listed in a DELETED array
    //
    // here we add to the model's CHOICES array new entries, or remove deleted ones..
    export async function generateNewTagModel(tagModel, uiData) {

        if (tagModel === undefined || uiData === undefined) {
          return
        }
        console.log('uiData:', uiData)
        console.log('uiData stringified:', JSON.stringify(uiData))


        const { checkedModel, neededFix } = checkTagModel(tagModel)
        if (neededFix) {
          alert('gernateNewModel, model needed fixing again for []')
        }
  
        // flag to indicate that new tagModel is different from original one => used for saving decision 
        let modelsAreDifferent = false
  
        //uiData is for data sets that have selected/created/deleted tags ..
  
  
        const newModel = checkedModel.map( item => {
  
            console.log('tagModel item:', item)
  
            // no user interaction within the UI on this tagGroup / tagBlock, so it does not show up
            if (uiData[item.id] === undefined) {
              return item
            }

            // now got new edge case: tagModel gets extended by merge with data loaded from data file 
    
            if (item.type === 'group') {
  
              let newChoices = item.choices

              // see if uiData choices are different from tagModel choices
              if (!!uiData[item.id] && !!uiData[item.id].values && uiData[item.id].length !== item.choices.length) {  // !!! what if tags were deleted and added =same length, but different values? no prob, will be handled in with .created tags
                console.log('uiData / tagModel choices are different for:', item.id, ':', uiData[item.id].length - item.choices.length)
              }
  
              if (!!uiData[item.id] && !!uiData[item.id].created && uiData[item.id].created.length > 0 && !!uiData[item.id].created[0] && uiData[item.id].created[0].length > 0 && uiData[item.id].created[0] !== []) {  
                // got at least one newly created TAG in here
                //console.log('uiData[item.id].created:', uiData[item.id].created)
  
                // add all created items to the new choices, behind the "keyboardIcon" on position 0, and before the rest
                // problem is here.. assuming keyboard is always on position 0, which it is not..
                
                newChoices = [ item.choices[0], ...uiData[item.id].created, ...item.choices.slice(1) ]

                if (!!uiData[item.id].created[0].key) {
                  
                } else {
                  alert('generateNewTagModel has problem with .created (not an object')
                }
                //newChoices = mergeObjectArraysSpecial(item.choices, uiData[item.id].created, 'key', false, 't') 



                
/*
                const exItemIdx = item.choices.findIndex( a => a === 't')
                if (exItemIdx > -1) {

                    const exItem = item.choices[exItemIdx]
                    //sorted.splice(exItemIdx,1)  // careful with splice, it ll manipulte array, not good for functional programming!!
                    
                    // remove exclude item, whereever it is
                    const choicesWithoutKeyboard = pureSplice(item.choices, exItemIdx, 1)  // careful with splice, it ll manipulte array, not good for functional programming!!
                  
                    newChoices = [exItem, ...uiData[item.id].created, ...choicesWithoutKeyboard]
                } 
*/


                modelsAreDifferent = true
              }
  
  
              if (!!uiData[item.id] && !!uiData[item.id].deleted && !!uiData[item.id].deleted[0]) {  
                // got at least one newly deleted TAG in here
                //console.log('uiData[item.id].deleted:', uiData[item.id].deleted)
  
                // remove all tags from item.choices, if they are found in uiData[item.id].deleted
                //newChoices = removeItemFromArray(item.choices, item.id, i =>  uiData[item.id].deleted.indexOf(i.key.toLowerCase() > -1)  )
  
                const iterator = uiData[item.id].deleted.values();
                for (const iter of iterator) {   
                  newChoices = [ ...removeItemFromArray(newChoices, item.id, i =>  iter.key === i.key) ] 
                  //console.log('iter.key:', iter.key)
                }
                modelsAreDifferent = true
                
              }
  
              // choices should be up to date,... compare created/deleted and see if they are.. NOT
  
              //console.log('uiData[item.id].choices:', newChoices)
              return { ...item, choices: newChoices }
    
            } else if (item.type === 'block') {
              //console.log('tagModel got block:', item, ' now calling recursively..')
    
              return item
    
            } else {
    
              return item
            }
        })
  
        console.log('newModel:', newModel)
  
  
        if (modelsAreDifferent) {
          const res = saveTagModel({ tagModel: newModel, cacheKey:'tagModel' }) //, fileName='tagModel.json', cacheKey=null}) {
          return { status: 'Updated tagModel saved!', res }
  
        } else {
          return { status: 'tagModel unchanged..' }
  
        }
  
        
    } // generateNewTagModel
  





    export async function saveTagModelAndData({     tagModel, 
                                                    uiData, 
                                                    uid, 
                                                    dataPrefix='data/', 
                                                    authUser, 
                                                    timeStamp })  {

        // SAVE TAG MODEL TO S3
        // checks if the tagModel was extended / reduced, if so, generates new tagModel and saves it..
        const modelRes = await generateNewTagModel(tagModel, uiData)
        console.log('tagModel status:', modelRes.status)


        //const dataPrefix = 'data/'  // folder for generated files (with writing on them)
        const dataFileName = dataPrefix + uid + '.json'
  

        //const authUser = this.getAuthUser()

        // SAVE TAG DATA TO S3
        const dataRes = await saveTagData({       uiData, 
                                                  uid, 
                                                  authUser,
                                                  imageTs: timeStamp,
                                                  fileName: dataFileName 
                                                } ) //, cacheKey=null})
        console.log('tagData status:', dataRes.status)

    }




    // get currently authenticated user
    // return null if error 
    export async function  getAuthUser() {
        try {
            const user = await Auth.currentAuthenticatedUser({
                bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
            })
            return user
        }
        catch (err) {
            console.log('!!! err, getting auth user.. ', err) 
            return null
        }
        
      }
  
  
      // testing this concept right now...
    export async function saveTagData({ uiData=null, 
                                        uid=null, 
                                        fileName='tagData.json', 
                                        imageTs=Date.now(), 
                                        authUser=null, 
                                        cacheKey=null   }) {
  
  
        console.log('saveTagData called..', uiData)
  
        if (uiData === null || uiData === undefined || uid === null) {
          console.log('!!! uiData is null or undefiend, or uid is null, not saving data, returning early..')
          return
        }
  
        try {
  
      
  
          let saveArr = []
  
          if (authUser === null) {
            console.log('Auth user could not be found for saving data..')
            return
          }
  
  
  
          //const a = uiData.keys().map( id => {
          const iterator = Object.keys(uiData)
  
          // iterate thru data hash-map
          for (const id of iterator) {           
  
            //const parent = uiData[id].parent
            const parent = uiData[id].parent  === 'main' ? uid : uiData[id].parent  // THIS SHOULD BE THE PHOTO QR ID
            // maybe also WRITE THE CONTEXT? e.g. IKI-   to seperate platform client records..
            // currrently the context is part of the uid, e.g. ik_AdedsEESS (context "ik"), underline is not part of the uid alphabet
            const label = uiData[id].dockedText
            const ts = uiData[id].ts || Date.now() // does not exist yet (timestamp of last data change in UI for this field)
  
            if (uiData[id].length === 0) {
              // special case, empty array (user removed value which needs to overwrite possible previously saved value )
            
              //return { parent, id, data:[], ts, label }
              saveArr.push( { parent, id, key:'', text:'', ts, label } )
  
            } else {
  
              // iterate thru selected tags (keys)
              uiData[id].selected.forEach( (key, idx) => {
  
                // see if there s a value field for the selected tags uiData['breeder'].selected = [..]   uiData['breeder'].values = [..]
                if (!!uiData[id].values[idx]) {
  
                  // up to now, the model and everyting is using KEY / TEXT pairs... not KEY / VALUE (same thing, different name)
                  saveArr.push({ parent, id, key:key, text:uiData[id].values[idx], ts, label })
  
                } else {
  
                  console.log('!!! ERR, CANT SAVE DATA, uiData data key / value arrays not matching, id:', id, ' key:',key)
                  return null
                }            
              }) // forEach
  
            } // else
          } // for
  
          console.log('saveTagData saveArr:', saveArr)
          // QUESTION. how to handle nested photos / data taken at different points of time..? have a encapsulating PARENT?
          // 
    
          if (saveArr !== [] && uiData !== null) {
  
            //const saveObj = { id: uid, imageTs, ts:Date.now(), user:authUser, data: saveArr}
    
            const result = await Storage.put(fileName, JSON.stringify(saveArr), {
                contentType: 'application/json',    // works
                //contentType: 'text/plain',        // works
                level: 'protected',
                cacheControl: '', // (String) Specifies caching behavior along the request/reply chain
                //contentDisposition: '', // (String) Specifies presentational information for the object
                //expires: new Date().now() + 60 * 60 * 24 * 7, // (Date) The date and time at which the object is no longer cacheable. ISO-8601 string, or a UNIX timestamp in seconds
                //metadata: { key: 'value' }, // (map<String>) A map of metadata to store with the object in S3.
            })
    
            console.log('tagData saveTagData write result:', result)
            
            if (!!cacheKey) {
              console.log('also rewriting saveTagData cache..')
              //const resultJson = await JSON.parse(result.Body.toString())
              Cache.setItem(cacheKey, saveArr)
  
              return { status: 'tagData saved & cached!', res: result }
            }
  
            return { status: 'tagData saved!', res: result }
  
  
          } else {
            console.log('saveTagData is null.. not saving..')
  
            return { status: 'tagData saved!' }
  
          }
    
        }
        catch(err) {
          console.log('!!! tagData saveTagData err:', err)
          alert('Error in saveTagData:', err)
  
          return { status: 'tagData save error:', err }
  
        }     
    
      }  
  


    


    // !!! this will not put image into user's PROTECTED folder.. !!
    // used only with upload to API GATEWAY, not with standard storage.put
    // problem with CORS on preflight request...
    export async function uploadPayload({ payload, contentType='image/jpeg', url }) {
        const response = await fetch(url, {
          method: 'PUT',
          body: payload,
          mode: 'cors', // no-cors, cors, *same-origin        
          headers: {
            'Content-Type': contentType,
            //'Origin': 'http://localhost:3000/'
          }
        });
        //const res = response //await response.json(); //extract JSON from the http response
        // do something with myJson
        console.log('upload res:', response)
        return response
    }        






/*
    // USING THIS TO CONVERT CURRENT TAG MODEL TO NEW ONE (WITH CB)
    // recusive rendering function for TagGroup / TagBlock 
    // tagModel initially comes from props (loaded from tagModel.json by parent)
    traverseTagModel(tagModel, parent=null) {

      if (tagModel === undefined) {
        console.log('tagModel undefined, returning ')
        return
      }
      console.log('traverseTagModel - tagModel to be processed for render:', tagModel)

      let res = []


      const iterator = tagModel.values();

      for (const item of iterator) {

        console.log('traverseTagModel - tagModel item:', item)

        const { dbKey=null, label=null, children=null, ...rest } = item


        if (item.type === 'group') {

          res.push( { id: dbKey, parent, ...rest } )
          

        } else if (item.type === 'block') {
          //console.log('tagModel got block:', item, ' now calling recursively..')

          //return [ { id: label, parent, ...rest }, ...this.traverseTagModel(children, label) ]   // blocks currently don t have a dbKey

          res.push( { id: label, parent, ...rest, label } )
          res.push( ...this.traverseTagModel(children, label) )

        } else {

          console.log('!!! traverseTagModel - item is null, returning null..:', item)
          //return null
        }
      }

      return res

    }


  */
