    // compareFunc is a the search function for the element that should be removed
    // id is the element to be removed - only for console log 
    // arr is the array from which the element should be removed
    export function removeItemFromArray(arr=[], id, compareFunc) {

        //find the index of object from array that you want to update
        const idx = arr.findIndex(compareFunc) 

        if (arr.lengt===0) {
            return []
        }
        if (idx === -1) {
            //console.log('removeItemFromArray: item '+id+' not found in array, returning original array:', arr)
            return arr
        } 

        const front = idx > 0 ? arr.slice(0,idx) : []
        return [ ...front, ...arr.slice(idx+1) ]

    }

    // merges two simple arrays
    export function mergeSimpleArrays(a,b) {
        // not very performant, but called not often, and only with small arrays  
        return [...new Set([...a, ...b])]
    }




    // same as mergeObjectArrays, but leaves an item with excludeFromSortKeyVal at the original position (e.g. the 't' field to control input behaviour)
    export function mergeObjectArraysSpecial(origArr=[], updatingArr=[], objKey, sort=true, excludeFromSortKeyVal='t') {


        if (!!excludeFromSortKeyVal && origArr.length > 1) {

            // sort array (if necessary)
            const sorted = [ ...mergeObjectArrays(origArr, updatingArr, objKey, sort) ]

            if (sorted.length > 0) {

                // splice manipulates the array, so we are working with a copy and not a reference

                // find exclude item in array
                const exItemIdx = sorted.findIndex( a => a[objKey] === excludeFromSortKeyVal)
                if (exItemIdx > -1) {

                    const exItem = sorted[exItemIdx]
                    if (exItem[objKey] !== excludeFromSortKeyVal) {
                        alert('Array merge problem with '+excludeFromSortKeyVal+'..'+ exItem[objKey])
                    }                    
                    //sorted.splice(exItemIdx,1)  // careful with splice, it ll manipulte array, not good for functional programming!!
                    
                    // remove exclude item, whereever it is
                    const sorted2 = pureSplice(sorted, exItemIdx, 1)  // careful with splice, it ll manipulte array, not good for functional programming!!
                  


                    return [exItem, ...sorted2]
                } 
            }

            // 
            return origArr

            
        } 
        
        return mergeObjectArrays(origArr, updatingArr, objKey, sort)          
    }



    // used in buildTagUIFalt to merge modelChoices and argChoices, basedon a certain key field (both are object arrays)
    export function mergeObjectArrays(mainArr=[], updatingArr=[], objKey, sort=true) {

        if (!!mainArr && mainArr.length > 0 && !!updatingArr && updatingArr.length > 0) {
            let origArr = mainArr

            updatingArr.forEach(upItem => {
                if ( !origArr.find(orgItem => upItem[objKey] === orgItem[objKey] ) ) {
                    //origArr.push(upItem)
                    //origArr = [upItem, ...origArr]
                    
                    origArr = origArr === [] ? [upItem] : [upItem, ...origArr] 
                    //origArr.unshift(upItem)
                }
            })
    
            if (sort) {
                return origArr.sort( (a,b,) => a[objKey] > b[objKey])
            }

            return origArr
        }

        return mainArr
        
      }



    // get full host URL, including protocol and port, not adding a trailing /
    export function getFullHostUrl() {
      return window.location.protocol + '//' + window.window.location.host

    }


    // wait for different amounts of time, resolove promise once time is reached.
    export function waitFor({ atLeast=500, maybeMore=400}) {

        const ms = Math.floor(Math.random()*(atLeast+maybeMore-atLeast+1)+atLeast)
        return new Promise(resolve => setTimeout(resolve, ms));
        
        //Math.floor(Math.random()*(max-min+1)+min)
      
    }    



    export function toDateTimeObj(UNIX_timestamp, dateSpacer='-', timeSpacer=':') {
            var a = new Date(UNIX_timestamp);
            //var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            var year = a.getFullYear();
            //var month = months[a.getMonth()];
            var date = a.getDate();
            var hour = a.getHours();
            var min = ( "0" + a.getMinutes() ).slice(-2) // force 00 format even for single digits
            var sec = ( "0" + a.getSeconds() ).slice(-2) // force 00 format even for single digits
            //var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;

            return {    dateOnly: year + dateSpacer + (a.getMonth()+1) + dateSpacer + date, 
                        timeOnly: hour + timeSpacer + min + timeSpacer + sec,
                        dateTime: year + dateSpacer + a.getMonth()+1 + dateSpacer + date + ' ' + hour + timeSpacer + min + timeSpacer + sec,
            }
/*
            return {    dateOnly: a.toLocaleDateString(), 
                        timeOnly: a.toLocaleTimeString(),
                        dateTime: year + '-' + a.getMonth()+1 + '-' + date + ' ' + hour + ':' + min + ':' + sec,
            }
            */
    }


    // get URL payload everyting after host URL
    export function getUrlPayload() {
        const pathName = window.location.pathname
        if (pathName.length > 1) {
          
          return pathName.slice(1)     
        }  
        
        return null
    }


    // get payload from react router location object, or null if not specified
    export function getRouterPayload(location=null, key=null) {

        if (!!location && !!location.params && !!location.params[key]) {
            return location.params[key]
        } else {
            return null
        }
    }

    // gets user 'context' prefix (e.g. ik) and unique id 
    export function getRouterPrefixAndId(location, prefixKey, uidKey) {

        const prefix = getRouterPayload(location, prefixKey)
        const uid = getRouterPayload(location, uidKey)

        // edge-case where there is no prefix (some older images are named like: prefix_uid, rather than prefix/uid )
        if (!!uid && !!!prefix) {
            return { uid, combi: uid } 
        }
        return (prefix !== null && uid !== null) ? { prefix, uid, combi: prefix + '/' + uid } : null
    
    }

    
    


    // calculates the delta (difference) between two mouse / touch positions
    export function getPositionDeltas(a,b, useAbsolute=true) {

        if (useAbsolute) {
            console.log('a.x / b.x:', a.x, b.x)
            return { dx:Math.abs(a.x - b.x), dy:Math.abs(a.y - b.y) }
        }
    
        return { dx:a.x - b.x, dy:a.y - b.y }
    }
    

    // compares two flat arrays, sorts them before that
    // not very performant, but good enough for now..
    export function arraysAreEqual(arr1=[],arr2=[]) {

        const s1 = !!arr1 && !!arr2 && arr1.length !== arr2.length && arr1.length > 0 ? arr1.sort() : []
        const s2 = !!arr1 && !!arr2 && arr1.length !== arr2.length && arr2.length > 0 ? arr2.sort() : []

        if (s1.length === 0 && s2.length === 0) {
            return true
        }

        // do comparison 
        if (!!s1 && !!s2 && s1.length === s2.length) {
            return s1.every( (item, idx) => item === s2[idx] )
        }

        return false
    
           
    }



    /**
 * A pure version of Array.prototype.splice
 * It will return a new array rather than mutate the array
 * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice
 * @param {Array} array The target array
 * @param {number} start Index at which to start changing the array
 * @param {number} deleteCount An integer indicating the number of old array elements to remove
 * @param {any} items The elements to add to the array, beginning at the start index
 * @returns {Array}
 */
export function pureSplice(array, start = 0, deleteCount = 0, ...items) {
    const arrayLength = array.length
    const _deleteCount = (deleteCount < 0) ? 0 : deleteCount
    let _start
    if (start < 0) {
      if (Math.abs(start) > arrayLength) {
        _start = 0
      } else {
        _start = arrayLength + start
      }
    } else if (start > arrayLength) {
      _start = arrayLength
    } else {
      _start = start
    }
    return [
      ...array.slice(0, _start),
      ...items,
      ...array.slice((_start + _deleteCount), arrayLength),
    ]
  }