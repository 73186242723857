import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/*
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from "./redux/reducers";
import { rootSaga } from './redux/sagas'


const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = composeWithDevTools({
  // Specify custom devTools options
});

// no need for preloadedState at this moment
//const store = createStore(reducers,  preloadedState,  composeEnhancers(
  //applyMiddleware(...middleware),
  //applyMiddleware(reduxThunk),
  applyMiddleware(sagaMiddleware)
  // other store enhancers if any
));

sagaMiddleware.run(rootSaga)

//const store = createStore(reducers, {}, applyMiddleware(reduxThunk));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
//registerServiceWorker();
*/


////////////////////////////////////////////////////////////////////////////////////////


/*
var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement ||
document.webkitFullscreenElement || document.msFullscreenElement;

document.addEventListener("fullscreenChange", function () {
    if (fullscreenElement != null) {
        console.info("Went full screen");
    } else {
        console.info("Exited full screen");              
    }
});
*/




// index.css has overflow: hidden ===> no scrolling on main page..

//this.fullScreenMode = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen; // This will return true or false depending on if it's full screen or not.


// store uid, if theres a pathname present.
// yields: "https://m.koitag.com/ikrXDdskees" => /ikrXDdskees

    // for special case where the QR code id (uid) comes in via URL, but gets lost during authentication
    // (when user has not logged in yet) - the callback URL can t transport the QR code id)
    // so we store the QR code id before auth in localStorage, and retrieve it after auth..
    //
    // if user is logged in already, the QR code id can be read from the URL (as no callback is called)
    //
    // URL callback works fine on localhost, not requiring this workaround... lets hope this also works on server
/*
const pathName = window.location.pathname
if (pathName.length > 1) {
	
	localStorage.setItem( "uid", pathName.slice(1) )

}
*/
