import React from 'react';
import {removeItemFromArray, waitFor } from '../helpers/basics' 
import { saveTagModel, saveTagModelAndData, getAuthUser } from '../helpers/storageIO'


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Fab from '@material-ui/core/Fab';


import { mergeCanvas, saveCanvas, saveCanvasWithExif, createUploadImagWithExif, b64toBlob } from '../helpers/processImage'


import SaveAltIcon from '@material-ui/icons/SaveAlt'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import PhotoFilterIcon from '@material-ui/icons/PhotoFilter'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CancelIcon from '@material-ui/icons/Cancel'

import { Storage } from 'aws-amplify';

// FOR TESTING .. THIS DATA / IMAGE EXISTS data/ik_F782QRenx.json


const IMG_HEIGHT = 150
const IMG_WIDTH = 150

const dialogTitle = 'Card'
const dialogText = ''//'Save or upload image'
const saveButton = <SaveAltIcon /> //'Save'
const uploadButton = <CloudUploadIcon /> //'Upload'
const cancelButton = <CancelIcon /> //'Cancel'



// props: dialogTitle, dialogText
//
// dialog for upload status (accept, skip, reject, new)
// can maybe also used for start/stop game..


function Transition(props) {
  return <Slide direction="up" {...props} />;
}


/*
props:

isOpen
handleClose
pic (optional)
*/

class SaveImgDialog extends React.Component {

    constructor(props) {
        super(props)
        this.dialogTextCanRef = React.createRef()
        this.dialogMergeCanRef = React.createRef()
    }

    state = {
    //open: false,
      isUploading: false,
      progress: 0
    }
  
    async componentDidUpdate(prevProps, prevState) {
        
        if (!!!prevProps.isOpen && !!this.props.isOpen) {
            console.log('merging canvases in dialog..')
            await mergeCanvas({
                photoCanRef: this.props.photoCanRef,
                textCanRef: this.dialogTextCanRef,
                mergeCanRef: this.dialogMergeCanRef,
                textBlock: this.props.textBlock,
                canVals: this.props.canVals
            })
        }

        waitFor({ atLeast:300 })

    }

    


    /*

FORMAT OF TAG-DATA:

HASH-MAP TURNED INTO FLAT JSON?
    SIMILAR TO TAG-MODEL, BUT WITH VALUES?
    ALSO NEED TIME-STAMP
    PARENT IS.. 
    VERSION IS..



    */



    // generate new tagModel from CREATED/DELETED tags in this.props.uiData (state of parent)
    // the UI keeps track of newly created entries, that are not part of the model in an CREATED array
    // the same holds true for deleted entries, which are listed in a DELETED array
    //
    // here we add to the model's CHOICES array new entries, or remove deleted ones..
    async generateNewTagModel(tagModel, uiData) {

      if (tagModel === undefined || uiData === undefined) {
        return
      }
      console.log('uiData:', uiData)
      console.log('uiData stringified:', JSON.stringify(uiData))

      // flag to indicate that new tagModel is different from original one => used for saving decision 
      let modelsAreDifferent = false

      //uiData is for data sets that have selected/created/deleted tags ..


      const newModel = tagModel.map( item => {

          console.log('tagModel item:', item)

          // no user interaction within the UI on this tagGroup / tagBlock, so it does not show up
          if (uiData[item.id] === undefined) {
            return item
          }
  
          if (item.type === 'group') {

            let newChoices = item.choices

            if (!!uiData[item.id] && !!uiData[item.id].created && !!uiData[item.id].created[0]) {  
              // got at least one newly created TAG in here
              //console.log('uiData[item.id].created:', uiData[item.id].created)

              // add all created items to the new choices, behind the "keyboardIcon" on position 0, and before the rest
              newChoices = [ item.choices[0], ...uiData[item.id].created, ...item.choices.slice(1) ]
              modelsAreDifferent = true
            }


            if (!!uiData[item.id] && !!uiData[item.id].deleted && !!uiData[item.id].deleted[0]) {  
              // got at least one newly deleted TAG in here
              //console.log('uiData[item.id].deleted:', uiData[item.id].deleted)

              // remove all tags from item.choices, if they are found in uiData[item.id].deleted
              //newChoices = removeItemFromArray(item.choices, item.id, i =>  uiData[item.id].deleted.indexOf(i.key.toLowerCase() > -1)  )

              const iterator = uiData[item.id].deleted.values();
              for (const iter of iterator) {   
                newChoices = [ ...removeItemFromArray(newChoices, item.id, i =>  iter.key === i.key) ] 
                //console.log('iter.key:', iter.key)
              }
              modelsAreDifferent = true
              
            }

            // choices should be up to date,... compare created/deleted and see if they are.. NOT

            //console.log('uiData[item.id].choices:', newChoices)
            return { ...item, choices: newChoices }
  
          } else if (item.type === 'block') {
            //console.log('tagModel got block:', item, ' now calling recursively..')
  
            return item
  
          } else {
  
            return item
          }
      })

      console.log('newModel:', newModel)


      if (modelsAreDifferent) {
        const res = saveTagModel({ tagModel: newModel, cacheKey:'tagModel' }) //, fileName='tagModel.json', cacheKey=null}) {
        return { status: 'Updated tagModel saved!', res }

      } else {
        return { status: 'tagModel unchanged..' }

      }

      
    } // generateNewTagModel

    

    onSaveClick = async () => {

      const { fileName, uid, uiData, oldExifData, timeStamp, mimeType, tagModel } = this.props


        // SAVE IMAGE WITH EXIF TO S3
        //await saveCanvas({ canRef: this.dialogMergeCanRef, fileName: this.props.fileName })
        await saveCanvasWithExif({ 
          canRef: this.dialogMergeCanRef, 
          fileName,
          timeStamp,

          oldExifData, // exif data from opening the initial file, with corrected rotation
          uiData // data that the user entered in the UI
        })


/*

        // SAVE TAG MODEL TO S3
        // checks if the tagModel was extended / reduced, if so, generates new tagModel and saves it..
        const modelRes = await this.generateNewTagModel(this.props.tagModel, this.props.uiData)
        console.log('tagModel status:', modelRes.status)



        const authUser = this.getAuthUser()
        const imageTs = this.props.timeStamp

        // SAVE TAG DATA TO S3
        const dataRes = await this.saveTagData({  uiData:this.props.uiData, 
                                                  uid: this.props.uid, 
                                                  authUser,
                                                  imageTs,
                                                  fileName:'data/'+this.props.uid+'.json' 
                                                } ) //, cacheKey=null})
        console.log('tagData status:', dataRes.status)
*/
                   
        // SAVE MODEL AND TAG DATA TO S3
        const authUser = await getAuthUser()

        await saveTagModelAndData({  
          tagModel, 
          uiData, 
          uid, 
          authUser, 
          timeStamp })  


        // CLOSE DIALOG WINDOW
        
        this.props.handleClose()
    }




  




    // FIGURE OUT A WAY TO GENERATE A TRANSACTION (FOR IMAGE UPLOAD, DATA UPLOAD, MODEL UPLOAD TO S3)
    onUploadClick = async () => {

      const { fileName, uid, uiData, oldExifData, timeStamp, mimeType, tagModel } = this.props


      const generatedPrefix = 'generated/'  // folder for generated files (with writing on them)
      const generatedFileName = generatedPrefix + uid

      try { 

        // returns a binary string (would be put in a buffer and then written to a file)
        const data = await createUploadImagWithExif({ 
          canRef: this.dialogMergeCanRef, 
          fileName: fileName,
          timeStamp: timeStamp,
  
          oldExifData: oldExifData, // exif data from opening the initial file, with corrected rotation
          uiData: uiData, // data that the user entered in the UI
  
        }) 
  

        // WORKS, THE NEW STANDARD WAY TO DO THIS...  
        // this actually also takes some time, maybe indicate it in the uploading progress...?
        // ALSO, this may be problematic with VIDEO !!!!!

        const blob = new Buffer(data.replace(/^data:image\/\w+;base64,/, ""),'base64')



        /*
        const res = this.uploadPayload({ 
          payload: blob, 
          contentType: this.props.mimeType, 
          url:"https://m.koitag.com/upload/koitag/"+fileName 
        })
        */

        this.setState({ isUploading: true })

        const that = this


        // UPLOAD TO S3

        //const res = await Storage.put(fileName, blob, {        
        const res = await Storage.put(generatedFileName, blob, {    // BETTER PUT IT IN A PROPER FOLDER
          //level: 'public',
          level: 'protected',
          //contentType: mimeType, //'image/jpeg', //mimeType,
          contentType: mimeType,
          progressCallback(progress) {  
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);

            that.setState({ progress: Math.floor(progress.loaded * 100 / progress.total) })

          }
        })
        

        console.log('uploaded ok, res:', res)

        this.setState({ isUploading: false, progress: 0 })


        // SAVE MODEL AND TAG DATA TO S3
        const authUser = await getAuthUser()

        await saveTagModelAndData({  
          tagModel, 
          uiData, 
          uid, 
          authUser, 
          timeStamp })         




        this.props.handleClose()

      } catch(err) {

        console.log(err)
        alert('onUploadClick Error:', err)
        this.setState({ isUploading: false, progress: 0 })

        alert(err); // TypeError: failed to fetch
      }      
     
    }


  render() {

    const { isOpen=false, handleClose=null } = this.props

    //const ImgRender = !!this.props.pic && this.props.pic.length > 0 ? <img src={this.props.pic} width={IMG_WIDTH} heigh={IMG_HEIGHT} alt='uploadImage' /> : ''
    const ImgRender = ''      // not really used anymore, image comes in via <canvas ...>

    /*  // removed
            <DialogContentText id="alert-dialog-slide-description">
              {dialogText}
            </DialogContentText>
    */

    return (
      <div>
        <Dialog
          open={isOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {dialogTitle}
          </DialogTitle>

          <DialogContent>

            {ImgRender}
            <canvas ref={ ref => this.dialogMergeCanRef = ref } style={{ maxWidth: 300, maxHeight: 300 }} />
            <canvas ref={ ref => this.dialogTextCanRef= ref } style={{  display: "none" }} />


          </DialogContent>


          <DialogActions>

            { !!this.state.isUploading && this.state.progress > 0 ? this.state.progress + '%' : '' }

            <Button variant="outlined" size="small" onClick={this.onSaveClick} color="default">
              {saveButton}
            </Button>

            <Button variant="outlined" size="small" onClick={this.onUploadClick} color="default">
              {uploadButton}
            </Button>         
            


          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default SaveImgDialog;


/*

            <Button variant="outlined" size="small" onClick={handleClose} color="default">
              {cancelButton}
            </Button>
*/


/*      
      let mimeType = ''

      if (fileName.toLowerCase().endsWith('.jpg') || fileName.toLowerCase().endsWith('.jpeg')) {
        mimeType = "image/jpeg" //["image/jpeg", 0.9]  // png is standard, no need for writing it out
      }    

      if (fileName.toLowerCase().endsWith('.mp4') || fileName.toLowerCase().endsWith('.mpeg4')) {
        mimeType = "video/mp4"
      }      
*/





/*

tried a number of ways to upload 



        //const blob = await b64toBlob(data, 'image/jpeg');   // string errors
        //const blob = window.atob(data)  
        
        // btoa() function creates a base-64 encoded ASCII string from a "string" of binary data.
        //const b64 = window.btoa(data)  

        //const blob = new Buffer(data, 'binary').toString('base64')    // not that
        //const base64data = data.toString('base64')

        //const buf = window.btoa(data).toString('base64');









      //const blob = new Buffer(data, 'binary') // no

       // THIS WORKS, BUT IS SLOW !!!!
       //const blob = data.replace(/^data:image\/\w+;base64,/,"");   
       // 4MB String takes 10 seconds
       //const blob = new Buffer.from(bufBase64, 'base64');  


        
        //const buf = new Buffer(data, 'binary')

        //var file = new File([data], fileName, {type: "image/jpeg"});

        //var bufBase64 = data.replace(/^data:image\/\w+;base64,/,"");
        //const blob = window.btoa(unescape(encodeURIComponent(bufBase64)));  // no
        //const blob = window.btoa(data)  // no



*/


/*
        // THIS WORKS, BUT IS SLOW !!!!
        const bufBase64 = data.replace(/^data:image\/\w+;base64,/,"");   
        // 4MB String takes 10 seconds
        const blob = new Buffer.from(bufBase64, 'base64');  
*/