import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import {removeItemFromArray, getEventXY, getPositionDeltas, arraysAreEqual, waitFor } from '../helpers/basics' 

import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/Clear';

import LockIcon from '@material-ui/icons/Lock';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';




import KeyboardIcon from '@material-ui/icons/Keyboard';


//import IntegrationDownshift from './autoSuggest'
import IntegrationReactSelect from './autoSuggest2'

import useLongPress from '../helpers/useLongPress';

import confirmService from './confirmService';




//import NumberFormat from 'react-number-format';
// '$0,0.00'
import numeral from 'numeral'

//import FaceIcon from '@material-ui/icons/Face';
//import DoneIcon from '@material-ui/icons/Done';
//import { stat } from 'fs';









const styles = theme => ({
  root: {
    textAlign: 'left',
    

  },
  chip: {
    marginTop: theme.spacing(1), //theme.spacing.unit,
    opacity: 0.8,
    //height: '1.9em', //'1.8em',
    fontSize: '1.1em', //'1.2em',
  },
  chipBig: {
    marginTop: theme.spacing(1), //theme.spacing.unit,
    opacity: 0.8,
    //height: '2em', //'1.8em',   // was commented out
    fontSize: '1.1em', //'1.2em',
    //paddingTop: theme.spacing(1),   // was not here
    //paddingBottom: theme.spacing(1)   // was not here

  },

});


const ChipSpan = {
  //position: 'static',
  display: 'flex',
  //justifyContent: 'center',
  //alignSelf: 'center',
  alignContent: 'center',
  //alignItems: 'center',
  //width: '100%',
  overflowX: 'auto',
  scrollbarWidth: 'none',  // Firefox    
  //flexWrap: 'wrap',
  //'&::-webkit-scrollbar': { // Webkit
  '&::WebkitScrollbar': {
    appearance: 'none',
    height: 0,
  }, 
  //marginLeft: 100,   
}

const ContainerSpan = {
  //display: 'absolute',


  alignItems: 'center',

  display: 'flex',
  flexWrap: 'nowrap'

}

// just used for texting
const LabelSpan = {
  //position: 'static',
  alignContent: 'left',
  textAlign: 'middle', 
  marginLeft: '0.5em',
  marginTop: '0.4em',  
}



const KeyboardIconSpan = {
  //position: 'static',
  alignContent: 'left',
  textAlign: 'middle', 
  //marginLeft: '0.5em',
  //marginTop: '0.4em',  
}




class TagGroup extends React.Component {


  constructor(props) {
    super(props)
    this.verticalRef = React.createRef()  // for vertical scrolling of autoSuggest (the menu gets covered by mobile keyboard otherwise)
    this.horizontalRef = React.createRef()
    this.buttonPressTimer = null

  }


    state = {
        selected: [],          // this is still single key (not yet an array or hash table)
        choices: [],

        created: [],            // for newly created elements (tags)  (supposed to be text/key objects)
        deleted: [],            // for deleted elements (tags), not really used yet

        values: [], // for testing..
        
        docked: true,
        autoSuggest: false,

        //horizontalRef: false,
        //deleteModeOn: false,  
        //longPressOn: false, // usef for long press detection 

        // not used much longer..
        tagToDelete: null,


        goodClickTS: 0,             // last TS of a good click that was handled within the component, to contrast with (outside) clickTS (which triggers this.state.docked: true)
        

        deleteDialogOpen: false,    // used for delete dialog
        deleteTag: false            // set by delete dialog once delete action is confirmed
    }



    // render a single tag, only used for selection purpose (not when docked)
    renderChip = ({ item, avatar='', classes, placeRef=false, backgroundColor=null, color=null }) => {

      const key = this.createKey(item.key)


      if (placeRef) {
        console.log('placing Ref for horizontal scroll.. showing actively selected TAG..')
        return (<span ref={ el => { this.horizontalRef = el }} key={ 'openSpan'+key }
                  onClick={ () => {
                      console.log('renderChip Chip clicked..')
                      this.handleClick(item.key) }
                  } 
                >

                  <Chip
                      key={ 'open'+key }
                      avatar={ !!avatar && avatar.length > 0 ? <Avatar>{avatar}</Avatar> : null }
                      label={ item.text }
                      clickable
                      className={classes.chipBig}
                      color={ color !== null ? color : this.state.selected.indexOf(item.key) > -1 ? "secondary" : "primary" }
                      style={ color !== null ? { backgroundColor: backgroundColor, color: color } : {} }

                      //onClick={ () => {
                      //    console.log('renderChip Chip clicked..')
                      //    this.handleClick(item.key) }
                      //}
                      deleteIcon={ !!this.props.arrangeModeOn && item.key !== 't' && avatar !== '+' && this.state.choices.length > 1 ? <CancelIcon /> : null }
                      onDelete={ !!this.props.arrangeModeOn && item.key !== 't' && avatar !== '+' && this.state.choices.length > 1 ? () => this.onTagDelete( item ) : null }
                      
                      //inputRef={el => this.inputElement = el}

                      //onDelete={handleDelete}
                      //onClick={ () => this.setState(state => { selected: !state.selected } ) }
                      //deleteIcon={<DoneIcon />}
                  /> 

                  { item.key !== 't' && !!item.image && <div
                    style={{ 
                      height: '120px',
                      border: '4px solid cyan',

                      maxWidth: '100%',
                      backgroundImage: `url(${item.image})`, //`url(/koi6.jpg)`,
                      //backgroundImage: `url(${this.state.photo.name})`,
                      //backgroundImage: `url(${this.state.photoUrl})`,
                      //backgroundImage: `${this.state.photo.name}`,

                      backgroundSize: 'auto 120px', //'contain', // 'cover'
                      //backgroundSize: 'cover', //'contain', // 'cover'

                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center', // Center the image 
                      //minHeight: window.innerHeight,
                    }}
                  />}                  

              </span>)
                
      } else {

        //             ref={ placeRef ? el => this.horizontalRef = el : null }

        return (<span key={ 'openSpan'+key } 
                      onClick={ () => {
                          console.log('renderChip Chip clicked..')
                          this.handleClick(item.key) }
                      } 
                >
                  { console.log('item.image:', item.image) }

                  <Chip
                    key={ 'open'+key }
                    avatar={ !!avatar && avatar.length > 0 ? <Avatar>{avatar}</Avatar> : null }
                    label={ item.text }
                    clickable
                    className={classes.chipBig}
                    color={ color !== null ? color : this.state.selected.indexOf(item.key) > -1 ? "secondary" : "primary" }
                    style={ color !== null || backgroundColor !== null ? { backgroundColor, color } : {} }      
                    //onClick={ () => {
                    //    console.log('renderChip Chip clicked..')
                    //    this.handleClick(item.key) }
                    //}
                    deleteIcon={ !!this.props.arrangeModeOn && item.key !== 't' && avatar !== '+' ? <CancelIcon /> : null }   // avatar is "+" with add group chips
                    onDelete={ !!this.props.arrangeModeOn && item.key !== 't' && avatar !== '+' ? () => this.onTagDelete( item ) : null }  // avatar is "+" with add group chips          

                    
                    //inputRef={el => this.inputElement = el}

                    //onDelete={handleDelete}
                    //onClick={ () => this.setState(state => { selected: !state.selected } ) }
                    //deleteIcon={<DoneIcon />}
                  />

                  { item.key !== 't' && !!item.image && <div
                    style={{ 
                      height: '120px',
                      maxWidth: '100%',
                      backgroundImage: `url(${item.image})`, //`url(/koi6.jpg)`,
                      //backgroundImage: `url(${this.state.photo.name})`,
                      //backgroundImage: `url(${this.state.photoUrl})`,
                      //backgroundImage: `${this.state.photo.name}`,

                      backgroundSize: 'auto 120px', //'contain', // 'cover'
                      //backgroundSize: 'cover', //'contain', // 'cover'

                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center', // Center the image 
                      //minHeight: window.innerHeight,

                      backgroundAttachment: 'scroll'                     
                    }}
                  >
                      
                  </div>}
                  
                </span>
              )

      }
    }


    componentDidMount() {
    
      // using this.state.choices because newly entered (by keyboard) values need to extend the this.PROPS.choices array
      // 

      // because choices get extended with new-additions... so i mostly use this.state.choices




      this.setState({ choices: this.props.choices })


      // if there are some pre-selected choices, set them accordingly
      if (!!this.props.selected && this.props.selected.length > 0) {
        this.setState({ selected: this.props.selected, docked: this.props.docked })
        //this.props.onTagSelect


        // adding pre-selected items to parent state ::: assuming that choices contains all selected elements (not the case if elements were deleted via UI, but still stored within incoming data)
        const values = this.props.selected.map(item => this.props.choices.filter(choice => choice.key === item)[0].text )
          
        // adding pre-selected items to parent state ::: assuming that choices contains all selected elements (not the case if elements were deleted via UI, but still stored within incoming data)
        /*
        const values = this.props.selected.map(item => {
          const found = this.props.choices.filter(choice => choice.key === item)
          if (!!found && found[0] && found[0].text) {
            return found[0].text 
          } else {
            return null
          }
        })
          
         let values = []
         this.props.selected.forEach(item => {
          const found = this.props.choices.filter(choice => choice.key === item)
          if (!!found && found[0] && found[0].text) {
            values.push(found[0].text) 
          } 
        })
        */



        if (!!this.props.onTagSelect) {
          this.props.onTagSelect({ 
            key: this.props.dbKey, //this.props.dockedText.toLowerCase(),
            dbKey: this.props.dbKey,
            parent: this.props.parent,

            dockedText:this.props.dockedText,
            dockedSymbol: this.props.dockedSymbol,
            required: this.props.required,
            saveExif: !!this.props.saveExif? true : false,

            selected: this.props.selected, 
            values: values,
            deleted: this.state.deleted,
            created:this.state.created })
        } 
                
      }

      // for click-away (collapse of extended choice list into dock)
      /*document.addEventListener('touchstart', this.handleClickOutside);
      document.addEventListener('mousedown', this.handleClickOutside);
      */
      // these two are removed temporarily to debug mobile click issue


      //document.addEventListener('touchend', this.stopLongClickHandler);
      //document.addEventListener('mouseup', this.stopLongClickHandler);

      //document.addEventListener('touchend', this.handleClickOutside);
      //document.addEventListener('mouseup', this.handleClickOutside);
      //document.addEventListener('touchcancel', this.handleClickOutside);


    }    
 

    componentWillUnmount() {

      // remove mouse event listeners, for click-away (collapse of extended choice list into dock)
      /*
      document.removeEventListener('mousedown', this.handleClickOutside);
      document.removeEventListener('touchstart', this.handleClickOutside);
      */

      //document.removeEventListener('mouseup', this.stopLongClickHandler);
      //document.removeEventListener('touchend', this.stopLongClickHandler);

      // these two are removed temporarily to debug mobile click issue



      //document.removeEventListener('mouseup', this.handleClickOutside);
      //document.removeEventListener('touchend', this.handleClickOutside);
      //document.removeEventListener('touchcancel', this.handleClickOutside);

    }



  


    componentDidUpdate(prevProps, prevState) {

      //console.log('..componentDidUpdate (tagGroup), this.horizontalRef:', this.horizontalRef)




      // translating arrangeMode change in to local state change => dock the component, turn off auto suggest, ..
      if (!!!prevProps.arrangeModeOn && !!this.props.arrangeModeOn ) {
        //this.setState({ docked: true, autoSuggest: false })
        console.log('component did update, change in arrangeModeOn, setting autoSuggest to false..')

        this.setState({ autoSuggest: false })

      }
/*
      // translating arrangeMode change in to local state change => dock the component, turn off auto suggest, ..
      if (!!prevProps.arrangeModeOn && !!!this.props.arrangeModeOn ) {
        this.setState({ docked: true, autoSuggest: false })
      }
*/


      // if docked state changes, and new state is not docked (=expanded), do horizontal scroll to selected tags
      if (this.props.choices === []) {
        console.log('component did update, no props.choices, returning early..')

        return false
      }


      if (prevState.docked !== this.state.docked && !this.state.docked) {
        //await chillSomewhat()
        
        console.log('component did update, state.docked changed to false => expanded .. scrolling horizontally..')

        this.doHorizontalScrolling()
      }   
      
      
      // if the selection has changed (added or removed, especially in multi-selection fields) scroll to the newly selected item
      
      if (prevState.selected !== this.state.selected) {
        //this.setState({})
        console.log('component did update, change in state.selected, scrolling horizontally..')

        this.doHorizontalScrolling()
      }   
      


      // current problem: scroll event also qualifies as a clickTS..

      // if there was a click outside the component, start timer for docking the tagGroup
      // doing this so i m able to differentiate between outside clicks and component clicks (which tend to follow after outside clicks)
      if (prevProps.clickedTS !== this.props.clickedTS) {
          this.clickTimer = setTimeout( ()=> this.setState({ docked: true }), 300)
          console.log('setting new clickedTS timer..')
      } 


      // got a good component click (selection, deselection, deleteIcon, ... then cancel the timer above)
      if (prevState.goodClickTS !== this.state.goodClickTS  ) {
          //&& this.state.goodClickTS - this.props.clickedTS > 500 ) {
        clearTimeout(this.clickTimer)
        console.log('goodClickTS canceld clickTS timer..')
      }


      // also clear the timer when arrangeMode is turned on/off
      // this has no effect
      if (prevProps.arrangeModeOn !== this.props.arrangeModeOn) {
        clearTimeout(this.clickTimer)
        console.log('arrangeModeOn/Off change canceld clickTS timer..')

      }


/*
function myFunction() {
  myVar = setTimeout(function(){ alert("Hello"); }, 3000);
}

function myStopFunction() {
  clearTimeout(myVar);
}
*/
      // if selected data is loaded via URL ==> argData ==> converted to new UI

      if (  !!this.props.selected 
            && !!prevProps.selected 
            && (
                  (
                    !!!prevProps.selected[0] &&     // if the previous one was not empty, and the new one is also not empty..
                    this.props.selected.length > 0
                  ) 
                  || ( 
                    !!prevProps.selected[0]         // if the previous one is not empty, and the new one is different from the prev one..
                    && !arraysAreEqual(prevProps.selected, this.props.selected) // && prevProps.selected[0] !== this.props.selected[0]
                  )
            )
          ) 
      {



        console.log('within TagGroup, setting state (componentDidUpdate)')
        // because choices get extended with new-additions... so i mostly use this.state.choices


        // if there are some pre-selected choices, set them accordingly
        if (!!this.props.selected && this.props.selected.length > 0) {
          this.setState({ selected: this.props.selected, choices: this.props.choices  })
          //this.props.onTagSelect


          // adding the VALUES of pre-selected items to parent state        key === text    careful here
          //const values = this.props.selected.map(item => this.props.choices.filter(choice => choice.key === item)[0].text )
            
          //let notInModel = []
          const values = this.props.selected.map(item => {
            const filtered = this.props.choices.filter(choice => choice.key === item) 
            if (filtered.length > 0) {
              return filtered[0].text
            } //else {
              //notInModel.push(item)
              //console.log('notInModel item:', item)
              //return item
            //}
          })

          //const notInModelChoices = []//notInModel.length > 0 ? notInModel.map(item =>  ({key: item, text: item}) ) : []
          //console.log('notInModelChoices:', notInModelChoices)

          if (!!this.props.onTagSelect) {
            this.props.onTagSelect({ 
              key: this.props.dbKey, //this.props.dockedText.toLowerCase(),
              dbKey: this.props.dbKey,
              parent: this.props.parent,

              dockedText:this.props.dockedText,
              dockedSymbol: this.props.dockedSymbol,
              required: this.props.required,
              saveExif: !!this.props.saveExif? true : false,

              selected: this.props.selected, 
              values: values,
              deleted: this.state.deleted,
              created: this.state.created }) //, ...notInModelChoices ] })
          } 
                 
        } else {
          this.setState({ choices: this.props.choices, selected: this.props.selected, values: this.props.values })
        }
      }

    }



    

    // within here calling onTagSelect with deleted tags, after removing them from local state
    handleDeleteTag = (delItem) => {

      console.log('handleDeleteTag:', delItem)
      console.log('this.state.deleted:', this.state.deleted)
      console.log('this.props.deleted:', this.props.deleted)

      if (delItem === undefined || delItem.key === undefined) {
        console.log('delItem or delItem.key is undefined, returning early..')
        return
      }

      const { key } = delItem

      // remove tag from available choices
      const newChoicesArr = removeItemFromArray(this.state.choices, key, item => item.key.toLowerCase() === key.toLowerCase()  )
      // need to remove "created" tags, if they get deleted thereafter (e.g. accidental create, wrong spelling, etc..)
      const newCreatedArr = removeItemFromArray(this.state.created, key, item => item.key.toLowerCase() === key.toLowerCase()  )
      
      // because deleting selected tags is not allowed. need to un-select first..!!
      //const newSelectedArr = this.removeItemFromArray(this.state.selected, key, item => item.toLowerCase() === key.toLowerCase()  )
      //const newValuesArr = this.removeItemFromArray(this.state.values, key, item => item.toLowerCase() === key.toLowerCase()  )

      //const delItem = this.createAddItemFromValue(item.text)


      // MULTI CHOICE
      if (!!this.props.multiSelect) {
        // inserting newly entered value right behind ... on position 1 in this.state.choices

        if (!!this.props.onTagSelect) {
          this.props.onTagSelect({ 
            key: this.props.dbKey, //this.props.dockedText.toLowerCase(),
            dbKey: this.props.dbKey, 
            parent: this.props.parent,

            dockedText:this.props.dockedText,
            dockedSymbol: this.props.dockedSymbol,
            required: this.props.required,
            saveExif: !!this.props.saveExif? true : false,

            //selected: this.state.selected, //newSelectedArr, 
            //values: this.state.values, //newValuesArr,
            deleted: [ delItem, ...this.state.deleted ],
            created: newCreatedArr,              
          })
        }


        this.setState( 
          state => ({ //docked: true, 
                      autoSuggest: false,
                      //selected: this.state.selected, //newSelectedArr, 
                      choices: newChoicesArr,
                      created: newCreatedArr,
                      deleted: [ delItem, ...this.state.deleted ]

                      //choices: [ addItem, ...state.choices ]

                      // MIGHT HAVE TO REMOVE DELETED ITEM IF ITS THE SAME LIKE WHICH WAS JUST ADDED
          })
        )  
      
      // SINGLE CHOICE
      } else {
        // in singleSelect mode, need to remove previous selections, there can only be one..
        console.log('handleAddNew, singleItem, adding..')
        console.log('this.state.choices[0]:', this.state.choices[0])



        // inverse selection if it was selected before..
        //const selected = this.state.selected.indexOf(found[0].key) > -1 ? [] : [ found[0].key ]

        //console.log('handle new s-selected:', item.toLowerCase() )
        //console.log('handle new s-values: ', addItem.text )          

        if (!!this.props.onTagSelect) {
          this.props.onTagSelect({ 
            key: this.props.dbKey, //this.props.dockedText.toLowerCase(),
            dbKey: this.props.dbKey, 
            parent: this.props.parent,

            dockedText:this.props.dockedText,
            dockedSymbol: this.props.dockedSymbol,
            required: this.props.required,
            saveExif: !!this.props.saveExif? true : false,
          
            //selected: this.state.selected, //newSelectedArr.length > 0 ? [ newSelectedArr[0] ] : [], 
            //values: this.state.values, //newValuesArr.length > 0 ? newValuesArr : [], 
            deleted: [ delItem, ...this.state.deleted ],
            created: newCreatedArr,              
          })
        }



        this.setState( 
          state => ({ //docked: true, 
                      autoSuggest: false,
                      //selected: this.state.selected, //newSelectedArr.length > 0 ? [ newSelectedArr[0] ] : [], 
                      choices: newChoicesArr,
                      created: newCreatedArr,
                      deleted: [ delItem, ...this.state.deleted ]
                      //choices: [ addItem, ...state.choices ]

          })
        )        
      }
    }

    // create a "addItem" of format { text, key } based on value (text)
    // assums that KEYS are just lower case VALUES...
    // MAYBE better to look up the TAG key within the dataModel.. or do away with TAG keys?
    // and just focus on values? 
    //  point against: PRICE (fancy formated vs number)
    //                  values with SPACE in between like 'Dainichi Toyota' or NOTES fields... !!! im not really removing spaces yet..
    createAddItemFromValue(value) {
      if (!!this.props.format && this.props.format === "price") {
        //const price = () => { return (<NumberFormat thousandSeparator={true} displayType={'text'} prefix={'¥'} value={promptRes}/>) }
        
        const price = '¥ ' + numeral(value).format('0,0')
        console.log('price:', price)

        return { key: value.toLocaleLowerCase(), text: price }

      } else {
        return { key: value.toLocaleLowerCase(), text: value }
      }      
    }


    // autoSuggest field (input field)
    // add new value to choice array, by input from autosuggest input field
    // "item" here stands for the value of the item (text), not the key
    // the key needs to be derived from this value...
    handleAddNew = (item) => {

      // return early if createNew prop is not set to true
      // should use a different component for the input field (select rather than create)
/*
      if (!!!this.props.createNew) {
        console.log('u r not allowed to createNew, unless u set the prop..')
        return
      }
*/
      console.log('handleAddNew:', item)

      
      const addItem = this.createAddItemFromValue(item)

      // remove item from deleted array, if we r creating it again.. maybe have been deleted by accident..
      const newDeletedArr = removeItemFromArray(this.state.deleted, item, i => i.key.toLowerCase() === item.toLowerCase()  )



      // MULTI CHOICE
      if (!!this.props.multiSelect) {
        // inserting newly entered value right behind ... on position 1 in this.state.choices

        if (!!this.props.onTagSelect) {
          this.props.onTagSelect({ 
            key: this.props.dbKey, //this.props.dockedText.toLowerCase(),
            dbKey: this.props.dbKey, 
            parent: this.props.parent,

            dockedText:this.props.dockedText,
            dockedSymbol: this.props.dockedSymbol,
            required: this.props.required,
            saveExif: !!this.props.saveExif? true : false,

            selected: [ item.toLowerCase() ], 
            values: [ addItem.text ],
            deleted: newDeletedArr,
            created: [ addItem, ...this.state.created ],     
            //     mergeObjectArraysSpecial(this.state.created, [...addItem], 'key', false, 't') 
         
          })
        }


        this.setState( 
          state => ({ docked: true, 
                      autoSuggest: false,

                      selected: [ ...state.selected, item.toLowerCase() ], 
                      choices: [ state.choices[0], addItem, ...state.choices.slice(1) ],
                      created: [ addItem, ...state.created ],
                      //     mergeObjectArraysSpecial(this.state.created, [...addItem], 'key', false, 't') 

                      deleted: newDeletedArr,

                      //choices: [ addItem, ...state.choices ]

                      // MIGHT HAVE TO REMOVE DELETED ITEM IF ITS THE SAME LIKE WHICH WAS JUST ADDED
          })
        )  
      
      // SINGLE CHOICE
      } else {
        // in singleSelect mode, need to remove previous selections, there can only be one..
        console.log('handleAddNew, singleItem, adding..')
        console.log('this.state.choices[0]:', this.state.choices[0])


  
          // inverse selection if it was selected before..
          //const selected = this.state.selected.indexOf(found[0].key) > -1 ? [] : [ found[0].key ]

          console.log('handle new s-selected:', item.toLowerCase() )
          console.log('handle new s-values: ', addItem.text )          

          if (!!this.props.onTagSelect) {
            this.props.onTagSelect({ 
              key: this.props.dbKey, //this.props.dockedText.toLowerCase(),
              dbKey: this.props.dbKey, 
              parent: this.props.parent,

              dockedText:this.props.dockedText,
              dockedSymbol: this.props.dockedSymbol,
              required: this.props.required,
              saveExif: !!this.props.saveExif? true : false,

              selected: [ item.toLowerCase() ], 
              values: [ addItem.text ],   // just .text ?? not the whole object??
              deleted: newDeletedArr,
              created: [ addItem, ...this.state.created ], 
              //     mergeObjectArraysSpecial(this.state.created, [...addItem], 'key', false, 't') 
             
            })
          }



        this.setState( 
          state => ({ docked: true, 
                      autoSuggest: false,

                      selected: [ item.toLowerCase() ], 
                      choices: [ state.choices[0], addItem, ...state.choices.slice(1) ],
                      created: [ addItem, ...state.created ],
                      //     mergeObjectArraysSpecial(this.state.created, [...addItem], 'key', false, 't') 

                      deleted: newDeletedArr
                      //choices: [ addItem, ...state.choices ]

          })
        )        
      }

     
    }



    // autoSuggest field (input field)
    // handle selection from autosuggest list
    handleSelect = (item) => {
      console.log('handleSelect:', item)

      // currently there are only single selections in the autosuggest list... 


      const found = (this.state.choices.filter( a => a.key === item.toLowerCase() ))


      // input is part of choice array, select it
      if (!!found & found.length > 0) {
        console.log('found in choices:', found[0])



        // MULTI selection
        if (!!this.props.multiSelect) {


          const selected = this.state.selected.indexOf(found[0].key) > -1 ?   
                                this.state.selected.filter(a => a !== found[0].key) :  
                                [ found[0].key, ...this.state.selected ]
          
          const values = this.state.selected.map(item => this.state.choices.filter(choice => choice.key === item)[0].text )


          console.log('handle multi selected:', selected)
          console.log('handle multi found[0]: ', found[0])

          if (!!this.props.onTagSelect) {
            this.props.onTagSelect({ 
              key: this.props.dbKey, //this.props.dockedText.toLowerCase(),
              dbKey: this.props.dbKey,
              parent: this.props.parent,

              dockedText: this.props.dockedText,
              dockedSymbol: this.props.dockedSymbol,
              required: this.props.required,
              saveExif: !!this.props.saveExif? true : false,
              
              selected: selected, 
              deleted: this.state.deleted, 
              values: values, // [ found[0] ],
              created: this.state.created })
          }   
          
          
          console.log('m-selected:', selected)
          console.log('m-values:', values)



          // if this tagGroup is for triggering actions (e.g. choose node for settings), don t select item, return early 
          if (!!this.props.actionTrigger) {  
            
          } else {
            this.setState(({ docked: true, autoSuggest: false, selected: selected }) )
          }

        } else {

          console.log('handleSelect, single select.. ')
/*
          this.setState( 
            state => ({ docked: true, autoSuggest: false, selected: state.selected.indexOf(found[0].key) > -1 ? 
                [] : [ found[0].key ]
            })
          ) 
*/          
          
          // inverse selection if it was selected before..
          const selected = this.state.selected.indexOf(found[0].key) > -1 ? [] : [ found[0].key ]

          console.log('handle s-selected:', selected)
          console.log('handle s-values: ', found[0])          

          if (!!this.props.onTagSelect) {
            this.props.onTagSelect({ 
              key: this.props.dbKey, //this.props.dockedText.toLowerCase(),
              dbKey: this.props.dbKey,
              parent: this.props.parent,

              dockedText:this.props.dockedText,
              dockedSymbol: this.props.dockedSymbol,
              required: this.props.required,
              saveExif: !!this.props.saveExif? true : false,

              selected: selected, 
              deleted: this.state.deleted, 

              values: [ found[0].text ],              
              created:this.state.created })
          }


          // if this tagGroup is for triggering actions (e.g. choose node for settings), don t select item, return early 
          if (!!this.props.actionTrigger) {  
            
          } else {
            this.setState({ selected: selected, 
                            docked: true,
                            autoSuggest: false,
                          })
          }

        }

      }
          
    }


    
    // handle click on expanded tag choices (horizontal list)
    handleClick = async (key) => {

        console.log('handleClick key:', key)  



        const { multiSelect=false, multiSelectMaxN=null } = this.props


/*
        if (key==='showMore') {
            console.log('show more clicked..') 
            this.setState({ docked: false })

            //this.doHorizontalScrolling()   


            return          
        }
*/

        if (this.state.docked) {
          this.setState({ docked: false, goodClickTS: Date.now() })
          return
        }

/*
        if (key==='x') {
             console.log('closed by user who clicked x') 
             this.setState({ docked: true, autoSuggest: false })

             return
        } 
        */
        
        if (key ==='t') {
          console.log('got keyboard input...')
          
          // activate rendering of auto-suggest
          this.setState({ autoSuggest: true, goodClickTS: Date.now() })

          return 

        }




        // SINGLE select case, select and collapse choosen tag into docked-mode
        if (!multiSelect) {

            console.log('multiSelect false or null')

            const selected = this.state.selected.indexOf(key) > -1 ? [] : [ key ]
            const values = selected.map(item => this.state.choices.filter(choice => choice.key === item)[0].text )

        
            if (!!this.props.onTagSelect) {
              this.props.onTagSelect({ 
                key: this.props.dbKey, //this.props.dockedText.toLowerCase(),
                dbKey: this.props.dbKey,
                parent: this.props.parent,

                dockedText:this.props.dockedText,
                dockedSymbol: this.props.dockedSymbol,
                required: this.props.required,
                saveExif: !!this.props.saveExif? true : false,

                selected: selected, 
                deleted: this.state.deleted, 

                values: values,
                created:this.state.created })
            }

            console.log('selected:', selected)
            console.log('values:', values)

            // if this tagGroup is for triggering actions (e.g. choose node for settings), don t select item, return early 
            if (!!this.props.actionTrigger) {  
              console.log('got actionTrigger click..')

              // finding callback function associated with this value pair and calling it when found..
              const fn = this.state.choices.find(choice => choice.key === key).fn  || undefined

              if (fn !== undefined) {
                console.log('calling fn callback for choice:', key)
                fn()
              }

              this.setState({ //selected: selected, 
                              docked: true, 
                              goodClickTS: Date.now() })              
            } else {
              this.setState({ selected: selected, 
                              docked: true, 
                              goodClickTS: Date.now() })
            }

            return
        }


        // multi-select case
            // allow selection of everything (no max number is given by user)

        if (!!multiSelectMaxN) {

          // CURRENTLY NOT HANDLING case where multiSelectMaxN is set.. !!!!
          console.log('multiSelectMaxN is set, but not yet')

        } else {

            console.log('multiSelectMaxN false or null')

            const selected = this.state.selected.indexOf(key) > -1  ? this.state.selected.filter(item => item !== key) 
                                                                    : [ key, ...this.state.selected ]
            const values = selected.map(item => this.state.choices.filter(choice => choice.key === item)[0].text )

            if (!!this.props.onTagSelect) {

              this.props.onTagSelect({ 
                key: this.props.dbKey, //this.props.dockedText.toLowerCase(),
                dbKey: this.props.dbKey,
                parent: this.props.parent,

                dockedText:this.props.dockedText,
                dockedSymbol: this.props.dockedSymbol,
                required: this.props.required,
                saveExif: !!this.props.saveExif? true : false,


                selected: selected, 
                deleted: this.state.deleted, 

                values: values,
                created:this.state.created })
            }


            // if this tagGroup is for triggering actions (e.g. choose node for settings), don t select item, return early 
            if (!!this.props.actionTrigger) {  
              this.setState({ //selected: selected, 
                              goodClickTS: Date.now() })              
            } else {
              this.setState({ selected: selected, 
                              goodClickTS: Date.now() })
            }            


            //this.setState({ selected: selected, goodClickTS: Date.now() })





                /*
            this.setState( 
                state => ({ selected: state.selected.indexOf(key) > -1 ? 

                    state.selected.filter(item => item !== key) :  
                    [ key, ...state.selected ]
                })
            )
            */
   
            return
        }



    }
/*
        //this.setState(prevState => ({ selected: prevState.selected.filter(item => item !== key) }))            
        //const foundIdx = this.state.selected.indexOf(key)
        //    const choiceLen = this.state.choices.length
            

        this.setState( 
            state => ({ selected: state.selected.indexOf(key) > -1 ? 
                prevState.selected.filter(item => item !== key) :  
                [...prevState.selected, key ]
            })
        )

*/





    createKey = (valTxt) => {
      return 'key' + this.props.dockedText + '-' + valTxt
    }



    doHorizontalScrolling = async () => {

   
      // scroll to specific position in horizontal list of tags/chips
      if (!this.state.docked && !!this.props.scrollHandler && !!this.horizontalRef && !!this.state.selected && this.state.selected.length > 0) { //} || !!this.horizontalRef.children)  && !!this.props.scrollHandler) {

        console.log('doHorizontalScrolling: this.horizontalRef:', this.horizontalRef)


        // need this delay to wait for horizontal list to get rendered.. stupid hack.. that looks organic
        await waitFor({ atLeast:200, maybeMore: 0 })      

        console.log('!!! chilling..')
        // One of "start", "center", "end", or "nearest". Defaults to "nearest".

        // works on safari mobile, but scrolls to the very left.., so removing   top: 0, left: 0  to see how it goes without.
        //         this.props.scrollHandler(this.horizontalRef, { top: 0, left: 0, inline:'center', behavior: 'smooth'})

        
        this.props.scrollHandler(this.horizontalRef, { inline:'center', behavior: 'smooth'})
      
      }

    }



    // when clicking on  the docked element
    handleDockedClick = () => {

      this.setState({ docked: false, goodClickTS: Date.now() })

      //this.doHorizontalScrolling()   
      
    }




    renderDocked = () => {

        //console.log('TagGroup renderChips start..')

        const { classes, avatar='', dockedText='', dockedSymbol='' } = this.props




        // no selected items? just show dock symbol   ... no choices (e.g. user deleted all choices: show dock symbol)
        if (this.state.selected === null || this.state.selected.length === 0 || this.state.choices.length === 0) {
          //console.log('renderDocked: got nothing selected (this.state.selected).. rendereing dockedText:', dockedText)

          // show category, e.g. "size", when there is no selection
          return this.renderChipDocked({ 
                    key:this.createKey('docked'), 
                    avatar: !!avatar && avatar.length > 0 ? <Avatar>{avatar}</Avatar> : null,
                    item:{ key:'dockedText', text:dockedText}, 
                    backgroundColor: this.props.backgroundColor || null,
                    color: this.props.color || 'primary', // standard color for docked is 'secondary'
                    clickHandler: this.handleDockedClick,
                    classes 
                  } )

        }

        //console.log('renderDocked got this.state.selected:', this.state.selected, this.state.choices)



        // render selected tags in docked mode
        const r = this.state.selected.map( (chipKey, idx) => {

            const item = this.state.choices.find( item => item.key === chipKey ) || -1
            if (!item) {
              console.log('couldnt find ', chipKey, ' in this.state.choices:', this.state.choices )
              return null
            }



            const key = this.createKey(chipKey)
//            const item = this.state.choices[chipIdx]
            //console.log (' key:', key, ' item:', item)

            //   avatar={ !!avatar && avatar.length > 0 ? <Avatar>{avatar}</Avatar> : null }


            return this.renderChipDocked({ key:'docked'+key,
              avatar: idx===0 && !!dockedSymbol && dockedSymbol.length > 0 ? dockedSymbol : null,
              item, 
              clickHandler: this.handleClick,
              classes,
              backgroundColor: this.props.backgroundColor || null,
              color: this.props.color || 'secondary', // standard color for items is 'secondary'
            } ) 
            
        })

        /*
        // not used anymore
        // render showMore icon, to expand docked list on click
        const showMore = this.renderChipDocked({ key:this.createKey('docked'),
                    color: 'primary', 
                    avatar: !!avatar && avatar.length > 0 ? <Avatar>{avatar}</Avatar> : null,
                    item:{ key:'showMore', text:':'}, 
                    clickHandler: this.handleClick,
                    classes 
                  } ) 
*/
        //console.log('TagGroup r:', r)

        return r
//        return !!r && r.length > 0 ? [showMore, ...r] : showMore

      } 








    doTagDelete = async (item) => {



      this.handleDeleteTag(item)
/*
      // local delete within state, not in cloud DB
      const newChoices = this.state.choices.filter( item => {
        return item.key !== itemKey
      } ) 

      //this.setState({ choices: newChoices, deleteDialogOpen: false, tagToDelete: itemKey })

      this.setState({ choices: newChoices })
*/
      console.log('TAG DELETED..', item )      
    }



    onTagDelete = async (item) => {

      console.log('this.onTagDelete called with item:', item)

      // dont allow deleting of selected field
      if (this.state.selected.indexOf(item.key) > -1) {
        return null
      }

      // not allowed to delete the keyboard
      if (item.key === 't') {
        return null
      }      
      
/*
      // don t allow deleting of all fields (cause i can handle empty choice list yet..)
      if (this.state.choices.length < 2) {
        console.log('can t delete last Tag, because list would be empty..')
        return null
      }    
      
*/

      this.setState({ confirmDialogOpen: true })


      // find the actual label (text) of for this item
      const choicesItem = this.state.choices.filter( i => {
        return i.key === item.key
      } )


      this.props.doConfirmDialog(choicesItem[0], this.doTagDelete ) 
/*
      // show confirm dialog..
      const result = await confirmService.show({
        title: choicesItem[0].text,
        //message:'Really delete ' + choicesItem[0].text + '??',
        icon: <DeleteIcon fontSize="large" />
      });
      if (result) {
        this.doTagDelete(choicesItem[0])
      }


      this.setState({ confirmDialogOpen: false })
*/


/*  // testing new confirm dialog above, so putting this away for a bit..

      if (this.props.openConfirmDelDialog !== undefined) {
        console.log('opening confirm dialog for itemKey:', itemKey)

        const choicesItem = this.state.choices.filter( item => {
          return item.key === itemKey
        } )


        this.props.openConfirmDelDialog({ delTitle:choicesItem[0].text, delText:"Really delete?", 
                                          delCB: ()=>this.doTagDelete(itemKey) }) 
        
      }
*/





/*
      //this.doTagDelete(itemKey)
      this.setState({ deleteDialogOpen: true, tagToDelete: itemKey }) // deleteTag: itemKey, 

      setTimeout(() => {
        this.setState({ deleteDialogOpen: false, tagToDelete: null }) // deleteTag: itemKey, 
      },2000)      
      */
      
      


/*
      const newSelected = this.state.selected.filter( item => {
        return item.key !== itemKey
      } ) 


      const newValues = this.state.values.filter( item => {
        return item.key !== itemKey
      } )    
      
      const newCreated = 'create items minus the current deleted one (if its among them)'
      const newDeleted = 'deleted items plus current deleted one'


      const values = this.props.selected.map(item => this.props.choices.filter(choice => choice.key === item)[0].text )
          
      if (!!this.props.onTagSelect) {
        this.props.onTagSelect({ 
          key: this.props.dbKey, //this.props.dockedText.toLowerCase(),
          dbKey: this.props.dbKey,
          dockedText:this.props.dockedText,
          dockedSymbol: this.props.dockedSymbol,
          required: this.props.required,

          selected: newSelected, 
          values: newValues,
          deleted: newDeleted,
          created: newCreated })
      }  
      */     

      //console.log('newChoices without deleted tag:', newChoices)
      console.log('need to rerender expanded tag list to see change... also need to confirm and then call DB')



    }


/* // could compose new "Chip" funciton, to enable long-clicks...

      return <div 
                data-func=    {this.turnOnDeleteMode}
                onMouseDown=  {this.startLongClickHandler }
                onMouseUp=    {this.stopLongClickHandler }
                onTouchStart= {this.startLongClickHandler}
                onTouchEnd=   {this.stopLongClickHandler }
              >
                <Chip
*/


    // render a single tag, only used for selection purpose (not when docked)
    renderChipDocked = ({ item, avatar='', classes, backgroundColor=null, color='secondary', clickHandler=null, placeRef=false }) => {

      const key = this.createKey(item.key)

      //const longPressForDelete = useLongPress(this.turnOnDeleteMode, 500);
      


      // if the avatar text is too long, but value short, replace it with the labelText
      if (!!avatar && avatar.length > 3 && item.text.length < 8) {

          return <Chip
                
              key={ 'open'+key }
              //label={ <span>{ item.text } <LockOpenRoundedIcon style={{ height:'0.7em', width:'0.7em'}}/> </span> }
              label={ this.props.dockedText + ' ' + item.text }
              clickable
              className={classes.chip}
              color={ color }
              onClick={ !!clickHandler ? () => clickHandler(item.key) : null }
              style={ color !== null || backgroundColor !== null ? { backgroundColor, color } : {} }      


              //onDelete={ () => console.log('deleted..') }
              //variant={"outlined"}
              //deleteIcon={<LockOpenRoundedIcon />}
              
              //inputRef={el => this.inputElement = el}

              //onDelete={handleDelete}
              //onClick={ () => this.setState(state => { selected: !state.selected } ) }
              //deleteIcon={<DoneIcon />}
          />          
      }

      // ref={ placeRef ? el => this.horizontalRef = el : null }
      return <Chip
                    key={ 'open'+key }
                    avatar={ !!avatar && avatar.length > 0 ? <Avatar>{avatar}</Avatar> : null }
                    //label={ <span>{ item.text } <LockOpenRoundedIcon style={{ height:'0.7em', width:'0.7em'}}/> </span> }
                    label={ item.text }
                    clickable
                    className={classes.chip}
                    color={ color === 'primary' || 'secondary' ? color : null }
                    onClick={ !!clickHandler ? () => clickHandler(item.key) : null }     
                    style={ color !== null || backgroundColor !== null ? { backgroundColor, color } : {} }      


                    //onDelete={ () => console.log('deleted..') }
                    //variant={"outlined"}
                    //deleteIcon={<LockOpenRoundedIcon />}
                    
                    //inputRef={el => this.inputElement = el}

                    //onDelete={handleDelete}
                    //onClick={ () => this.setState(state => { selected: !state.selected } ) }
                    //deleteIcon={<DoneIcon />}
              />
            
    }


    renderArrangeModeChips = () => {

      const { classes, avatar='' } = this.props    // forwardRef

      const addGroupBeforeKey = this.createKey('addGroupBefore')
      const addGroupAfterKey = this.createKey('addGroupAfter')

      const AddGroupBefore = this.renderChip({ addGroupBeforeKey, avatar:'+', item: { key: 'AddGroupBefore', text: 'Group'}, backgroundColor: '#C02626', classes, placeRef: false })
      const AddGroupAfter = this.renderChip ({ addGroupAfterKey, avatar:'+', item: { key: 'AddGroupAfter', text: 'Group'}, backgroundColor: '#C02626', classes, placeRef: false })




      return [AddGroupBefore, this.renderChips(), AddGroupAfter]

      //this.renderChip({ key, avatar, item, classes, placeRef: false })

    }



    // renders expanded, horizontally scroll-able chip list
    renderChips = () => {

        console.log('TagGroup renderChips expanded start..')

        const { classes, avatar='' } = this.props    // forwardRef

        // if all choices were removed / deleted, then there is nothing to render..
        if (this.state.choices.length === 0) {
          console.log('!!! choices are empty, nothing to render in renderChips..')
          return
        }



        const r = this.state.choices.map( (item, idx, arr) => {

            const key = this.createKey(item.key)

            // text input field (with autosuggest)
            if (item.key === 't' && this.state.autoSuggest) {

              console.log('rendering IntegrationDownshift/IntegrationReactSelect..')  // IntegrationReactSelect
              //return <IntegrationDownshift suggestions={this.state.choices} />

              if (!!this.props.scrollHandler) {
                console.log('calling scrollHandler from child with (vertical) ref:', this.verticalRef)
                

                // maybe only do this with "t" field, no vertical scrolling should be needed for normal selections...
                // ==> move this down 
                // visibly do some scrolling after a short deplay, for dramatic effect.
                setTimeout(() => {
                  this.props.scrollHandler(this.verticalRef, {block: 'nearest', behavior: 'smooth'})
                },800)


                // maybe need to put in an async delay before scrolling up... the keyboard is not rendered out yet
                // the vertical list not shown yet, ... therefore there is not much to scroll down to...
              }
              
              return <IntegrationReactSelect 
                        key={key}
                        suggestions={this.state.choices.slice(1)} //   SURE THE KEYBOARD IS ON POS 0???  without first field (which is the trigger field t for input)                    suggestions={this.state.choices.slice(1)} 
                        onSelect={ this.handleSelect } 
                        onNew={ this.handleAddNew }
                        inputType={ this.props.inputType }
                      />

            }
            

            // doing horizontal scroll if selected fields present 
            if (!!this.state.selected && this.state.selected[0] && this.state.selected[0] === item.key) {
              console.log('renderChips: this.state.choices contains selected item/idx:', item, idx)
              console.log('this.state.selected:', this.state.selected)
              console.log('placeRef:', !!this.state.selected && this.state.selected[0] && this.state.selected[0] === item.key)
              //this.doHorizontalScrolling()
            }

            // showing keyboard now outside the horizontal scroll panel, so don t render it here
            if (!!item.key && item.key === 't') {
              
              return null
            }
            //return this.renderChip({ key, avatar, item, classes, placeRef: idx===(arr.length)-1 })

            // could pass in if this field can be deleted or not, e.g. if there is a keyboard next to it or not..
            // .. consider: team non admins should only be allowed to delete fields they created...
            return this.renderChip({  key, avatar, item, classes, 
                                      backgroundColor: this.props.backgroundColor || null,
                                      color: this.props.color || null,
                                      placeRef: !!this.state.selected && this.state.selected[0] && this.state.selected[0] === item.key })


        })



        // if there s a keyboard field, and autoSuggest has not been triggered yet, arrange the keyboard icon as first to the left UNMOVING
        



        if (this.state.choices[0].key === 't' && !this.state.autoSuggest) {

          const key = this.createKey('t')
          const keyboardChip = this.renderChip({  key:key, 
                                                  avatar, 
                                                  item: { key: this.state.choices[0].key, text: this.props.keyboardIcon }, 
                                                  classes, 
                                                  placeRef: null,
                                                  backgroundColor: this.props.backgroundColor || null,
                                                  color: this.props.color || 'primary', // standard color for items is 'secondary'

                                                })


          if (!!this.props.showLabelExpanded) {
            return (<span style={ContainerSpan}><span style={ LabelSpan }>{this.props.dockedText}:</span><span style={ KeyboardIconSpan }>{keyboardChip}</span><span style={ChipSpan}>{r}</span></span>)
          }          
          return (<span style={ContainerSpan}><span style={ KeyboardIconSpan }>{keyboardChip}</span><span style={ChipSpan}>{r}</span></span>)


        } else {

          
          if (!!this.props.showLabelExpanded) {
            return (<span style={ContainerSpan}><span style={ LabelSpan }>{this.props.dockedText}:</span><span style={ChipSpan}>{r}</span></span>)
          }

          return (<span style={ContainerSpan}><span style={ChipSpan}>{r}</span></span>)

        }


        // x was used to close the extended chip into the dock... not used anymore
        //const x = this.renderChip({ key:this.createKey('x'), item:{ key:'x', text:'x'}, classes } )

        //console.log('TagGroup r:', r)
        //return (<span style={ContainerSpan}><span style={ LabelSpan }>Label:</span><span style={ChipSpan}>{r}</span></span>)
        
        
        //return [ x, ...r ]
    } 
    
    /*

    display: inline-block;
    border: 1px solid red;
    position:relative;
}

#column-content strong {
    color: #592102;
    font-size: 18px;
    */


    /*

    // scroll to the first selected element (problem, i can only go back to the full choices list, when selections are empty..)
    scrollIntoViewHorizontal = () => {
      console.log('scrollIntoView..')
      //if (!!this.state.selected && this.state.selected.length > 0 && !!this.refs && this.refs[ this.state.selected[0] ] ) {
   
      
        //const myRef = this.state.selected[0]

        // scroll to the middle position of choices list
        const scrollToPos = this.state.choices.length > 5 ? Math.floor(this.state.choices.length/2) : 0
        const myRef = this.state.choices[scrollToPos]
        console.log('myRef:', myRef)
        console.log('this.refs:', this.refs)
        console.log('this.refs[myRef]:', this.refs[myRef.key])
        //await chillSomewhat()
//        this.refs[myRef.key].scrollIntoView({inline: 'center', behavior: 'smooth'});
        this.refs.marujiyu.scrollIntoView({inline: 'center', behavior: 'smooth'});

//[name]: value,
      //}      
    }




    // scroll to the first selected element (problem, i can only go back to the full choices list, when selections are empty..)
    scrollIntoViewVertical = () => {
      console.log('scrollIntoView..')
      //if (!!this.state.selected && this.state.selected.length > 0 && !!this.refs && this.refs[ this.state.selected[0] ] ) {
   
      
        //const myRef = this.state.selected[0]

        // scroll to the middle position of choices list
        const scrollToPos = this.state.choices.length > 5 ? Math.floor(this.state.choices.length/2) : 0
        const myRef = this.state.choices[scrollToPos]
        console.log('myRef:', myRef)
        console.log('this.refs:', this.refs)
        console.log('this.refs[myRef]:', this.refs[myRef.key])
        //await chillSomewhat()
        this.refs[myRef.key].scrollIntoView({block: 'start', behavior: 'smooth'});
        //[name]: value,
      //}      
    }


    waitAndScrollHorizontal = async () => {

      await chillSomewhat({ atLeast:1500 })
      this.scrollIntoViewHorizontal()        
    }

*/



  

    render() {

        const { classes } = this.props


        const RenderedTagGroup = !!this.state.docked 
                                    ? this.renderDocked() 
                                    : this.props.arrangeModeOn 
                                        ? this.renderArrangeModeChips()
                                        : this.renderChips()

/*
          // trying to figure out, why clicks don t work on mobile devices... but ok on desktop and simulator

            <div className={classes.root} ref={this.verticalRef}    
              onTouchStart= {this.startLongClickHandler}
              onTouchEnd=   {this.stopLongClickHandler }            
              onMouseDown=  {this.startLongClickHandler }
              onMouseUp=    {this.stopLongClickHandler }

            >


            <div className={classes.root} ref={this.verticalRef}    
              onTouchStart= {this.startLongClickHandler}
              onTouchEnd=   {this.stopLongClickHandler }            
              onMouseDown=  {this.startLongClickHandler }
              onMouseUp=    {this.stopLongClickHandler }
            >            


              onTouchStart={ (e) => this.tStart({ type:'touch', 
                                                    id: e.target.innerText, 
                                                    pos: { x:e.touches[0].clientX, y:e.touches[0].clientY }  
                                                  }) } 
              onMouseUp={ (e) => this.tStart({  type:'mouse', 
                                                id: e.target.innerText, 
                                                pos: { x:e.clientX, y:e.clientY }  
                                              }) }
              onTouchEnd={ (e) => this.tEnd({   type:'touch', 
                                                id: e.target.innerText, 
                                                pos: { x:e.touches[0].clientX, y:e.touches[0].clientY }  
                                              }) } 
              onMouseDown={ (e) => this.tEnd({  type:'mouse', 
                                                id: e.target.innerText, 
                                                pos: { x:e.clientX, y:e.clientY }  
                                              }) }    
                                              
                                              



                onTouchEnd={ (e) =>  { this.stopLongClickHandler(e, {   
                          type:'touch', 
                          action:'end',
                          id: e.target.innerText, 
                          pos: { x:e.changedTouches[0].clientX, y:e.changedTouches[0].clientY }  
                        }) 
                      }} 
                onMouseUp={ (e) => { this.stopLongClickHandler(e, {  
                          type:'mouse', 
                          action:'end',
                          id: e.target.innerText, 
                          pos: { x:e.clientX, y:e.clientY }  
                        }) 
                      }}

*/

        return(
            <div className={classes.root} ref={this.verticalRef}  
            /*  
                onTouchStart={ this.startLongClickHandler } 
                onMouseDown={ this.startLongClickHandler }

                onTouchEnd={ this.stopLongClickHandler }
                onMouseUp={ this.stopLongClickHandler }
*/
            >
                
                { !!this.props.arrangeModeOn && this.state.tagToDelete !== null && <span>DelTag:{this.props.tagToDelete}</span> }

                { RenderedTagGroup } 
                
            </div>            
        )
    }

} // end of class



TagGroup.propTypes = {

    classes: PropTypes.object.isRequired,

    choices: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        key: PropTypes.string,
      })
    ).isRequired,

    selected: PropTypes.arrayOf(PropTypes.string), 
    createNew: PropTypes.bool,


    multiSelect: PropTypes.bool,
    multiSelectMaxN: PropTypes.number,
    docked: PropTypes.bool,
    dockedText: PropTypes.string,
    dockedSymbol: PropTypes.string,

}
  

// choices:  [{ text: '', key: '' }, ..]
// avatar: text or image of avatar (default is null)
// selected: array of keys of pre-selected items (default is null)
// multiSelect: allow selection of more than one item? (default is false)
// multiSelectMaxN: max number of selected items (default is null, only applies to multiSelect=true case)
// classes: theme/styling classes from material-ui

// docked: shows selected value or TagGroup symbol on the side (default is true), user needs to click on the docked item to show the tags for selection
// dockedText: category text describing the choices, only shown when docked, e.g. {'Size'}
// dockedSymbol: short text or symbol for docked tags, shown on every docked tag, e.g. {'cm'}

  



  
export default withStyles(styles)(TagGroup);
  
  

/*
const RefTagGroup = React.forwardRef((props, ref) => {
  console.log('RefTagGroup called.. ref:', ref)
  return <TagGroup {...props} forwardedRef={ref} />;
});

export default withStyles(styles)(RefTagGroup);
*/

/*

function RefTagGroup(Component) {


  // Note the second param "ref" provided by React.forwardRef.
  // We can pass it along to LogProps as a regular prop, e.g. "forwardedRef"
  // And it can then be attached to the Component.
  return React.forwardRef((props, ref) => {
    return <TagGroup {...props} forwardedRef={ref} />;
  });
}

*/