import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import LinearProgress from '@material-ui/core/LinearProgress'
import CircularProgress from '@material-ui/core/CircularProgress';

/*
how to vertically add a new tagGroup or tagBlock to the data model:

- needs to be inserted into this.props.tagModel 
- tag2, tagGroup and tagBlock need to be albe to handle empty choices (to add new choices)
- long-click needs to be on tagGroup parent = tag2  and include tagBlock (to add/remove/move tagGroups within and add new tagBlocks)
- this.props.tagModel, argHash, this.state all has to move to redux

*/

//import FaceIcon from '@material-ui/icons/Face';
//import DoneIcon from '@material-ui/icons/Done';


// ratings: https://material-ui.com/components/rating/

import TagGroup from '../components/tagGroup'
import TagBlock from '../components/tagBlock'
import Avatar from '@material-ui/core/Avatar';


//import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import statcan from '../helpers/statcan'
import { getUrlPayload, mergeSimpleArrays, mergeObjectArrays, mergeObjectArraysSpecial, toDateTimeObj, getPositionDeltas, waitFor } from '../helpers/basics'
import confirmService from '../components/confirmService';


//import { saveAs } from 'file-saver';

//import Hashids from 'Hashids'

import KeyboardIcon from '@material-ui/icons/KeyboardOutlined';

import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search'
//import ImageSearchIcon from '@material-ui/icons/ImageSearch'
import ShareIcon from '@material-ui/icons/Share'

//import AppsIcon from '@material-ui/icons/Apps'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'




//import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft'
//import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight'
//import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
//import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop'

//import FlashOnIcon from '@material-ui/icons/FlashOn'

//import SaveAltIcon from '@material-ui/icons/SaveAlt'
//import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import SettingsIcon from '@material-ui/icons/Settings'

//import PhotoFilterIcon from '@material-ui/icons/PhotoFilter'
//import ChevronRightIcon from '@material-ui/icons/ChevronRight'
//import CancelIcon from '@material-ui/icons/Cancel'
//import StarIcon from '@material-ui/icons/StarBorder'
//import FavoriteIcon from '@material-ui/icons/Favorite'

//import PinDropIcon from '@material-ui/icons/PinDrop'
import MapIcon from '@material-ui/icons/Map'

import { Storage } from 'aws-amplify';



/*
gesture (doodle)
create (pencil)

undo
redo

// maybe for layout of written specs on image (left / right / bottom )

border_left
border_right
border_bottom
border_top
border_clear


*/




import SaveImgDialog from '../components/saveImgDialog'
import BrowseS3dialog from '../components/browseS3dialog'




//import roundRect from '../helpers/roundRect'


//import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

//import { onload2promise, onload2promiseE } from '../helpers/promiseTools'
import { generateQRid, piexifImageProcessor, piexifUrlImageProcessor } from '../helpers/processImage'
//import { generateQRid, urlImageProcessor, imageProcessor, piexifImageProcessor, piexifUrlImageProcessor, saveCanvas } from '../helpers/processImage'


//import Hashids from 'hashids/lib/hashids'
import Hashids from 'hashids'
//import shortid from 'shortid'



//import ArtQR from 'art-qr';
 
// create something you can access to store the instance if you want
//let MyQRInstance;

//import bardcode from 'bardcode'
//var bardcode = require("bardcode");

import vhCheck from 'vh-check'


// MIGHT HAVE TO UPDATE THIS WHEN USER INTERACTS WITH SCREEN - E.G. SCROLLS...
// THIS IS A MESS.... need it to resize image correctly for main screen....
// probably will remove all this and disable iOS vertical scrolling...
const vhCheckObj = vhCheck()
const TOP_MENU_MARGIN = vhCheckObj.offset > 0 ? vhCheckObj.offset : 10  //Math.abs(50+vhCheckObj.offset) //vhCheckObj.offset

var myhashids = new Hashids('', 0, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'); // all lowercase

//var myhashids = new Hashids('', 0, 'abcdefghijklmnopqrstuvwxyz'); // all lowercase
console.log('top 234,hashids:', myhashids.encode('234'))

//var Hashids = require('hashids');
//var hashids = new Hashids('', 0, 'abcdefghijklmnopqrstuvwxyz'); // all lowercase



const styles = theme => ({
  root: {
    position: 'relative'
  },
  fab: {
    
    //margin: theme.spacing(1),
  },  
  stacked: {
    position: 'absolute',
    backgroundColor: 'red',
    top: '200px',
    left: '200px',
    zIndex: 1000
  }
  
  
});











// Tags component, simple encapsulation (with styles) of TagGroup
class Tag extends React.Component {

  constructor(props) {
    super(props)
    this.photoRef = React.createRef()
    this.canvasRef = React.createRef()
    this.textCanRef = React.createRef()

    this.mergeCanvas = React.createRef()
    //this.clickedTS = 0

  }

  state = {
//    photo: { name: `url("./koi6.jpg")` }
      photo: { name: "/koi6.jpg" },
      photoUrl: "/koi6.jpg", 
      mimeType: 'image/jpeg',    // for standard image (koi6.jpg)

      image: null,

      // in css this can be used: `calc(100vh - var(--vh-offset, 0px))`
      screenSize: { height: window.innerHeight-TOP_MENU_MARGIN, width: window.innerWidth },
      canVals: { },

      data: [], // testing callback function and dumping state into data on every select / deselect,
      loading: false,

      //lastModified: '2019-11-11',      // photo file created date
      //lastModifiedRaw: null,           // timestamp of modiefied file
      //lastModifiedTime: null,         // time (without date) of modified file
      lastModifiedTS: null,

      gpsLat: null,
      gpsLng: null,

      saveDialogOpen: false,
      browseS3dialogOpen: false,

      uid: null,
  
      exifData: null,
      argDataHashTable: [],

      prevUrl: null,




      tagModel: null,


      // bringing clickOutside / longClick / confirmDialog up from child components (tagGroup):
      arrangeModeOn: false,        // for deleting tags, adding/removing groups / blocks, rearranging order..
      confirmDialogOpen: false,
      clickedTS: 0                // timestamp for last click (outside)
  }


  //this.setState({ lastModifiedRaw, lastModified: lastModified, photo: photo, photoUrl: photo })
   

  processNewUrlImage = async (argImageUrl, argUid) => {

    this.setState({ lastModifiedTS: null })

    console.log('tag2.. processNewUrlImage argImageUrl:'+ argImageUrl+ ' with argUid:' +argUid)
    await this.readArgImage(argImageUrl, argUid)

    const argDataHashTable = this.argDataToHashTable()
    console.log('argDataHashTable processNewUrlImage:', argDataHashTable)
    this.setState({ argDataHashTable: argDataHashTable, uid: argUid, prevUrl: argImageUrl })
  
  } 

  /*
  // missuse of shouldComponentUpdate
  async shouldComponentUpdate(nextProps, nextState) {

    if (nextProps.ts !== this.props.ts) {
      console.log('shouldComponentUpdate ts updated..:', this.props.ts)
      console.log('prevProps.argImageUrl:', nextProps.argImageUrl)
      console.log('this.props.argImageUrl:', this.props.argImageUrl)

      return true

    }

    if (this.state.prevUrl !== this.props.argImageUrl) {
      return true
    }

    // only update state if we have a new/changed image URL coming in
    if (  !!nextProps.argImageUrl 
          && nextProps.argImageUrl.length > 0 
          && (( !!this.props.argImageUrl 
                && this.props.argImageUrl.length > 0
                && nextProps.argImageUrl !== this.props.argImageUrl
              )
              || (this.props.argImageUrl === undefined || !!!this.props.argImageUrl || !!!this.props.argImageUrl.length)
          )
        )  {

          //return true
          //await this.processNewUrlImage(nextProps.argImageUrl, nextProps.argUid) 

    }    
  }
*/

async componentDidUpdate(prevProps, prevState) {

  if (this.props.argImageUrl !== prevProps.argImageUrl) {

    this.setState({ loading: true, loadingMsg: 'loading image..', arrangeModeOn:false })
    await this.processNewUrlImage(this.props.argImageUrl, this.props.argUid) 
    
    this.setState({ loading: false, loadingMsg: 'loading finished..' })

  } 
  

  // if a new tagModel comes in via props, put it into state..
  if (this.props.tagModel !== prevProps.tagModel) {

    this.setState({ tagModel: this.props.tagModel })
    
  }   
  
}


/*
  async componentDidUpdate(prevProps, prevState) {

    if (prevProps.ts !== this.props.ts) {
      console.log('componentDidUpdate ts updated..:', this.props.ts)
      console.log('prevProps.argImageUrl:', prevProps.argImageUrl)
      console.log('this.props.argImageUrl:', this.props.argImageUrl)

    }

    // only update state if we have a new/changed image URL coming in
      if (  !!this.props.argImageUrl 
          && this.props.argImageUrl.length > 0 
          && (( !!prevProps.argImageUrl 
                && prevProps.argImageUrl.length > 0
                && prevProps.argImageUrl !== this.props.argImageUrl
              )
              || (prevProps.argImageUrl === undefined || !!!prevProps.argImageUrl || !!!prevProps.argImageUrl.length)
          )
        )  {

      console.log('tag2.. got incoming argImageUrl..:'+ this.props.argImageUrl+ ' with argUid:' +this.props.argUid)
      await this.readArgImage(this.props.argImageUrl, this.props.argUid)

      const argDataHashTable = this.argDataToHashTable()
      console.log('argDataHashTable componentDidUpdate:', argDataHashTable)
      this.setState({ argDataHashTable: argDataHashTable, uid: this.props.argUid })
    

    }
  }

*/

  componentWillUnmount() {

    // remove mouse event listeners, for click-away (collapse of extended choice list into dock)
    //document.removeEventListener('mousedown', this.handleClickOutside);
    //document.removeEventListener('touchstart', this.handleClickOutside);
  }


  async componentDidMount() {

    const vhCheckObj = vhCheck()
    const TOP_MENU_MARGIN = vhCheckObj.offset > 0 ? vhCheckObj.offset : 10  //Math.abs(50+vhCheckObj.offset) //vhCheckObj.offset



    this.setState({ loading: true, 
                    tagModel: this.props.tagModel,
                    screenSize: { height: window.innerHeight-TOP_MENU_MARGIN, width: window.innerWidth } 
                  })

    // initiate photo selection once component is ready

    //const u = window.URL.createObjectURL(this.state.photo)

    // if an image is passed down via arguments, load it

    //this.readDefaultImage()
    //await this.buildTagUIFlat()
    

    //    const urlPayload = getUrlPayload()

    const {argUid, argImageUrl} = this.props

    if (argUid === null || !!!argImageUrl ) {

      console.log('argUid is null, reading default image, no arguments coming in via URL.. reading defaultImage and building UI..')
      console.log('tagModel:', this.props.tagModel)

      //this.setState({ loadingMsg: 'loading default image..' })
      await this.readDefaultImage()

      //this.setState({ loadingMsg: 'building UI..' })
      await this.buildTagUIFlat()

    } else {
      console.log('...argUid is not null:', argUid)

      //this.setState({ loadingMsg: 'loading image..' })
      await this.processNewUrlImage(this.props.argImageUrl, this.props.argUid) 

      /*
      if (!!argImageUrl && argImageUrl.length > 0 )  {

        console.log('tag2.. got incoming argImageUrl..:'+ argImageUrl+ ' with argUid:' +argUid)
        await this.readArgImage(argImageUrl, argUid)
  
        const argDataHashTable = this.argDataToHashTable()
        console.log('argDataHashTable componentDidUpdate:', argDataHashTable)
        this.setState({ argDataHashTable: argDataHashTable, uid: this.props.argUid })
      }  
      */    
    }

    this.setState({ loading: false, loadingMsg: 'loading finished..' })


    //if (!!this.props.selected && this.props.selected.length > 0) {
      // for click-away (collapse of extended choice list into dock)
//document.addEventListener('touchstart', this.handleClickOutside);
//document.addEventListener('mousedown', this.handleClickOutside);
    //}


    //this.imageToBase64(this.state.photo)
    
    //this.photoRef.click()


    /*
    // testing expansion of tagModel after delay of 6 secs:
    setTimeout( ()=> { 
        this.setState({ tagModel: [ this.getRandomModel(), ...this.state.tagModel ]  })
    }, 6000)
    */
  }     

  
/*
  getRandomModel() {
    return {
        "id": "temp",
        "parent": "main",
        "type": "group",
        "choices": [
            {
                "key": "t",
                "text": "KeyboardIcon",
                "color": "secondary"
            },
            {
                "key": "naritaevent",
                "text": "Narita Event"
            },
            {
                "key": "sakaiauction",
                "text": "Sakai Auction"
            },
            {
                "key": "sakailuckydraw",
                "text": "Sakai Lucky Draw"
            }
        ],
        "multiSelect": false,
        "docked": true,
        "dockedText": "Temp",
        "saveExif": true,
        "scrollHandler": true
      }
  } 
  */


  // convert flat argData to a hashTable (because this.state.data is based on hashtable)
  // argData, argUid only change once model is saved & loaded again => and passed in again via props
  // hasTable only includes tags that were SET by UI, no empty tagGroups or tagBlocks
  //
  argDataToHashTable() {

    const {argData, argUid} = this.props
    let hashTable = []

    if (argData === undefined || argData === null) {
      console.log('!!!no argData for argDataToHashTable, returning early.. ')
      return []
    }

    const iterator = Object.keys(argData)

    // iterate thru data hash-map
    for (const i of iterator) {  

      //console.log('hashTable argData[i]:',argData[i], ' i:', i )
      const id = argData[i].id
      //console.log('arg id:', id)
      //console.log('argData[i]:', argData[i])

      if (hashTable[id] === undefined)  {
        hashTable[id] = { choices: [], selected: [], values: []}
      } else {
        console.log('argData hashTable exists:', hashTable[id])       
      }

      hashTable[id].choices.push({ key:argData[i].key, text:argData[i].text })
      hashTable[id].selected.push(argData[i].key)
      hashTable[id].values.push({ key:argData[i].key, text:argData[i].text })

      if (!!hashTable[id].selected.length > 0) {
        hashTable[id].parent = argData[i].parent === argUid ? 'main' : argData[i].parent
      } 

    }

    //console.log('arg hashTable:', hashTable)
    return hashTable

  }

/////////////////////////



      
  startLongClickHandler = (e) => {

    const obj = { pos: {  x: e.clientX || e.touches[0].clientX,
                          y: e.clientY || e.touches[0].clientY },
                  id: e.target.innerText,
                  type: !!e.clientX ? 'mouse' : 'touch',
                  action: 'start'
                }    
                
    //e.preventDefault()

    //if (this.state.arrangeModeOn === false) { // && obj.id === this.props.dockedText) {

      console.log('starting long click:', obj) 

      this.longClickStartXY = obj.pos
      this.longClickStartTS = Date.now()

    //} 

  }




  stopLongClickHandler = (e) => {

      const obj = { pos: {  x: e.clientX || e.changedTouches[0].clientX,
                            y: e.clientY || e.changedTouches[0].clientY },
                    id: e.target.innerText,
                    type: !!e.clientX ? 'mouse' : 'touch',
                    action: 'end'
      }

      const longClickEndTS = Date.now()

        //if (this.props.dockedText === obj.id) {     // not working with tagGroup with actively selected tags, as these would show the tag value, not the tag key

        console.log('stop.. on e.target.id (e.target.innerText): ', obj.id)




        if ( (longClickEndTS-this.longClickStartTS) > 700) {
          console.log('stop.. longClick was long enough..')
          //alert('longClick was long enough..')

          const delta = getPositionDeltas(this.longClickStartXY, obj.pos, true)

          // if click / touch position is within 10 pixels on click/touch release, turn on deleteMode
          if (delta.dx < 10 && delta.dy < 10) {

              e.preventDefault()

              console.log('stop.. longClick close enough released.. xy delta small enough => turning on arrange mode')
            

              if (this.state.arrangeModeOn) {
                this.turnOffArrangeMode()
              } else {
                this.turnOnArrangeMode()
              }

              return

              //alert('close enough click release..')
            
          } else {
            console.log('NOT turning on arrange mode, xy delta was too big..')
          }
        }
        


        // from handleClickOutside...
        if (!this.state.confirmDialogOpen) {
          //event.preventDefault()  
          //this.clickedTS = longClickEndTS

          // dont set clickedTS if user was scrolling (especially horizontally..)
          const delta = getPositionDeltas(this.longClickStartXY, obj.pos, true)

          // if click / touch position is within 10 pixels on click/touch release, turn on deleteMode
          if (delta.dx < 10 && delta.dy < 10) {

            this.setState({ clickedTS: longClickEndTS })
          }
        }

  }


  turnOnArrangeMode = () => {

  this.setState({ arrangeModeOn: true /*, docked: false */ })
    console.log('ARRANGE MODE IS ON..')      

  }


  turnOffArrangeMode = () => {
    // set these for all of uiData: docked: true, autoSuggest: false, 

    this.setState({ arrangeModeOn: false })
    console.log('ARRANGE MODE IS OFF..')      

  }


/*
  dockAll = async () => {

    console.log('dockAll called..:', this.state.tagModel)
    // this is not working, because initially, most tagModel docked fields are set to true anyways,
    // the tagGroup component, subsequently looks to its own state.docked
    // ==> setting all tagModel .docked to true, creates no change in props for tagGroup!!!



    // tagModel currently comes in via .props, not .state.. 1!!!  ... changed this to this.state. for now (only for relevant UI rendering)
    const { tagModel } = this.state 

    if (tagModel === undefined || tagModel === undefined) {
      return
    }

    const allDockedModel = tagModel.map( item => {
      return { ...item, docked: true }
    })

    console.log('allDockedModel:', allDockedModel)

    this.setState({ tagModel: allDockedModel })
    await this.buildTagUIFlat()


  }
*/




  doConfirmDialog = async (item, handleDeleteCB) => {
    this.setState({ confirmDialogOpen: true })


    // find the actual label (text) of for this item
    //const choicesItem = this.state.choices.filter( i => {
    //  return i.key === item.key
    //} )


    // show confirm dialog..
    const result = await confirmService.show({
      title: item.text,
      //message:'Really delete ' + choicesItem[0].text + '??',
      icon: <DeleteIcon fontSize="large" />
    });
    if (result) {
      handleDeleteCB(item)
    }


    this.setState({ confirmDialogOpen: false })    
  }





  // reads s3 image passed in via url arguments (uid derived from url)
  readArgImage = async (argUrl=null, argUid) => {

    this.setState({ lastModifiedTS: null })
    // maybe need to reset other state varaible as well
    // e.g. textBlock..


    if (argUrl !== null && argUrl.length > 0) {
      console.log('readArgImage, got argUrl:', argUrl)

    } else {
      console.log('readArgImage, argUrl is null, can t read image into piexifUrlImageProcessor, returning early..')
      return
    }

    //const res = await urlImageProcessor({ photoUrl: this.state.photoUrl, canRef: this.canvasRef })
    const res = await piexifUrlImageProcessor({ 
      lastModifiedTS: null,
      photoUrl: argUrl, 
      canRef: this.canvasRef, 
      doStoreExif: this.doStoreExif,
      doUpdateFileTimeStamp: this.doUpdateFileTimeStamp,  // state.lastModifiedTS gets set here
      doUpdateGPS: this.doUpdateGPS 
    })


    //const uid = generateQRid({ prefix:'ik' })

    if (!!res && !!res.canVals) {
      //this.setState({ uid: argUid, image: res.image, canVals: res.canVals })



      //const lastModifiedTS = !!res.exifDateObj && !!res.exifDateObj.exifTimeStamp ? res.exifDateObj.exifTimeStamp : Date.now()
      //console.log('got NEW, CURRENT lastModifiedTS:', lastModifiedTS)

      //this.setState({ uid: argUid, image: res.image, canVals: res.canVals, lastModifiedTS, data:[], argDataHashTable:[] })  
      this.setState({ uid: argUid, image: res.image, canVals: res.canVals, data:[], argDataHashTable:[] })  

  /* these things are not getting set.. mostly because they are legacy values from first prototype 
      that wokred without servers, eg. with photos taken by camera... no load of args... etc

      this.setState({ uid, 
        lastModifiedTS, 
        mimeType: e.target.files[0].type, 
        photo: photo, 
        photoUrl: window.URL.createObjectURL(e.target.files[0]), 
        argDataHashTable:null,
        data: [] })
*/
    
    } else {
      console.log('NO CANVALS after readArgImage')
    }

  }


  readDefaultImage = async () => {

    console.log('reading default image and sending it to piexifUrlImageProcessor: ', this.state.photoUrl)
    this.setState({ lastModifiedTS: null })

    //const res = await urlImageProcessor({ photoUrl: this.state.photoUrl, canRef: this.canvasRef })
    const res = await piexifUrlImageProcessor({ 
      lastModifiedTS: null,
      photoUrl: this.state.photoUrl, 
      canRef: this.canvasRef, 
      doStoreExif: this.doStoreExif,
      doUpdateFileTimeStamp: this.doUpdateFileTimeStamp,    // state.lastModifiedTS gets set here
      doUpdateGPS: this.doUpdateGPS 
    })

    const uid = generateQRid({ prefix:'ik' })

    if (!!res && !!res.canVals) {

      console.log('got default image, setting uid to:', uid)

      //const lastModifiedTS = !!res.exifDateObj && !!res.exifDateObj.exifTimeStamp ? res.exifDateObj.exifTimeStamp : Date.now()
      //this.setState({ uid, image: res.image, canVals: res.canVals, lastModifiedTS })
      this.setState({ uid, image: res.image, canVals: res.canVals })

    } else {
      console.log('NO CANVALS after readDefaulImage')
    }

  }

    // if string length is without limit, set it to a high number, like 5000
    // otherwise it is used to decide if a newStr gets added to an existing line
    // or put seperately into a new line
    // addNewLines decides on how many blank lines to add after the string 
    addToTextBlock({ block=[], newStr='', startWithNewLine=false, startWithNewPara=false, seperator=' ', maxLength=5000, addNewLines=0 }) {
        

        if (newStr === '') {
          return block
        }

        let myBlock = block
        //const linesToAdd = addNewLines.map(i => [''])

        let linesToAdd = []
        for(var i=0; i < addNewLines; i++){
          linesToAdd = [...linesToAdd, ''] // + [''] //.push('')
        }        


                
        if (!!startWithNewLine) {
            //myBlock = [ ...block, 'newLineStartWithNewLine' ]
            myBlock = [ ...block, '' ]

            
        } else if (!!startWithNewPara) {
            // this does not work properly..
            console.log('startwithnew para.. inserts two line breaks')
            myBlock = [ ...block, '', '' ]
        }
        

        
        if (!!newStr && newStr.length > 0) {
            const newStrLen = newStr.length

            if (!!myBlock && myBlock.length > 0) {

                const blockLen = block.length
                const lastLine = block[blockLen-1]
                const lastLineLen = lastLine.length
                const newSeperator = lastLineLen > 0 ? seperator : ''

                // special case, where block only consists of one line
                // seperate out the lines that will not change anymore (which are fixed)
                const fixedBlock = myBlock < 2 ? [] : myBlock.slice(0, blockLen-1) 

                // adding new string to last line too long? put it into a new line
                if ( ((lastLineLen + newSeperator.length + newStrLen) > maxLength) || !!startWithNewLine) {

                    // need to add empty lines after the newStr? 
                    if (addNewLines > 0) {
                        //return [...myBlock, newStr, 'newLine_TooLong_AddNewLines']  // should iterate addNewLines here, now just adding one with ''
                        return [...myBlock, newStr, ...linesToAdd]  // should iterate addNewLines here, now just adding one with ''
                    } else {
                        return [...myBlock, newStr]
                    }

                // string fits into with last line of block    
                } else {
                    
                    // need to add empty lines after the newStr? 
                    if (addNewLines > 0) {
                        //return [...fixedBlock, (lastLine + newSeperator + newStr), 'newLine_ShortEnough_AddNewLines']  // should iterate addNewLines here, now just adding one with '' 
                        return [...fixedBlock, (lastLine + newSeperator + newStr), ...linesToAdd]  // should iterate addNewLines here, now just adding one with '' 
                    } else {
                        return [...fixedBlock, (lastLine + newSeperator + newStr)]
                    }

                }

                
            } else {
                if (addNewLines > 0) {
                    //return [newStr, 'newLineCauseNoBlock']  // should iterate addNewLines here, now just adding one with '' 
                    return [newStr, ...linesToAdd]  // should iterate addNewLines here, now just adding one with '' 
                } else {
                    return [newStr]
                }
            }

        } else {
            return block
        }
    }
/*
  formatTextBlock({ block=[], newStr='', seperator=' ', maxLength=0 }) {
      if (!!newStr && newStr.length > 0) {
          if (maxLength > 0)
          return [...block, newStr]
      }
  }
*/

///////////////////////////////////////////////////


  stateToText3 = () => {

    const { data, uid } = this.state

    //const keys = Object.keys(data)

    let paragraph = []
    let paragraph2 = []
    
    const maxLength= 30
    const dataMaxLen = 10   // special short length for important data
    const dataMaxLen2 = 30   // special short length for important data
 

/*
    if (!!!keys || keys.length === 0 || this.checkRequired(data)) {
      console.log('no data, returning early')
      return
    }


    const missing = this.checkRequired(data)
    if (missing && missing.length > 0) {
      console.log('highlight missing:', missing)
      return
    }
*/


    const size = !!data['size'] && data['size'].values[0] ? data['size'].values[0] : ''
    const sizePostfix = !!size ? size[size.length-1] === 'm' ? ' ' : 'cm' : ''  

    if (!!size && !!data['size'].created && !!data['size'].created.length > 0) {
      console.log('newly created size: ', data['size'].created)
    }    

    
    const birthYear = !!data['birthyear'] && data['birthyear'].values[0] ? data['birthyear'].values[0] : ''
    const birthMonth = !!data['birthmonth'] && data['birthmonth'].values[0] ? data['birthmonth'].values[0] : ''


    //const age = !!data['age'] && data['age'].values[0] ? data['age'].values[0] : ''
    //const agePostfix = !!age ? ['m','y','i'].indexOf(age[age.length-1]) > -1 ? ' ' : 'y' : '' 
    
    const gender = !!data['gender'] && data['gender'].values[0] ? data['gender'].values[0] : ''
    const breeder = !!data['breeder'] && data['breeder'].values[0] ? data['breeder'].values[0] : ''
    const soldAt = !!data['soldat'] && data['soldat'].values[0] ? data['soldat'].values[0] : ''

    const blood = !!data['blood'] && data['blood'].values[0] ? data['blood'].values[0] : ''

    const koiType = !!data['type'] && !!data['type'].values && data['type'].values[0] && data['type'].values[0] !== 'Wagoi' ? data['type'].values[0] : ''
    const koiPattern = !!data['pattern'] && !!data['pattern'].values ? data['pattern'].values.join(' ') : ''
    const koiColor = !!data['color'] && !!data['color'].values ? data['color'].values.join(' ') : ''
    const koiVariety = !!data['variety'] && !!data['variety'].values ? data['variety'].values[0] : ''

    const ratingBody = !!data['rbody'] && !!data['rbody'].values ? parseInt(data['rbody'].values[0])  : 0
    const ratingSkin = !!data['rskin'] && !!data['rskin'].values ? parseInt(data['rskin'].values[0]) : 0
    const ratingPattern = !!data['rpattern'] && !!data['rpattern'].values ? parseInt(data['rpattern'].values[0]) : 0
    const ratingFinishing = !!data['rfinishing'] && !!data['rfinishing'].values ? parseInt(data['rfinishing'].values[0]) : 0

    // pattern information is not really necessary to have on the photo, but good for searching
    // the exception is 'tancho' 
//    const tanchoPattern = koiPattern.indexOf('Tancho') > -1 ? koiPattern : ''

    //const tanchoPattern = koiPattern.indexOf('Tancho') > -1 ? 'Tancho' : ''
    

    if (!!!this.state.lastModifiedTS) {
      console.log('lastModifiedTS not set... using Date.now().')
      this.setState({ lastModifiedTS: Date.now() })

    }
    
    console.log('lastModifiedTS:', this.state.lastModifiedTS)
    
    //const encBreeder = statcan(data['breeder'].values[0])
    //const encVariety = statcan(data['variety'].values[0])

    //const encDateStr = this.convertDateString(this.state.lastModified)

    //const line5 = !!this.state.lastModified ? encBreeder + this.convertDateString(this.state.lastModified) + encVariety + this.convertTimeString(this.state.lastModified) : 'no date'
    
    //const line5 = !!this.state.lastModified ? encBreeder + this.convertDateString(this.state.lastModified) + '.' + this.convertTimeString(this.state.lastModified) : 'no date'
    
    //const koiUniqueCode = !!this.state.lastModified ? encBreeder + this.convertDateString(this.state.lastModifiedRaw) + 'y' + this.convertTimeString(this.state.lastModifiedRaw) : 'no date'
     // !!this.state.lastModified ? encBreeder + this.convertDateString(this.state.lastModifiedRaw) + 'y' + this.convertTimeString(this.state.lastModifiedRaw) : 'no date'

    //const line5 = !!this.state.lastModified ? encBreeder + '.' + this.encodeDateToLetters(this.state.lastModified) + '.78' : 'no date'

    
    if (soldAt.length > 0) {
      paragraph = this.addToTextBlock({ block:paragraph, newStr:breeder, addNewLines:1, maxLength:maxLength, seperator:' ' })
      paragraph = this.addToTextBlock({ block:paragraph, newStr:soldAt, addNewLines:2, maxLength:maxLength, seperator:' ' })
    } else {
      paragraph = this.addToTextBlock({ block:paragraph, newStr:breeder, addNewLines:2, maxLength:maxLength, seperator:' ' })
    }


    paragraph = this.addToTextBlock({ block:paragraph, newStr:size+sizePostfix, addNewLines:1 , maxLength:dataMaxLen, seperator:' ' })
    paragraph = this.addToTextBlock({ block:paragraph, newStr:gender, addNewLines:1 , maxLength:dataMaxLen, seperator:' ' })
    //paragraph = this.addToTextBlock({ block:paragraph, newStr:age+agePostfix, addNewLines:2 , maxLength:dataMaxLen, seperator:' ' })
    if (birthMonth.length > 0) {
      paragraph = this.addToTextBlock({ block:paragraph, newStr:birthYear, addNewLines:0 , maxLength:dataMaxLen, seperator:' ' })
      paragraph = this.addToTextBlock({ block:paragraph, newStr:birthMonth, addNewLines:2 , maxLength:dataMaxLen, seperator:'.' })
  
    } else {
      paragraph = this.addToTextBlock({ block:paragraph, newStr:birthYear, addNewLines:2 , maxLength:dataMaxLen, seperator:' ' })
    }   


    paragraph = this.addToTextBlock({ block:paragraph, newStr:blood, addNewLines:2 , maxLength, seperator:' ' })
    //paragraph = [...paragraph, '']
    

    paragraph2 = this.addToTextBlock({ block:paragraph2, newStr:koiType, addNewLines:0, maxLength:dataMaxLen2, seperator:' ' })

    //paragraph2 = this.addToTextBlock({ block:paragraph2, newStr:tanchoPattern, addNewLines:1 , maxLength:dataMaxLen2, seperator:' ' })
    paragraph2 = this.addToTextBlock({ block:paragraph2, newStr:koiPattern, addNewLines:0 , maxLength:dataMaxLen2, seperator:' ' })
    paragraph2 = this.addToTextBlock({ block:paragraph2, newStr:koiColor, addNewLines:0 , maxLength:dataMaxLen2, seperator:' ' })
    paragraph2 = this.addToTextBlock({ block:paragraph2, newStr:koiVariety, addNewLines:1 , maxLength:dataMaxLen2, seperator:' ' })


    //const line5 = !!this.state.lastModified ? encBreeder + this.convertDateString(this.state.lastModified) + '.153' : 'no date'
    //paragraph2 = this.addToTextBlock({ block:paragraph2, newStr:koiUniqueCode, addNewLines:0 , maxLength: maxLength, seperator:' ' })

    //shortid.characters('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$@');
    const dateTimeObj = toDateTimeObj(this.state.lastModifiedTS || Date.now())
    paragraph2 = this.addToTextBlock({ block:paragraph2, newStr:dateTimeObj.dateOnly + "   " + dateTimeObj.timeOnly, addNewLines:1 , maxLength:maxLength, seperator:' ' })


    paragraph.forEach( (item, idx) => {
        console.log('paragaraph line' + idx + ':', item)
    })

    return { block1: paragraph, block2: paragraph2, uid, ratings: { ratingBody, ratingSkin, ratingPattern, ratingFinishing }  } 


  }



    // used when new values are created, when values are not found
    // and when values are set in state..
    setDataItemInState = (props) => {

      const { key=null, dbKey=null, values=null, selected=null, parent=null,
        deleted=null, dockedText= null, dockedSymbol=null, created=null, saveExif=null } = props

      this.setState( 
        state => ({ 
          data: { 
                  ...state.data, 
                  [key]: {
                            values: values, 
                            selected: selected,
                            created: created,
                            deleted: deleted, // newly added delete functionality, still not fleshed out
                            
                            key: key,  // not react component key (this is likely the same as "docked" text)
                            dockedText: dockedText, 
                            dockedSymbol: dockedSymbol,
                            saveExif: saveExif,
                            dbKey: dbKey,
                            parent: parent
                          }

                },
                //lastModifiedTS: Date.now() 
        })
      )      
    }
    
    // CB called when the TagSettings TagGroup is selected (edge case that is used to EDIT / CREATE nodes in the tagModel)
    onTagSettings = (props) => {

      console.log('## onTagSelect key:', props.key, ' values:', props.values, ' selected:', props.selected, ' created:', props.created)

      
      if (props.values && props.values.length > 0) {
        
      // NodeSettings got a created item!!
      if (props.dbKey === 'NodeSettings' && props.created && props.created[0]) {
        console.log('onTagSelect, called with item CREATED for NodeSettings:', props.dbKey, props.created[0])
        
        // figure out WHERE to insert the new node into the tagGroup, and if its a GROUP or BLOCK
        

        return
      }

      // check if created item is a newly created node for the tagModel..
      if (props.dbKey === 'NodeSettings') {
        console.log('onTagSelect, called with item NodeSettings (=EDIT) item:', props.dbKey, props.key)
        
        // call EDIT function for the selected node..
        return
      }

      }    
    }




    onTagSelect = (props) => {

      console.log('## onTagSelect key:', props.key, ' values:', props.values, ' selected:', props.selected, ' created:', props.created)

      
      if (props.values && props.values.length > 0) {
        //this.textToCanvas({ x:300, y:55, myText: values.join(' '), font:"30px Arial" }) 
        //this.saveCanvas(key + "_" + selected[0] + '.jpg') 
      }

      // store feedback data into this.state.data hashmap
                  

      // got a newly created tag
      if (props.created !== null && !!props.created[0] && props.created[0] !== [] ) { // && (this.state.data[key] === undefined || this.state.data[key].created[0].key !== created[0].key) ) {

        console.log('### onTagSelect created condition ok created:', props.created)

        // WRONG ASSUMPTION. INCOMING IS THE WHOLE OF DATA OF E.G. "BREEDER"

        const foundPos = this.props.tagModel.find(item => item.id === props.dbKey)       

        //console.log('onTagSelect argDataHashTable[props.key]:', this.state.argDataHashTable[props.key])
        //console.log('onTagSelect this.state.data[props.key]:', this.state.data[props.key])


        if (!foundPos) {
          console.log('### foundPos not found, need to create new..')


        }

        //this.props.saveTagModel({ tagModel:newTagModel, fileName:'tagModel.json', cacheKey:'tagModel' })
      }


      this.setDataItemInState(props)


      console.log('this.state.data:', this.state.data)
      
      

    }
/*
        this.setState( 
          state => ({ docked: true, 
                      autoSuggest: false,
                      selected: [ ...state.selected, item.toLowerCase() ], 
                      choices: [ state.choices[0], addItem, ...state.choices.slice(1) ],
                      created: [ addItem, ...state.created ],

                      //choices: [ addItem, ...state.choices ]
          })
        )  
*/

    // used to upate state exif date from within the photo processor
    doStoreExif = (exifData=null) => {
      this.setState({ exifData })
    }

    doUpdateGPS = (lat, lng) => {
      this.setState({ gpsLat: lat, gpsLng: lng })
    }    


    // does not write the updated timestamp into EXIF !!
    // time stamp of uploaded files need to put into lastModifiedTS before this operation..
    // or it will be older than Date.now() and never set..
    // 
    // used to update photo modification time state date from within the photo processor, if exif date/time is present 
    doUpdateFileTimeStamp = ({ exifDate=null, exifTimeZone=null, exifTimeStamp=null }) => {

      console.log('file date:', this.state.lastModifiedTS, ', exif date:', exifTimeStamp)
      //console.log('file Time:', this.state.lastModifiedTime)

      //console.log('file TS:', this.state.lastModifiedRaw)
      //console.log('exif TS:', exifTimeStamp)

      // returns: Tuesday, December 16, 2017 11:09:42
      const {lastModifiedTS} = this.state

  
      if (!!exifTimeStamp) {
        if  (this.state.lastModifiedTS === undefined 
              || this.state.lastModifiedTS === null 
              || this.state.lastModifiedTS === 0
              || exifTimeStamp > lastModifiedTS ) {    // appbles and beans
        
            console.log('exifTimeStamp present, lastModifiedTS not present or older than exifTimeStamp:  updating lastModifiedTS in state..')
            //actually on the lastModifiedRaw (timestamp) is used for writing on the image
            //lastModified is just the date (without the time, but not really used..)

            // need to seperate time out of exifDate
            // need to seperate date only out of exifDate
            this.setState({ lastModifiedTS: exifTimeStamp })
          
        } else {
          console.log('!! exifTimeStamp was present, but lastModifiedTS was newer, so nothing got updated:', exifTimeStamp, this.state.lastModifiedTS, this.state.lastModifiedRaw)
        }


      // no exifTimeStamp..
      } else {

        if  (this.state.lastModifiedTS === undefined 
          || this.state.lastModifiedTS === null 
          || this.state.lastModifiedTS === 0) {

            console.log('lastModifiedTS not present, got no exifTimeStamp, would be ..setting now() as lastModifiedTS in state..')
            //this.setState({ lastModifiedTS: Date.now() })
        } else {
            console.log('lastModifiedTS present, got no exifTimeStamp, no changes made...')
        }

      }
    }

  


    // uploads original image, thumbnail to S3  (queick and dirty.. will do all that in SAGA in a bit)
    // fileName = the filename of the image chosen by the user (not the filename to be set at upload)
    // image = canvas of image
    // uid = QRid 
    // fileNamePrefix = folder where to put the image, trailed by /
    // mimeType = mimetype of uploaded file, not necessary for jpg or png

    uploadImage = async ({ image, mimeType=[], fileName, fileNamePrefix="orig/", uid }) => {



      let upMimeType = mimeType  // png is standard, no need for writing it out

      if (fileName.toLowerCase().endsWith('.png')) {
        upMimeType = ["image/png"]
      } 
      if (fileName.toLowerCase().endsWith('.jpg') || fileName.toLowerCase().endsWith('.jpeg')) {
        upMimeType = ["image/jpeg", 0.9]
      }  
      if (fileName.toLowerCase().endsWith('.mp4') || fileName.toLowerCase().endsWith('.mpeg4')) {
        upMimeType = ["video/mp4"]
      }  
      //const newJpgStr = can.toDataURL(...upMimeType)


/*
    canRef.toBlob(function(blob) {
        saveAs(blob, fileName);
    }, ...mimeType);      
    
    canvas.toBlob(function(blob) {
        saveAs(blob, fileName);
    }, ...mimeType);  
  
    //}, 'image/jpeg', 0.9);  

*/

      const uploadFileName = fileNamePrefix + uid

      try {  

        // WORKS, THE NEW STANDARD WAY TO DO THIS...  
        // this actually also takes some time, maybe indicate it in the uploading progress...?
        // ALSO, this may be problematic with VIDEO !!!!!

        // !!! not sure this will work with canvas passed in via image...
        // SLOW, 10 sec for 4mb
        const blob = new Buffer(image.replace(/^data:image\/\w+;base64,/, ""),'base64')



        /*
        const res = this.uploadPayload({ 
          payload: blob, 
          contentType: this.props.mimeType, 
          url:"https://m.koitag.com/upload/koitag/"+fileName 
        })
        */

        this.setState({ isUploadingOrig: true })

        const that = this


        // UPLOAD TO S3

        //const res = await Storage.put(fileName, blob, {        
        const res = await Storage.put(uploadFileName, blob, {    // BETTER PUT IT IN A PROPER FOLDER
          //level: 'public',
          level: 'protected',
          //contentType: mimeType, //'image/jpeg', //mimeType,
          contentType: upMimeType.join(','),
          progressCallback(progress) {  
            console.log(`uploadImage Uploaded: ${progress.loaded}/${progress.total}`);

            that.setState({ uploadProgressOrig: Math.floor(progress.loaded * 100 / progress.total) })
          }
        })
        

        console.log('uploadImage uploaded ok, res:', res)

        this.setState({ isUploadingOrig: false, uploadProgressOrig: 0 })


      } catch(err) {

        console.log('uploadImage err:', err)
        this.setState({ isUploading: false, progress: 0 })

        alert(err); // TypeError: failed to fetch
      }      
     
    }





    // on photo change (take new photo or choose existing one), run image processor for scaling and exif rotation
    handlePhotoChange = async (e=null) => {


      //console.log('photoRef:',this.photoRef)
      //console.log('photoRef.value:', this.photoRef.value)
      //console.log('photo window.URL.createObjectURL', window.URL.createObjectURL(e.target.files[0]))

      if (e===null) {
        return
      }

      // to get timestamp of last modified:
      // Date.parse(document.lastModified)

      const photo = e.target.files[0];
      console.log('MIME TYPE FROM FILE:', e.target.files[0].type)

      const lastModifiedTS = !!photo && !!photo.lastModified && photo.lastModified > 0 
                                ? new Date(photo.lastModified ) : 0 //new Date(file.lastModified()); Date.parse(photo.lastModified)
      //const lastModifiedObj = toDateTimeObj(lastModifiedTS)

      //const lastModifiedRaw = photo.lastModified  // // something like: Tuesday, December 16, 2017 11:09:42
      //const lastModifiedTime =  new Date(photo.lastModified).toISOString()
      //const lastModified = new Date(photo.lastModified).toISOString().slice(0,10) // just a date string 1980.3.12 (without time)

      
      // !!!! SEE IF EXIF DATE IS OLDER - USE EXIF DATA IF THIS IS THE CASE 
      // convert to date here..


      //console.log('photo:', photo)
      //console.log('lastModifiedTime:', lastModifiedTime)
      //console.log('lastModifiedRaw:', lastModifiedRaw)

      const uid = generateQRid({ prefix:'ik' })

      this.setState({ uid, 
                      lastModifiedTS, 
                      mimeType: e.target.files[0].type, 
                      photo: photo, 
                      photoUrl: window.URL.createObjectURL(e.target.files[0]), 
                      argDataHashTable:null,
                      data: [] })

      console.log('mime type:', e.target.files[0].type)

                      //await this.buildTagUIFlat()  
                      
                      
      //this.imageToBase64(e.target.files[0]);

      //await this.imageProcessor(e.target.files[0]);
      //const res = await imageProcessor({ target: e.target.files[0], canRef: this.canvasRef })
      const res = await piexifImageProcessor({ 
        lastModifiedTS: lastModifiedTS,
        target: e.target.files[0], 
        canRef: this.canvasRef, 
        doStoreExif: this.doStoreExif,
        doUpdateFileTimeStamp: this.doUpdateFileTimeStamp,  // state.lastModifiedTS gets set here
        doUpdateGPS: this.doUpdateGPS
      })


      if (!!res && !!res.canVals) {
        console.log('new this.state.canVals: ', res.canVals)
        this.setState({ image: res.image, canVals: res.canVals })


        console.log('about to upload orginal file to S3..')
        const fileName = photo.name //e.target.files[0]  // photo.name
        
        // i could pass in that mime type into uploadImage
        //console.log('MIME TYPE FROM FILE:', e.target.files[0].type)

        const upOrigRes = await this.uploadImage({ image: res.image, fileName, fileNamePrefix:"orig/", uid })
        console.log('uploaded original file to S3:', upOrigRes)


      } else {
        console.log('NO CANVALS after handlePhotoChange')
      }      
     
      
      
    }


    // scroll handler for horizontal and vertical scrolling of tags
    scrollHandler = (ref, args={block: 'start', behavior: 'smooth'}) => {

      if (!!ref) { 
        
        const vhCheckObj = vhCheck()
        // -10 so we can see the login button above
        const TOP_MENU_MARGIN = vhCheckObj.offset > 0 ? vhCheckObj.offset-20 : 10  //Math.abs(50+vhCheckObj.offset) //vhCheckObj.offset
        
        // only set new screenSize if its different from current one (e.g. user interacts with screen and address bar gets removed or shows..)
        if (this.state.screenSize.height !== window.innerHeight-TOP_MENU_MARGIN) {
          console.log('resetting screenSize due to user interaction..')

          this.setState({ screenSize: { height: window.innerHeight-TOP_MENU_MARGIN, width: window.innerWidth } })
        }

        // may work with ios / chrome as an alternative, but not encouraged:
        // element.scrollIntoViewIfNeeded(true)
    

        if (!!ref.current) {
            //console.log('tags scrollHandler called with ref (.current):', ref)
            ref.current.scrollIntoView(args);
        
        } else {
            //console.log('tags scrollHandler called with ref (without .current):', ref)
    
            // somewhat wobbly performance
            if (ref.scrollIntoView !== undefined) {
              ref.scrollIntoView(args);
            }
          
        }
    
      } else {  
    
        console.log('tags scrollHandler ref is null:', ref)
      }
    
    }

    
    // triggers upload dialog
    doUploadDialog() {

      //this.mergeAndSaveCanvas()

      this.setState({ saveDialogOpen: true })
    } 


/*
    updateModelItem(tagModel=null, id=null, newItem) {
      if (tagModel !== null && id !== null) {
        const item = tagModel.find(item => item.id === id)
        if (item !== null) {

        }
      }
    }
*/

    // triggers upload dialog
    doBrowseS3dialog() {

      //this.mergeAndSaveCanvas()

      this.setState({ browseS3dialogOpen: true })
    }     

    // creates a list of all tagModel nodes (tagGroups and tagBlocks)
    // used when creating new nodes
    // or when searching for a node (e.g. selecting predecessor in node-list, so that node shows up in right position)
    createTagModelChoiceList({keyboard=false}) {
      const {tagModel} = this.state

      const choices = tagModel.map( item => {

        if (!!!item) {
          console.warn('Empty tagModel..')
          return {}
        }

        const prefix = item.parent === 'main' ? '/' : item.parent + '/'

        if (item.type === 'group') {
          return { key: item.id, text: prefix + item.dockedText, fn: () => { console.log('fn:', item.id) }  }
        }

        if (item.type === 'block') {
          return { key: item.id, text: prefix + item.label, color: "secondary", fn: () => { console.log('fn:', item.id) }  }
        }        

      }) || null

      // add keyboard / input / auto complete field, if createNew is allowed..
      // what if somebody only wants to search the list...?
      // supply the "createNew" prop with tagGroup (where this choices list will be used..)
      if (choices && keyboard) {
        return [
          {
            "key": "t",
            "text": "KeyboardIcon",
            "color": "secondary"
          }, 
          ...choices 
        ]     
      }

      return choices  
    }  



    // counting selected tags of a certain parent (block)
    // confusing: argHash, does not have BLOCKS, only GROUPS data
    hasChildrenWithTags({parent='main'}) {

      const argHash = this.state.argDataHashTable   // hashTable of selected data, not complete tagModel
      const {tagModel} = this.state   // was this.props before

      let childTagCount = 0
      if (!!argHash && !!parent && !!tagModel) {
          
          Object.values(tagModel).forEach( item => { 
            if (item.parent === parent) {

              if (item.type === 'group') {
                if (!!argHash[item.id] && !!argHash[item.id].selected && argHash[item.id].selected.length > 0) {
                  childTagCount += argHash[item.id].selected.length 
                }

              } else if (item.type === 'block') {
                //console.log(childTagCount + ' before calling recursively with parent:', item.id)
                childTagCount += this.hasChildrenWithTags({parent:item.id})
              }
            }
  
          })      
      } 
      return childTagCount  
    }


    // merges hashTable with model and renders UI based on values
    //
    // rendering of flat tagModel (newer version) generates function for TagGroup / TagBlock 
    // tagModel initially comes from props (loaded from tagModel.json by parent)
    // argHash is rendered on Update (hashtable version or tagModel)
    //    hash table is created when new argImage is passed in
    
    buildTagUIFlat = (tagModel, argHash=null, parent='main') => {

      if (tagModel === undefined || tagModel === null) {
        return
      }

      const children = tagModel.filter(item => parent !== null && item.parent === parent)
      //const modelChildrenWithActiveTag = tagModel.filter(item => parent !== null && item.parent === parent && !!item.selected && item.selected.length > 0)

      const rendered = children.map( item => {

          //console.log('arg data tagModel item:', item)
          //console.log('arg data tagHash item:', argHash && argHash[item.id] ? argHash[item.id].choices : 'undefined')

          const argChoices = !!argHash && !!argHash[item.id] && !!argHash[item.id].choices && !!argHash[item.id].choices.length > 0 ? argHash[item.id].choices : []
          const argSelected = !!argHash && !!argHash[item.id] && !!argHash[item.id].selected && !!argHash[item.id].selected.length > 0 ? argHash[item.id].selected : []
          
          //console.log('argChoices:', argChoices, ' argSelected:', argSelected)

          const modelSelected = !!item.selected ? item.selected : []
          const modelChoices = !!item.choices ? item.choices : []

          //if (!!argSelected && argSelected.length > 0) {
            /*
            console.log('item:', item)
            console.log('argSelected:', argSelected)
            console.log('modelSelected:', modelSelected)
            console.log('argChoices:', argChoices)
            console.log('modelChoices:', modelChoices)
*/
            //console.log('argSelected [...modelSelected, ...argSelected]:', !!modelSelected ? [...modelSelected, ...argSelected] : [])
            //console.log('argChoices [...modelChoices, ...argChoices]:', !!modelChoices ? [...modelChoices, ...argChoices] : [])
            //console.log('item.id:'+item.id+' argSelected obj parent (argHash):', argHash.parent, ' item.parent:', !!item.parent ? item.parent : null)
          //}
  

          // [...modelSelected, ...argSelected]    will create duplicates if the field is pre-selcted by the model..

          // model allows to specify empty lines before a field.
          //const spaceBefore = !!item.spaceBefore? '<Typography component="div" style={{ height:"0.5em" }} />' : ''




          // very inefficient to merge arrays on every render... should be done in componentDidUpdate...

          if (item.type === 'group') {
            return   (<span key={'sp-' + item.parent + '-' + item.id}>{!!item.spaceBefore? <br/> : ''}
              <TagGroup 
                key={ 'tg-' + item.parent + '-' + item.id }
                parent={!!item.parent ? item.parent : null }
                selected={ modelSelected.length > 0 || argSelected.length > 0 ? mergeSimpleArrays(modelSelected, argSelected) : [] }
                choices={ modelChoices.length > 0 || argChoices.length > 0 ? mergeObjectArraysSpecial(modelChoices, argChoices, 'key', false, 't') : []} 
                avatar={!!item.avatar ? item.avatar : null }
                multiSelect={!!item.multiSelect ? item.multiSelect : false }
                multiSelectMaxN={!!item.multiSelectMaxN ? item.multiSelectMaxN : null }
                docked={!!item.docked ? item.docked : false }
                dbKey={!!item.id ? item.id : null }
                dockedText={!!item.dockedText ? item.dockedText : null }
                dockedSymbol={!!item.dockedSymbol ? item.dockedSymbol : null }
                showLabelExpanded={!!item.showLabelExpanded ? item.showLabelExpanded : null }
                keyboardIcon={<KeyboardIcon />}
                format={!!item.format ? item.format : null } 

                arrangeModeOn={ this.state.arrangeModeOn }
                //onTagDelete={ this.onTagDelete }
                doConfirmDialog={ this.doConfirmDialog }
                clickedTS={this.state.clickedTS}

                //use this to not show a history of recently entered tags (especially for price)
                //noHistory={!!item.noHistory ? item.noHistory : null } 
                

                createNew={!!item.createNew ? item.createNew : false } 
                saveExif={!!item.saveExif ? item.saveExif : false } 
                scrollHandler={!!item.scrollHandler ? this.scrollHandler : null }
                onTagSelect={this.onTagSelect} />
              </span>)
  
          } else if (item.type === 'block') {
           
              
            const BlockContents = this.buildTagUIFlat(tagModel, argHash, item.id)
            //console.log('tagModel, after recursive call, result:', BlockContents)
       
            // count children with active (selected) tags
            const selectedChildTags = this.hasChildrenWithTags({parent: item.id}) 
              /*
            const label = !!item.label 
                            ? selectedChildTags > 0 
                              ? item.label + ' (' + selectedChildTags + ')'
                              : item.label
                            : null
             */               
            const label = !!item.label ? item.label : null
  
            return   (<span key={ 'sp-' + item.parent + '-' + item.id }>{!!item.spaceBefore? <br/> : ''}
              <TagBlock 
                key={ 'tb-' + item.parent + '-' + item.id }
                label={ label }
                dbKey={!!item.id ? item.id : null }
                parent={!!item.parent ? item.parent : null }
                initialOpen={!!item.initialOpen ? item.initialOpen : false }
                avatarSrc={!!item.avatarSrc ? item.avatarSrc : '' }
                avatar={!!item.avatar ? item.avatar : '' }
                activeChildTags={!!selectedChildTags ? selectedChildTags : 0 }
                scrollHandler={!!item.scrollHandler ? this.scrollHandler : null } >
                  {BlockContents}
                </TagBlock>
              </span>)
  
          } else {
  
            return null
          }
      })
      
      // allow to add new nodes to tagModel..
      if (parent === 'main') {

        return [

          ...rendered,

          <span key={'sp-NODE-SETTINGS'}>
          <TagGroup 
            key={ 'tg-NODE-SETTINGS' }
            parent={ null }
            selected={ [] }
            choices={ this.createTagModelChoiceList({ keyboard: true }) || []} 
            multiSelect={ false }
            multiSelectMaxN={ null }
            docked={ true }
            dbKey={ 'NodeSettings' }
            backgroundColor={'#F5450F'}
            color="#000000"
            dockedText={ <Avatar><SettingsIcon color='#F5450F' /></Avatar> }
//            dockedSymbol={ <SettingsIcon /> }
            showLabelExpanded={ false }
            keyboardIcon={<KeyboardIcon />}
            format={ null } 

            arrangeModeOn={ false } // { this.state.arrangeModeOn }
            //onTagDelete={ this.onTagDelete }
            doConfirmDialog={ this.doConfirmDialog }
            actionTrigger={ true }
            clickedTS={this.state.clickedTS}

            //use this to not show a history of recently entered tags (especially for price)
            //noHistory={!!item.noHistory ? item.noHistory : null } 
            

            createNew={ true } 
            saveExif={ false } 
            scrollHandler={ this.scrollHandler }
            onTagSelect={this.onTagSettings}    // tagSettings execute a CB that adds / edits nodes in the tagModel..
          />
          </span>         

        ]

      }

      return rendered
    
      
    }

    // might not work with some older browsers..
    copyToClipboard(text)  {
      var textField = document.createElement('textarea')
      textField.innerText = text
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
    }


    // share image via WebShare API.. only in some mobile browsers..
    shareImage = () => {

      const textBlock = this.stateToText3()

      //const baseUrl = window.location.protocol + '//' + window.window.location.host

      // using this hard coded url to test prototype (only for user Santa, API gateway, only can access this users' images.. as they are all seperated in own user directories..)
      const baseUrl = 'https://m.koitag.com'

      
      const url = !!this.state.uid ? baseUrl + '/' + this.state.uid : baseUrl

      const title = textBlock.block1.filter(item=> item.length > 1).join(', ')
      const text = textBlock.block2[0]
      const comboText = title + ', ' + text

      if (navigator !== undefined && navigator.share !== undefined) {
        navigator.share({
          title,              // title is not getting picked up by APIs...
          text: comboText,
          url
        })
        .then(() => {
            console.log('KoiTag share completed successfuly')
        })
        .catch((error) => { 
          console.log(`KoiTag share for ${url} failed: ${error}`) 
          alert(`KoiTag share for ${url} failed: ${error}`)
        });   
      } else {
        this.copyToClipboard(`${comboText}: \n${url}`)
        //console.log(`Your browser does not support sharing..try Mobile Chrome or Safari!\n\n${comboText}:\n${url}`)
        alert(`Your browser does not support sharing..try Mobile Chrome or Safari!\n\n${comboText}:\n${url}\n\ncopied to clipboard!`)

      }
         
    }



    ///////////////////////////////////////////////////

    render() {

  

      const {classes } = this.props
      const { tagModel } = this.state

      const innerHeight = window.innerHeight



      //console.log('innerHeight:', innerHeight)
      //console.log('## photo.name:', this.state.photo.name)

/*
          <div style={{ 
            //backgroundImage: `url(./koi6.jpg)`,
            //backgroundImage: `url(${this.state.photo.name})`,
            backgroundImage: `url(${this.state.photoUrl})`,
            //backgroundImage: `${this.state.photo.name}`,

            backgroundSize: 'cover',note taking apps 
            height: window.innerHeight,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center', // Center the image 
            //minHeight: window.innerHeight,
          }}>

*/

      const {screenSize, canVals=1 } = this.state
      const screenRatio = screenSize.width / canVals.canvasW // canVals.canW should be canVals.canvasW, but if i use that, the image gets distorted..
      const canH = canVals.canvasH * screenRatio || 0 //screenSize.height * screenRatio
      const canW = canVals.canvasW * screenRatio || 0
/*
      console.log('screenRatio:', screenRatio)

      console.log('canVals.canvasW:', canVals.canvasW)
      console.log('screenSize.width:', screenSize.width)
      console.log('canW:', canW)
      console.log('canH:', canH)
*/
      //const TagFunctions = tagModel !== null ? this.buildTagUI(tagModel.default) : <Typography component="div">loading tagModel..</Typography>
      const TagFunctions = (tagModel !== null || (tagModel !== null && this.state.argDataHashTable !== [])) ? this.buildTagUIFlat(this.state.tagModel, this.state.argDataHashTable) : <Typography component="div">loading tagModel..</Typography>



      //const TagFunctions = this.buildTagUI() || 'placeholder'


/*

            <canvas ref={ ref => this.mergeCanvas = ref } style={{  display: "none" }} />


            <canvas ref={ ref => this.canvasRef = ref } style={{  position: 'absolute', top:0, left: 0, width: this.state.screenSize.width, maxWidth: this.state.screenSize.width, height: canH }} />
  
            
            <canvas ref={ ref => this.textCanRef = ref } style={{  position: 'absolute', top:0, left: 0, width: this.state.screenSize.width, maxWidth: this.state.screenSize.width, height: canH }} />

*/


// zIndex: 1000 for TagGroup typography

      
/*
              { <span> loading data..<br/></span> && this.state.argDataHashTable === [] }
              { <span> loading picture..<br/></span> && this.state.pic === [] }

*/

// calling SaveImgDialog    with .jpg     ==>   error with files of other format !!!!!


// added top:0, left: 0  to css to try and fix safari scrolling issue..
      return (
        <span>


          <Typography
            component="div"
            variant="body1"
            style={{ height: this.state.screenSize.height, width: this.state.screenSize.width, position: 'relative' }}
          >



            <canvas ref={ ref => this.canvasRef = ref } style={{  position: 'absolute', top:0, left: 0, width: this.state.screenSize.width, maxWidth: this.state.screenSize.width, height: canH }} />
  


            <BrowseS3dialog          
              isOpen={this.state.browseS3dialogOpen}
              handleClose={() => this.setState({ browseS3dialogOpen: false })}
              uidPrefixFiller="/"
              classes={classes}              
            />

            <SaveImgDialog 
              isOpen={this.state.saveDialogOpen}
              handleClose={() => this.setState({ saveDialogOpen: false })}
              photoCanRef={this.canvasRef}
              fileName={this.state.uid? this.state.uid+'.jpg' : 'canvas.jpg'}
              uid={this.state.uid}
              textBlock={this.stateToText3}
              canVals={this.state.canVals}
              pic={ this.state.image }
              mimeType={ this.state.mimeType }
              oldExifData={ this.state.exifData }
              uiData={ this.state.data }
              timeStamp={this.state.lastModifiedTS}
              tagModel={this.props.tagModel}
            />


            <Typography           
              component="div"
              style={{ position: 'absolute', overflowY: 'auto', top: 10, left: 0, bottom: 10, width: this.state.screenSize.width, maxHeight: canH-10-10 }}             
            
                 
              onTouchStart={ this.startLongClickHandler } 
              //onMouseDown={ this.startLongClickHandler }

              onTouchEnd={ this.stopLongClickHandler }
              //onMouseUp={ this.stopLongClickHandler }

            >

              { !!this.state.loading && <CircularProgress style={{ color: "yellow", position: 'absolute', align: 'center', top: window.outerHeight/2-80, left: this.state.screenSize.width/2-15 }} /> }
              { /* !!this.state.loading && this.state.loadingMsg */ }   

              {TagFunctions}



            </Typography>



        </Typography>
   

        
        <Typography
          component="div"
          variant="body1"
          style={{ position: 'fixed', bottom: 5, right: 5 }}
        >

          <Fab color="secondary" aria-label="Add" className={classes.fab} component='label'>
            <AddAPhotoIcon />
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={this.handlePhotoChange}
              ref={ ref => this.photoRef = ref }
            />                
          </Fab>

          <Fab color="secondary" aria-label="go" className={classes.fab} onClick={() => this.doUploadDialog()}>
            GO
          </Fab>  
          
          <Fab color="secondary" aria-label="search" className={classes.fab} onClick={() => this.doUploadDialog()}>
            <SearchIcon />
          </Fab>            

          <Fab color="secondary" aria-label="browseS3" className={classes.fab} onClick={() => this.doBrowseS3dialog()}>
            <PhotoLibraryIcon />
          </Fab>  

          <Fab color="secondary" aria-label="share" className={classes.fab} onClick={() => this.shareImage()}>
            <ShareIcon />
          </Fab>  


          { this.state.gpsLat !== null && this.state.gpsLng !== null && <Fab color="secondary" aria-label="Go to GPS" className={classes.fab} onClick={()=> window.open(`https://maps.google.com/?q=${this.state.gpsLat },${this.state.gpsLng}`, "_blank")}><MapIcon /></Fab> }
                
          

        </Typography> 

      </span>
      )
      //               style={{ zIndex: 10, position: 'absolute', top: this.state.screenSize.height-TOP_MENU_MARGIN, right: 50 }}

  } // end of tags component
}


// zIndex: 10000 for FAB typography

//<FavoriteIcon color="secondary" />

Tag.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Tag);
